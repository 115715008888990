import { useRef } from 'react'
import { DismissButton, Overlay, Placement, usePopover } from 'react-aria'
import { OverlayTriggerState } from 'react-stately'

import styles from './popover.module.css'

export interface PopoverProps {
  /** The content of the popover. */
  children: React.ReactNode
  /** The state for the popover. */
  state: OverlayTriggerState
  /** The ref for the element which the popover positions itself with respect to. */
  triggerRef: React.RefObject<HTMLElement>
  /** The width of the trigger element. */
  width?: string
  /** The maximum height of the popover. */
  listMaxHeight?: string
  /** The placement of the element with respect to its anchor element. */
  placement?: Placement
  //** The additional offset applied along the main axis between the element and its anchor element. */
  offset?: number
  /** Whether the overlay should update its position automatically. */
  shouldUpdatePosition?: boolean
}

export const Popover = ({
  children,
  state,
  width,
  listMaxHeight,
  offset = 8,
  ...props
}: PopoverProps) => {
  const popoverRef = useRef<HTMLDivElement>(null)
  const { popoverProps, underlayProps } = usePopover(
    { ...props, offset, popoverRef },
    state
  )
  const popoverWidth = { '--popover-width': width }
  const popoverMaxHeight = { '--list-max-height': listMaxHeight }

  return (
    <Overlay>
      <div className={styles.underlay} {...underlayProps} />
      <div
        {...popoverProps}
        className={styles.popover}
        ref={popoverRef}
        style={{ ...popoverProps.style, ...popoverWidth, ...popoverMaxHeight }}
      >
        <DismissButton onDismiss={state.close} />
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  )
}
