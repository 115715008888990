import { ItemWithId } from '../../../UI/components/node-editor-panel/editor-widgets/sortable-widget/droppable-widget'
import {
  AssetFields,
  AssetLocale,
  InputLocale,
  PayloadLocale,
  QueueFields,
  QueueLocale,
  TextLocale,
  UrlLocale,
  VideoLocale,
} from '../content-fields'
import { LocaleCode } from '../locales/code'

interface ObjectWithLocale {
  locale: string
}

export class LocaleManager {
  private static getLocaleField<T extends ObjectWithLocale>(
    fieldLocales: T[],
    locale: string
  ): T | undefined {
    return fieldLocales.find(localeField => localeField.locale === locale)
  }

  static getTextByLocale = (
    textLocales: TextLocale[],
    locale: string
  ): string => {
    return this.getLocaleField(textLocales, locale)?.message || ''
  }

  static setTextByLocale = (
    textLocales: TextLocale[],
    text: string,
    locale: LocaleCode
  ) => {
    const newTitleLocales = textLocales.filter(
      textLocale => textLocale.locale !== locale
    )
    newTitleLocales.push({ message: text, locale })
    return newTitleLocales
  }

  static getVideoUrlByLocale = (
    videoLocales: VideoLocale[],
    locale: string
  ): string => {
    return this.getLocaleField(videoLocales, locale)?.url || ''
  }

  static setVideoUrlByLocale = (
    videoLocales: VideoLocale[],
    url: string,
    locale: LocaleCode
  ) => {
    const newVideoLocales = videoLocales.filter(
      videoLocale => videoLocale.locale !== locale
    )
    newVideoLocales.push({ url, locale, is_embedded: false })
    return newVideoLocales
  }

  static getInputsByLocale = (
    inputLocales: InputLocale[],
    locale: string
  ): string[] => {
    return this.getLocaleField(inputLocales, locale)?.values || []
  }

  static setInputsByLocale = (
    inputLocales: InputLocale[],
    values: string[],
    locale: LocaleCode
  ) => {
    const newInputLocales = inputLocales.filter(
      inputLocale => inputLocale.locale !== locale
    )
    newInputLocales.push({ values, locale })
    return newInputLocales
  }

  static getImageByLocale = (
    imageLocales: AssetLocale[],
    locale: string
  ): AssetFields | undefined => {
    const asset = this.getLocaleField(imageLocales, locale)
    if (!asset) return undefined
    return AssetFields.fromHubtypeCMS(asset)
  }

  static setImageByLocale = (
    imageLocales: AssetLocale[],
    image: AssetFields | undefined,
    locale: LocaleCode
  ) => {
    const imageLocale = image?.toHubtypeCMS(locale)
    const newImageLocales = imageLocales.filter(
      imageLocale => imageLocale.locale !== locale
    )
    if (imageLocale) {
      newImageLocales.push(imageLocale as AssetLocale)
    }
    return newImageLocales
  }

  static getQueueByLocale = (
    queueLocales: QueueLocale[],
    locale: string
  ): QueueFields | undefined => {
    const queue = this.getLocaleField(queueLocales, locale)
    if (!queue) return undefined
    return QueueFields.fromHubtypeCMS(queue)
  }

  static setQueueByLocale = (
    queueLocales: QueueLocale[],
    queue: QueueFields | undefined,
    locale: LocaleCode
  ) => {
    const queueLocale = queue?.getValuesByLocale(locale)
    const newQueueLocales = queueLocales.filter(
      queueLocale => queueLocale.locale !== locale
    )
    if (queueLocale) {
      newQueueLocales.push(queueLocale as QueueLocale)
    }
    return newQueueLocales
  }

  static getNonMessageContentByLocale = <T extends ItemWithId>(
    noneMessageLocales: UrlLocale[] | PayloadLocale[],
    locale: string,
    nonMessageContents: T[]
  ): T | undefined => {
    const noneMessageLocale = this.getLocaleField(noneMessageLocales, locale)
    return nonMessageContents.find(field => field.id === noneMessageLocale?.id)
  }

  static addLocale<T extends ObjectWithLocale>(
    fieldLocales: T[],
    newLocale: LocaleCode,
    copyFrom: LocaleCode
  ): void {
    const fieldToCopy = this.getLocaleField(fieldLocales, copyFrom)
    if (fieldToCopy) {
      fieldLocales.push({ ...fieldToCopy, locale: newLocale })
    }
  }

  static removeLocales<T extends ObjectWithLocale>(
    itemWithLocales: T[],
    localesToRemove: string[]
  ): void {
    const filteredItems = itemWithLocales.filter(
      item => !localesToRemove.includes(item.locale)
    )
    itemWithLocales.splice(0, itemWithLocales.length, ...filteredItems)
  }

  static getLocalesWithEmptyText(
    textLocales: TextLocale[],
    locales: LocaleCode[]
  ): string[] {
    return locales.filter(
      locale => !this.getLocaleField(textLocales, locale)?.message
    )
  }

  static getLocalesWithEmptyOrInvalidQueue(
    queueLocales: QueueLocale[],
    locales: LocaleCode[]
  ): string[] {
    return locales.filter(
      locale =>
        !this.getLocaleField(queueLocales, locale)?.id ||
        this.getLocaleField(queueLocales, locale)?.isInvalid
    )
  }

  static getLocalesWithEmptyAsset(
    assetLocales: AssetLocale[],
    locales: LocaleCode[]
  ): string[] {
    return locales.filter(
      locale => !this.getLocaleField(assetLocales, locale)?.file
    )
  }

  static getLocalesWithEmptyPayload(
    payloadLocales: PayloadLocale[],
    locales: LocaleCode[]
  ): string[] {
    return locales.filter(
      locale => !this.getLocaleField(payloadLocales, locale)?.id
    )
  }

  static getLocalesWithEmptyVideo(
    videoLocales: VideoLocale[],
    locales: LocaleCode[]
  ): string[] {
    return locales.filter(
      locale => !this.getLocaleField(videoLocales, locale)?.url
    )
  }

  static getLocalesWithEmptyInput(
    inputLocales: InputLocale[],
    locales: LocaleCode[]
  ): string[] {
    return locales.filter(
      locale => !this.getLocaleField(inputLocales, locale)?.values.length
    )
  }
}
