import { CountryInfo } from '../../domain/models/content-fields'
import { LanguageInfo } from '../../domain/models/locales/language'
import { WebchatDimensions } from '../../UI/types'
import { SelectCountryDropdown } from './select-country-dropdown'
import { SelectLanguageDropdown } from './select-language-dropdown'
import { StartOverButton } from './start-over-button'
import {
  StyledTestWebchatOptionsContainer,
  StyledWebchatOptionContainer,
} from './test-webchat-options-header-styles'

interface TestWebchatOptionsHeaderProps {
  isShared: boolean
  webchatDimensions: WebchatDimensions
  languages: LanguageInfo[]
  countries: CountryInfo[]
  selectedLanguage: LanguageInfo
  selectedCountry: CountryInfo
  onSelectedLanguage: (language: LanguageInfo) => void
  onSelectedCountry: (country: CountryInfo) => void
}

export const TestWebchatOptionsHeader = ({
  isShared,
  webchatDimensions,
  languages,
  countries,
  selectedLanguage,
  selectedCountry,
  onSelectedLanguage,
  onSelectedCountry,
}: TestWebchatOptionsHeaderProps) => {
  if (
    !languages ||
    !countries ||
    !onSelectedLanguage ||
    !onSelectedCountry ||
    !webchatDimensions.ready
  ) {
    return null
  }
  const computedWebchatHeight =
    100 - Math.round((webchatDimensions.height * 100) / window.innerHeight)
  return (
    <StyledTestWebchatOptionsContainer
      width={webchatDimensions.width}
      height={computedWebchatHeight}
    >
      <StyledWebchatOptionContainer $maxWidth={50}>
        <StartOverButton
          isShared={isShared}
          selectedLanguage={selectedLanguage}
          selectedCountry={selectedCountry}
        />
      </StyledWebchatOptionContainer>
      <StyledWebchatOptionContainer>
        <SelectLanguageDropdown
          selectedLanguage={selectedLanguage}
          languages={languages}
          onSelect={onSelectedLanguage}
        />
      </StyledWebchatOptionContainer>
      <StyledWebchatOptionContainer>
        <SelectCountryDropdown
          selectedCountry={selectedCountry}
          countries={countries}
          onSelect={onSelectedCountry}
        />
      </StyledWebchatOptionContainer>
    </StyledTestWebchatOptionsContainer>
  )
}
