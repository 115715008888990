import { Item } from 'react-stately'

import { SelectItem } from '../select/select'
import {
  MultiselectMenu,
  MultiselectMenuProps,
} from './widgets/multiselect-menu'

export const Multiselect = <T extends SelectItem>(
  props: Omit<MultiselectMenuProps<T>, 'children'>
): JSX.Element => {
  return (
    <MultiselectMenu
      {...props}
      selectionMode='multiple'
      aria-label={props.label}
    >
      {item => <Item textValue={item.name || item.id}>{item.name}</Item>}
    </MultiselectMenu>
  )
}
