import { Button, Icon, Tooltip } from '@hubtype/ui-react-web'

import {
  AI_CONTENT_ID_GENERATION_MESSAGE,
  MISSING_CONTENT_ID_MESSAGE,
} from '../../../constants'
import { useFeedbackMessage } from '../../../custom-hooks/use-feedback-message'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { NodeTypes } from '../../../types'
import { StyledWaringButton } from '../header-styles'

interface ErrorButtonProps {
  hasFlowErrors: boolean
}

export const ErrorButton = ({
  hasFlowErrors,
}: ErrorButtonProps): JSX.Element => {
  const { reportError, reportCustomFeedback } = useFeedbackMessage()
  const { state, removeFeedbackMessages } = useFlowBuilderSelector(ctx => ctx)

  const showErrors = () => {
    removeFeedbackMessages()
    let showAiContentIdGenerationMessage = false
    const errorCountLocales = state.nodes.reduce(
      (acc, node) => {
        showAiContentIdGenerationMessage =
          processFieldErrors(node) || showAiContentIdGenerationMessage
        processLocalesWithErrors(node, acc)
        handleDuplicatedCode(node)
        return acc
      },
      {} as Record<string, number>
    )
    if (showAiContentIdGenerationMessage) {
      reportCustomFeedback(AI_CONTENT_ID_GENERATION_MESSAGE)
    }
    showLocaleErrors(errorCountLocales)
  }

  const showLocaleErrors = (errorCountLocales: Record<string, number>) => {
    Object.entries(errorCountLocales).forEach(([locale, count]) => {
      if (locale !== state.currentLocale.code) {
        reportError(`${count} errors in language: ${locale}\n`, {
          localeCode: locale,
        })
      }
    })
  }

  const processLocalesWithErrors = (
    node: NodeTypes,
    errorCountLocales: Record<string, number>
  ) => {
    node.data.errors.localesWithErrors.forEach(locale => {
      errorCountLocales[locale] = (errorCountLocales[locale] ?? 0) + 1
    })
  }

  const processFieldErrors = (node: NodeTypes): boolean => {
    let areIdsMissing = false
    node.data.errors.fieldErrors.forEach(error => {
      reportError(error, { nodeId: node.id })
      if (error.includes(MISSING_CONTENT_ID_MESSAGE)) {
        areIdsMissing = true
      }
    })
    return areIdsMissing
  }

  const handleDuplicatedCode = (node: NodeTypes) => {
    if (node.data.errors.hasDuplicatedCode) {
      reportError(`Duplicated content id: ${node.data.code}`, {
        nodeId: node.id,
      })
    }
  }

  if (!hasFlowErrors || state.isReadOnly) return <></>

  return (
    <Tooltip content='See errors'>
      <StyledWaringButton>
        <Button
          intent='secondary'
          size='small'
          appearance='outline'
          onPress={showErrors}
        >
          <Icon icon='exclamation-triangle' prefix='fad' />
        </Button>
      </StyledWaringButton>
    </Tooltip>
  )
}
