import { Spinner } from '@hubtype/ui-react-web'

import { WindowContainer } from '../base-styles'
import { TextBody } from '../typography'
import { StyledLoading } from './ht-loading-screen-styles'

interface HtLoadingScreenProps {
  loadingMessage: string
}

export const HtLoadingScreen = ({
  loadingMessage,
}: HtLoadingScreenProps): JSX.Element => {
  return (
    <WindowContainer>
      <StyledLoading>
        <TextBody>{loadingMessage}</TextBody>
        <Spinner size='large' />
      </StyledLoading>
    </WindowContainer>
  )
}
