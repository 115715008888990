import { Icon, IconButton } from '@hubtype/ui-react-web'
import { cva } from 'class-variance-authority'

import { FeedbackType } from '../../../../domain/feedback'
import styles from './notification.module.css'

const notificationClasses = cva(styles.notification, {
  variants: {
    intent: {
      ai: styles.ai,
      primary: styles.primary,
    },
  },
})

export interface HtNotificationProps {
  children: React.ReactNode
  type: FeedbackType
  intent: 'ai' | 'primary'
  onClose: () => void
}

const getIconFromFeedbackType = (feedbackType: FeedbackType): string => {
  switch (feedbackType) {
    case FeedbackType.SUCCESS:
      return 'circle-check'
    case FeedbackType.INFORM:
      return 'circle-info'
    case FeedbackType.CONNECTION_ISSUE:
      return 'cloud-slash'
    default:
      return 'circle-xmark'
  }
}

export const HtNotification = ({
  children,
  type,
  intent = 'primary',
  onClose,
}: HtNotificationProps): JSX.Element => {
  return (
    <div className={notificationClasses({ intent })}>
      <Icon icon={getIconFromFeedbackType(type)} size='large' prefix='far' />
      {children}
      <IconButton icon='xmark' size='small' onPress={onClose} />
    </div>
  )
}
