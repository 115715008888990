import { Select } from '@hubtype/ui-react-web'
import { useEffect, useState } from 'react'

import { HubtypeUser } from '../../../../domain/models/organization-models'
import { getDateTime } from '../../../../utils/date-utils'
import { TrackEventName, useAnalytics } from '../../../analytics'
import anonymousUser from '../../../assets/anonymous_user.png'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { TextSmall, TextSmallBold } from '../../base'
import { headerDropdownProps } from '../header'
import {
  DropdownHeader,
  PublishedText,
  UserInfo,
} from './version-history-dropdown-styles'

export interface VersionHistoryData {
  id: string
  version: string
  createdAt: Date
  createdBy: string
  isPublished: boolean
  user?: HubtypeUser
}

export interface VersionHistoryProps {
  loadPublishedVersion(version: VersionHistoryData): Promise<void>
}

export const VersionHistoryDropdown = ({
  loadPublishedVersion,
}: VersionHistoryProps) => {
  const analytics = useAnalytics()
  const [currentVersionId, setCurrentVersionId] = useState<string | undefined>(
    undefined
  )
  const versionHistoryData = useFlowBuilderSelector(
    ctx => ctx.state.versionHistoryData
  )

  useEffect(() => {
    setCurrentVersionId(versionHistoryData.find(v => v.isPublished)?.id)
  }, [versionHistoryData])

  const onSelectVersion = (version?: VersionHistoryData) => {
    if (!version) return
    analytics.trackEvent(TrackEventName.CLICK_VERSION)
    setCurrentVersionId(version.id)
    loadPublishedVersion(version)
  }

  const getDropdownItemTitle = (date: Date) =>
    `Published on ${getDateTime(date)}`

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) return
    analytics.trackEvent(TrackEventName.VERSION_HISTORY_OPEN, {
      number_of_versions: versionHistoryData.length,
    })
  }

  return (
    <Select
      {...headerDropdownProps}
      items={versionHistoryData}
      label='Version history'
      selectedKey={currentVersionId}
      onChange={onSelectVersion}
      onOpenChange={onOpenChange}
      preserveButtonLabel={true}
      header={
        <DropdownHeader>
          {versionHistoryData.length === 0
            ? 'There are no previously published versions yet. Once there are, you’ll be able to check and restore them from this dropdown.'
            : 'Click to see any published version of this conversational app. Once loaded you can restore to that version.'}
        </DropdownHeader>
      }
    >
      {item => (
        <>
          {item.isPublished && (
            <PublishedText>Currently published version</PublishedText>
          )}
          <TextSmallBold>{getDropdownItemTitle(item.createdAt)}</TextSmallBold>
          {item.user && (
            <UserInfo>
              <img src={item.user.pic || anonymousUser} alt='avatar' />
              <TextSmall>{item.user.getUserName()}</TextSmall>
            </UserInfo>
          )}
        </>
      )}
    </Select>
  )
}
