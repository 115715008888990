import { Button, Tooltip } from '@hubtype/ui-react-web'
import { useEffect, useState } from 'react'

import { TrackEventName, useAnalytics } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { PopupType } from '../../../types'

interface PublishButtonProps {
  hasFlowErrors: boolean
}

export const PublishButton = ({
  hasFlowErrors,
}: PublishButtonProps): JSX.Element => {
  const analytics = useAnalytics()
  const { state, setPopupContent } = useFlowBuilderSelector(ctx => ctx)
  const [isDisabled, setIsDisabled] = useState(true)
  const [tooltip, setTooltip] = useState<string>()

  useEffect(() => {
    if (state.isReadOnly || state.isOffline) {
      setTooltip(undefined)
      setIsDisabled(true)
    } else if (hasFlowErrors) {
      setTooltip('Fix errors to publish')
      setIsDisabled(true)
    } else if (state.hash === state.hashPublished) {
      setTooltip('Already published')
      setIsDisabled(true)
    } else {
      setTooltip(undefined)
      setIsDisabled(false)
    }
  }, [
    hasFlowErrors,
    state.hash,
    state.hashPublished,
    state.isReadOnly,
    state.isOffline,
  ])

  const openPublishModal = async () => {
    setPopupContent({ type: PopupType.PUBLISH })
    await analytics.trackEvent(TrackEventName.CLICK_PUBLISH_CTA)
  }

  return (
    <Tooltip content={tooltip} isDisabled={!tooltip}>
      <Button
        intent='primary'
        size='small'
        isDisabled={isDisabled}
        onPress={openPublishModal}
      >
        Publish
      </Button>
    </Tooltip>
  )
}
