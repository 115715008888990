import { memo } from 'react'

import {
  ContentType,
  WhatsappButtonListFields,
  WhatsappButtonListSectionFields,
} from '../../../../../domain/models/content-fields'
import { flatten } from '../../../../../utils/array-utils'
import {
  MAX_WHATSAPP_BUTTON_TEXT_LENGTH,
  MAX_WHATSAPP_LIST_MESSAGE_LENGTH,
} from '../../../../constants'
import { HtInfoPanel, TextBodyBold, TextBodySmall } from '../../../base'
import {
  ActionType,
  ADD_ROW,
  ADD_SECTION,
  WHATSAPP_BUTTON_LIST_TEXT,
  WHATSAPP_BUTTON_TEXT,
} from '../../constants'
import { WhatsappButtonsContainer } from '../../editor-styles'
import { ButtonWidget } from '../../editor-widgets/button-widget'
import { SortableContentWidget } from '../../editor-widgets/sortable-widget/sortable-content-widget'
import { ContentTextField } from '../../editor-widgets/text-input-widgets'
import { UniqueContentField } from '../../editor-widgets/unique-content-id-widget'
import { EditorProps } from '../../node-editor-panel'
import { SectionEditor } from './section-editor'

interface WhatsappButtonListEditorProps extends EditorProps {
  nodeContent: WhatsappButtonListFields
}

export const WhatsappButtonListEditor = memo(
  ({ nodeContent, ...props }: WhatsappButtonListEditorProps): JSX.Element => {
    const isAddRowsButtonDisabled = () => {
      return flatten(nodeContent.sections.map(s => s.rows)).length >= 10
    }
    const isAddSectionsButtonDisabled = () => {
      return nodeContent.sections.length >= 10
    }

    const getSectionTitle = (section: WhatsappButtonListSectionFields) => {
      if (section.title) return section.title
      if (nodeContent.sections.length > 1) return 'Section title'
      return 'Section title (optional)'
    }

    const onRemoveSectionClick = (section: WhatsappButtonListSectionFields) => {
      props.onChange({
        type: ActionType.DELETE_SECTION,
        sectionToDelete: section,
      })
    }

    return (
      <>
        <TextBodyBold>Button list</TextBodyBold>
        <UniqueContentField
          {...props}
          id={nodeContent.id}
          value={nodeContent.code}
          isAiGenerated={nodeContent.isCodeAiGenerated}
        />
        <ContentTextField
          {...props}
          id={nodeContent.id}
          field={WHATSAPP_BUTTON_LIST_TEXT}
          value={nodeContent.text}
          multiline={true}
          hasEmojiPicker={true}
          maxLength={MAX_WHATSAPP_LIST_MESSAGE_LENGTH}
        />
        <ContentTextField
          {...props}
          id={nodeContent.id}
          field={WHATSAPP_BUTTON_TEXT}
          value={nodeContent.listButtonText}
          maxLength={MAX_WHATSAPP_BUTTON_TEXT_LENGTH}
        />
        <SortableContentWidget
          {...props}
          contentType={ContentType.SECTION}
          nodeId={nodeContent.id}
          sortableContents={nodeContent.sections}
          childProps='rows'
          selectedContentId={nodeContent.selectedSubContentId}
          isSelectable={true}
          isRemovable={nodeContent.sections.length > 1}
          onRemove={onRemoveSectionClick}
          header={section => (
            <TextBodySmall>{getSectionTitle(section)}</TextBodySmall>
          )}
        >
          {(section, i) => (
            <SectionEditor
              {...props}
              index={i}
              section={section}
              node={nodeContent}
              selectedSubContentId={nodeContent.selectedSubContentId}
            />
          )}
        </SortableContentWidget>
        <WhatsappButtonsContainer>
          <ButtonWidget
            {...props}
            field={ADD_ROW}
            isButtonDisabled={isAddRowsButtonDisabled()}
            tooltip='Max. rows reached'
          />
          <ButtonWidget
            {...props}
            field={ADD_SECTION}
            isButtonDisabled={isAddSectionsButtonDisabled()}
            tooltip='Max. sections reached'
          />
        </WhatsappButtonsContainer>
        <HtInfoPanel
          title='About WhatsApp button lists'
          text={
            'WhatsApp button lists can include up to 10 options divided into a maximum of 10 sections. Users will be able to select a single option.\n\nIf you plan on presenting 3 options or less, you can use a regular text message with buttons instead.'
          }
        ></HtInfoPanel>
      </>
    )
  }
)
