import { PresenceMessage, RealtimeChannel } from 'ably'
import { useEffect } from 'react'

export const useRealtimeChannel = (
  {
    realtimeChannel,
    connectionState,
  }: { realtimeChannel?: RealtimeChannel; connectionState?: string },
  enter: (member: PresenceMessage) => void,
  leave: (member: PresenceMessage) => void
) => {
  useEffect(() => {
    if (realtimeChannel?.state === 'detached') return
    realtimeChannel?.presence.subscribe('enter', enter)
    realtimeChannel?.presence.subscribe('leave', leave)

    return () => {
      if (realtimeChannel?.state === 'detached') return
      realtimeChannel?.presence.unsubscribe('enter', enter)
      realtimeChannel?.presence.unsubscribe('leave', leave)
    }
  }, [connectionState, realtimeChannel, enter, leave])
}
