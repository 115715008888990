import { Button, IconButton } from '@hubtype/ui-react-web'
import { useEffect } from 'react'

import { usePanelVisibility } from '../../../custom-hooks'
import { ARIA_LABEL, ROLES } from '../../../roles'
import { WindowContainer } from '../base-styles'
import {
  ModalButtonsContainer,
  ModalHeader,
  StyledModal,
} from './ht-modal-styles'

interface ModalButton {
  text: string
  disabled?: boolean
  isHidden?: boolean
  intent?: 'primary' | 'secondary' | 'danger'
  onClick: () => void
}

export interface ModalProps {
  title: React.ReactNode
  message?: string
  confirmButton?: ModalButton
  discardButton?: Partial<ModalButton>
  children?: React.ReactNode
  canCloseOnClickOutside?: boolean
  closeButtonEnabled?: boolean
  cancel: () => void
}

export const HtModal = ({
  title,
  message,
  confirmButton,
  discardButton,
  children,
  canCloseOnClickOutside = true,
  cancel,
  closeButtonEnabled = true,
}: ModalProps): JSX.Element => {
  const ref = usePanelVisibility(
    cancel,
    !confirmButton?.disabled ? confirmButton?.onClick : undefined
  )

  useEffect(() => ref.current?.focus(), [])

  return (
    <WindowContainer aria-label={ARIA_LABEL.SCREEN_OVERLAY}>
      <StyledModal
        ref={canCloseOnClickOutside ? ref : null}
        role={ROLES.DIALOG}
        tabIndex={-1}
      >
        <ModalHeader>
          {title}
          {closeButtonEnabled && discardButton?.onClick && (
            <IconButton icon='xmark' size='medium' onPress={cancel} />
          )}
        </ModalHeader>
        {message}
        {children}
        <ModalButtonsContainer>
          {!discardButton?.isHidden && (
            <Button
              intent='secondary'
              appearance='outline'
              isDisabled={discardButton?.disabled}
              onPress={() =>
                discardButton ? discardButton.onClick?.() : cancel()
              }
            >
              {discardButton?.text || 'Cancel'}
            </Button>
          )}
          {confirmButton && !confirmButton?.isHidden && (
            <Button
              intent={confirmButton.intent}
              isDisabled={confirmButton.disabled}
              onPress={() => confirmButton.onClick()}
            >
              {confirmButton.text}
            </Button>
          )}
        </ModalButtonsContainer>
      </StyledModal>
    </WindowContainer>
  )
}
