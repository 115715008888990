import { Icon, Tooltip } from '@hubtype/ui-react-web'
import { memo } from 'react'

import { TextBodySmall } from '../base'

interface BuildComponentTooltipProps {
  title: string
  tooltipText: string
}

export const BuildComponentTooltip = memo(
  ({ title, tooltipText }: BuildComponentTooltipProps) => {
    return (
      <Tooltip content={tooltipText} placement='right'>
        <TextBodySmall
          style={{ display: 'flex', gap: 4, alignItems: 'center' }}
        >
          {title}
          <Icon icon='circle-question' size='tiny' />
        </TextBodySmall>
      </Tooltip>
    )
  }
)
