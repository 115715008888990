import { memo, useEffect, useState } from 'react'

import {
  ButtonFields,
  ButtonStyle,
  ContentType,
  TextFields,
} from '../../../../../domain/models/content-fields'
import { isWebview } from '../../../../../webviews/utils'
import { TextBodyBold, TextBodySmall, TextBodySmallBold } from '../../../base'
import {
  ActionType,
  ADD_BUTTON,
  ADD_REPLY,
  BUTTON_STYLE,
  TEXT,
} from '../../constants'
import { SubContentTitle } from '../../editor-styles'
import { ButtonWidget } from '../../editor-widgets/button-widget'
import { FieldsCheckbox } from '../../editor-widgets/checkbox-widget'
import { SortableContentWidget } from '../../editor-widgets/sortable-widget/sortable-content-widget'
import { ContentTextField } from '../../editor-widgets/text-input-widgets'
import { UniqueContentField } from '../../editor-widgets/unique-content-id-widget'
import { EditorProps } from '../../node-editor-panel'
import { ButtonEditor } from '../button-editor/button-editor'

interface TextEditorProps extends EditorProps {
  nodeContent: TextFields
}

export const TextEditor = memo(
  ({ nodeContent, ...props }: TextEditorProps): JSX.Element => {
    const hasButtons = nodeContent.buttons.length > 0
    const [isReply, setReplyStyle] = useState(false)

    useEffect(() => {
      setReplyStyle(nodeContent.buttonsStyle === ButtonStyle.QUICK_REPLY)
    }, [nodeContent.buttonsStyle])

    const getButtonTitle = (index: number): string => {
      if (isReply) return `Quick reply ${index}`
      return `Button ${index}`
    }

    const isButtonSelected = (button: ButtonFields) => {
      return button.id === nodeContent.selectedSubContentId
    }

    return (
      <>
        <TextBodyBold>Text</TextBodyBold>
        <UniqueContentField
          {...props}
          id={nodeContent.id}
          value={nodeContent.code}
          isAiGenerated={nodeContent.isCodeAiGenerated}
        />
        <ContentTextField
          {...props}
          id={nodeContent.id}
          field={TEXT}
          value={nodeContent.text}
          multiline={true}
          hasEmojiPicker={true}
          isCounterEnabled={true}
          hasVariables={true}
        />
        {hasButtons && (
          <>
            <SortableContentWidget
              {...props}
              contentType={ContentType.BUTTON}
              nodeId={nodeContent.id}
              sortableContents={nodeContent.buttons}
              selectedContentId={nodeContent.selectedSubContentId}
              isSelectable={true}
              isRemovable={true}
              onRemove={button => {
                props.onChange({
                  buttonToDelete: button,
                  type: ActionType.DELETE_BUTTON,
                })
              }}
              header={(button, i) => (
                <SubContentTitle>
                  <TextBodySmallBold>{getButtonTitle(i + 1)}</TextBodySmallBold>
                  {button.text && <TextBodySmall>{button.text}</TextBodySmall>}
                </SubContentTitle>
              )}
            >
              {(button, i) => (
                <ButtonEditor
                  {...props}
                  button={button}
                  buttonIndex={i}
                  showButtonInfo={isButtonSelected(button)}
                  isReply={isReply}
                />
              )}
            </SortableContentWidget>
            <FieldsCheckbox {...props} field={BUTTON_STYLE} checked={isReply} />
          </>
        )}
        {!isWebview(nodeContent.flowId) && (
          <ButtonWidget {...props} field={isReply ? ADD_REPLY : ADD_BUTTON} />
        )}
      </>
    )
  }
)
