export interface Point2D {
  x: number
  y: number
}

type Point = [number, number]

const parsePath = (path: string): [Point, Point, Point, Point] | undefined => {
  const match = path.match(
    /M([\d.-]+),([\d.-]+)\s+C([\d.-]+),([\d.-]+)\s+([\d.-]+),([\d.-]+),\s+([\d.-]+),([\d.-]+)/
  )
  if (!match) return undefined

  const [, x0, y0, x1, y1, x2, y2, x3, y3] = match.map(Number)
  return [
    [x0, y0],
    [x1, y1],
    [x2, y2],
    [x3, y3],
  ]
}

export const getCirclePathIntersection = (
  cx: number,
  cy: number,
  r: number,
  path: string
): { x: number; y: number } | undefined => {
  const points = parsePath(path)
  if (!points) return undefined

  const [P0, P1, P2, P3] = points

  // Function to compute a point on the cubic Bezier curve
  const bezier = (t: number): Point => {
    const mt = 1 - t
    const x =
      mt ** 3 * P0[0] +
      3 * mt ** 2 * t * P1[0] +
      3 * mt * t ** 2 * P2[0] +
      t ** 3 * P3[0]
    const y =
      mt ** 3 * P0[1] +
      3 * mt ** 2 * t * P1[1] +
      3 * mt * t ** 2 * P2[1] +
      t ** 3 * P3[1]
    return [x, y]
  }

  // Function to compute distance from a point to circle center
  const getDistance = (point: Point): number => {
    const dx = point[0] - cx
    const dy = point[1] - cy
    return Math.sqrt(dx * dx + dy * dy)
  }

  // The minimum distance to see if intersection is possible
  let minDistance = Infinity
  const coarseSteps = 100
  for (let i = 0; i <= coarseSteps; i++) {
    const t = i / coarseSteps
    const point = bezier(t)
    const distance = getDistance(point)
    minDistance = Math.min(minDistance, distance)
  }

  // If minimum distance is greater than radius, no intersection
  if (minDistance > r) {
    return undefined
  }

  let nearestIntersection: Point | null = null
  let minDistanceToStart = Infinity

  const steps = 2000
  let lastDistance = getDistance(bezier(0))

  // Look for points where distance crosses the radius
  for (let i = 1; i <= steps; i++) {
    const t = i / steps
    const point = bezier(t)
    const distance = getDistance(point)

    // Check if we cross the radius
    if ((lastDistance - r) * (distance - r) <= 0) {
      let ta = (i - 1) / steps
      let tb = t
      let iterations = 0
      const maxIterations = 20

      while (iterations < maxIterations) {
        const tm = (ta + tb) / 2
        const pm = bezier(tm)
        const dm = getDistance(pm) - r

        if (Math.abs(dm) < 0.1) {
          // Calculate distance to start point
          const distanceToStart = Math.sqrt(
            (pm[0] - P0[0]) ** 2 + (pm[1] - P0[1]) ** 2
          )

          if (distanceToStart < minDistanceToStart) {
            minDistanceToStart = distanceToStart
            nearestIntersection = pm
          }
          break
        }

        if (dm * (getDistance(bezier(ta)) - r) < 0) {
          tb = tm
        } else {
          ta = tm
        }
        iterations++
      }
    }
    lastDistance = distance
  }

  return nearestIntersection
    ? { x: nearestIntersection[0], y: nearestIntersection[1] }
    : undefined
}
