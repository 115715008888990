import { memo } from 'react'

import { KeywordNodeProps } from '../../types'
import { ChipList, InputHandles, NodeHeader } from './node-components'
import { InputNode } from './node-styles'

const KeywordNode = ({ data, selected }: KeywordNodeProps) => {
  return (
    <InputNode $isSelected={selected}>
      <NodeHeader data={data} title='Keywords' icon='quote-right' />
      <InputHandles contentId={data.getContentId()} />
      <ChipList items={data.keywords} selected={selected} />
    </InputNode>
  )
}

export default memo(KeywordNode)
