import { useEffect, useState } from 'react'

import { PreviewUrlParams } from '../../UI/types'
import { getParamsFromUrl } from '../utils/url-utils'

export function useRefreshCount() {
  const [initialRefreshCount] = getParamsFromUrl([
    PreviewUrlParams.WEBCHAT_REFRESH_COUNT,
  ])
  const [refreshCount, setRefreshCount] = useState(
    initialRefreshCount ? parseInt(initialRefreshCount) : 0
  )
  const [urlWithRefreshCount, setUrlWithRefreshCount] = useState('')

  useEffect(() => {
    const updatedRefreshCount = refreshCount + 1
    const urlWithUpdatedRefreshCount = new URL(window.location.href)
    urlWithUpdatedRefreshCount.searchParams.set(
      PreviewUrlParams.WEBCHAT_REFRESH_COUNT,
      updatedRefreshCount.toString()
    )
    setUrlWithRefreshCount(urlWithUpdatedRefreshCount.toString())
    setRefreshCount(updatedRefreshCount)
  }, [])
  return { refreshCount, urlWithRefreshCount }
}
