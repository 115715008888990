import { TextInput } from '@hubtype/ui-react-web'
import { memo, useState } from 'react'

import { HtChip } from '../../../base'
import { InputAction } from '../../constants'
import { FullWidthContainer, KeywordsContainer } from '../../editor-styles'

interface KeywordsFieldProps {
  field: InputAction
  values: string[]
  readOnly: boolean
  onChange: (action: any) => void
}

export const KeywordsField = memo((props: KeywordsFieldProps): JSX.Element => {
  const [currentValue, setCurrentValue] = useState('')

  const addItem = () => {
    if (!currentValue || props.values.includes(currentValue)) return
    props.onChange({
      type: props.field.actionType,
      fieldKey: props.field.key,
      value: [...props.values, currentValue],
    })
    setCurrentValue('')
  }

  const deleteItem = (item: string) => {
    props.onChange({
      type: props.field.actionType,
      fieldKey: props.field.key,
      value: props.values.filter(v => v !== item),
    })
  }

  return (
    <FullWidthContainer>
      <TextInput
        label={props.field.label}
        placeholder={props.field.placeholder}
        value={currentValue}
        isReadOnly={props.readOnly}
        onChange={setCurrentValue}
        onKeyDown={e => {
          if (e.code === 'Enter') addItem()
        }}
        description={props.field.helperText}
      />
      <KeywordsContainer>
        {props.values.map((item, index) => (
          <HtChip
            key={index}
            label={item}
            disabled={props.readOnly}
            onDelete={() => deleteItem(item)}
          />
        ))}
      </KeywordsContainer>
    </FullWidthContainer>
  )
})
