import { cva } from 'class-variance-authority'

import { useFlowBuilderSelector } from '../../reducer/hooks'
import styles from './flow-controls.module.css'
import { UndoRedoControls } from './undo-redo-controls/undo-redo-controls'
import { ViewControls } from './view-controls/view-controls'

const viewControlsClasses = cva(styles.controlsContainer, {
  variants: {
    isEditorOpen: {
      true: styles.isEditorOpen,
    },
  },
})

export const FlowControls = () => {
  const currentNode = useFlowBuilderSelector(ctx => ctx.state.currentNode)

  return (
    <div className={viewControlsClasses({ isEditorOpen: !!currentNode })}>
      <UndoRedoControls />
      <ViewControls />
    </div>
  )
}
