import {
  Node as ReactFlowNode,
  NodeProps as ReactFlowNodeProps,
} from 'reactflow'

import { MessageContentType } from '../../domain/models/content-fields'
import { TextSmall, TextSmallBold } from '../../UI/components/base'
import {
  Button,
  Handles,
  NodeHeader,
} from '../../UI/components/nodes/node-components'
import {
  MessageNode,
  StyledWhatsappCTAFields,
} from '../../UI/components/nodes/node-styles'
import { WhatsappCTAUrlButtonFields } from './model'

export interface WhatsappCTAUrlButtonNodeInterface
  extends ReactFlowNode<WhatsappCTAUrlButtonFields> {
  data: WhatsappCTAUrlButtonFields
  type: MessageContentType.WHATSAPP_CTA_URL_BUTTON
}

export interface WhatsappCTAUrlButtonNodeProps
  extends ReactFlowNodeProps<WhatsappCTAUrlButtonFields> {
  data: WhatsappCTAUrlButtonFields
  type: MessageContentType.WHATSAPP_CTA_URL_BUTTON
}

export const WhatsappCTAUrlButtonNode = ({
  data,
  selected,
}: WhatsappCTAUrlButtonNodeProps) => {
  return (
    <MessageNode $isSelected={selected}>
      <Handles hasFollowUp={true} contentId={data.getContentId()} />
      <NodeHeader
        data={data}
        title={'URL Button'}
        icon='link'
        channelIcon='whatsapp'
      />
      <hr />
      <StyledWhatsappCTAFields $selected={selected}>
        {data.header && <TextSmallBold>{data.header}</TextSmallBold>}
        <TextSmall>{data.text || 'Main text'}</TextSmall>
        {data.footer && <TextSmall>{data.footer}</TextSmall>}
      </StyledWhatsappCTAFields>
      <Button button={data.button} hasHandler={false} />
    </MessageNode>
  )
}
