import {
  Node as ReactFlowNode,
  NodeProps as ReactFlowNodeProps,
} from 'reactflow'

import { InputContentType } from '../../domain/models/content-fields'
import AiBrainIcon from '../../UI/assets/ai-brain.svg'
import { TextBodySmall, TextSmallBold } from '../../UI/components/base'
import {
  InputHandles,
  NodeHeader,
} from '../../UI/components/nodes/node-components'
import {
  AiInputNode,
  StyledInputList,
} from '../../UI/components/nodes/node-styles'
import { SmartIntentFields } from './model'

export interface SmartIntentNodeInterface
  extends ReactFlowNode<SmartIntentFields> {
  data: SmartIntentFields
  type: InputContentType.SMART_INTENT
}

export interface SmartIntentNodeProps
  extends ReactFlowNodeProps<SmartIntentFields> {
  data: SmartIntentFields
  type: InputContentType.SMART_INTENT
}

export const SmartIntentNode = ({ data, selected }: SmartIntentNodeProps) => {
  return (
    <AiInputNode $isSelected={selected}>
      <NodeHeader
        data={data}
        title='Smart intent'
        iconComponent={<AiBrainIcon />}
      />
      <InputHandles contentId={data.getContentId()} />
      <StyledInputList>
        <TextSmallBold>Intent title</TextSmallBold>
        {data.title && (
          <TextBodySmall $wordBreak='break-word'>{data.title}</TextBodySmall>
        )}
      </StyledInputList>
    </AiInputNode>
  )
}
