import {
  TextBodySmall,
  TextBodySmallBold,
  TextSmallExtra,
  TextSmallExtraBold,
} from '../typography'
import { Size } from '../variants'
import { InfoPanelContainer, StyledInfoPanel } from './ht-info-panel-styles'

export enum PanelType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  WARN = 'warn',
}

interface HtInfoPanelProps {
  title: React.ReactNode
  text: React.ReactNode
  recommendedUseText?: string
  type?: PanelType
  size?: Size
}

export const HtInfoPanel = ({
  title,
  text,
  recommendedUseText,
  type = PanelType.SECONDARY,
  size = Size.SMALL,
}: HtInfoPanelProps): JSX.Element => {
  const Title = size === Size.SMALL ? TextSmallExtraBold : TextBodySmallBold
  const Text = size === Size.SMALL ? TextSmallExtra : TextBodySmall
  return (
    <InfoPanelContainer>
      <StyledInfoPanel $type={type} $size={size}>
        <div>
          <Title>{title}</Title>
        </div>
        <Text>{text}</Text>
      </StyledInfoPanel>
      {recommendedUseText && (
        <StyledInfoPanel $type={type} $size={size}>
          <Title>Recommended use</Title>
          <Text>{recommendedUseText}</Text>
        </StyledInfoPanel>
      )}
    </InfoPanelContainer>
  )
}
