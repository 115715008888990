import { arrayMoveImmutable } from '@hubtype/util-shared'
import deepClone from 'lodash.clonedeep'
import {
  DragDropContext,
  DraggableLocation,
  DropResult,
} from 'react-beautiful-dnd'
import { useUpdateNodeInternals } from 'reactflow'

import { MOVE_SUB_CONTENT } from '../../constants'
import { StyledDroppable } from '../../editor-styles'
import { DroppableWidget, DroppableWidgetProps } from './droppable-widget'

export interface ItemWithId {
  id: string
}

interface SortableWidgetProps<T extends ItemWithId>
  extends Omit<DroppableWidgetProps<T>, 'droppableId'> {
  nodeId: string
  childProps?: string
}

export const SortableContentWidget = <T extends ItemWithId>(
  props: SortableWidgetProps<T>
): JSX.Element => {
  const updateNodeInternals = useUpdateNodeInternals()

  const updateNode = (newContent: T[]) => {
    props.onChange({
      type: MOVE_SUB_CONTENT.actionType,
      fieldKey: MOVE_SUB_CONTENT.key,
      subContents: newContent,
      selectedSubContentId: props.selectedContentId,
      contentType: props.contentType,
    })
    // used to call updateNodeInternals after the state is updated, useEffect didn't work
    setTimeout(() => {
      updateNodeInternals(props.nodeId)
    }, 100)
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const { source, destination } = result
    if (result.type === props.contentType) {
      const newItems = arrayMoveImmutable(
        props.sortableContents,
        source.index,
        destination.index
      )
      updateNode(newItems)
    } else {
      onUpdateSubContent(source, destination)
    }
  }

  const onUpdateSubContent = (
    source: DraggableLocation,
    destination: DraggableLocation
  ) => {
    if (!props.childProps) return
    const itemSubItemMap = props.sortableContents.reduce(
      (acc: Record<string, T[]>, item) => {
        //@ts-expect-error typescript error
        acc[item.id] = item[props.childProps]
        return acc
      },
      {}
    )
    if (source.droppableId === destination.droppableId) {
      reorderItemsInSameParent(itemSubItemMap, source, destination)
    } else {
      reorderItemsInDifferentParent(itemSubItemMap, source, destination)
    }
  }

  const reorderItemsInSameParent = (
    itemSubItemMap: Record<string, T[]>,
    source: DraggableLocation,
    destination: DraggableLocation
  ): void => {
    const clonedItems = deepClone(props.sortableContents)
    const { droppableId: sourceParentId, index: sourceIndex } = source
    const destIndex = destination.index
    const sourceSubItems = itemSubItemMap[sourceParentId]

    const reorderedSubItems = arrayMoveImmutable(
      sourceSubItems,
      sourceIndex,
      destIndex
    )
    const newItems = clonedItems.map(item => {
      if (item.id === sourceParentId) {
        //@ts-expect-error 'string' can't be used to index type 'ItemWithId'.
        item[props.childProps] = reorderedSubItems
      }
      return item
    })
    updateNode(newItems)
  }

  const reorderItemsInDifferentParent = (
    itemSubItemMap: Record<string, T[]>,
    source: DraggableLocation,
    destination: DraggableLocation
  ) => {
    const clonedItems = deepClone(props.sortableContents)
    const { droppableId: sourceParentId, index: sourceIndex } = source
    const { droppableId: destParentId, index: destIndex } = destination
    const sourceSubItems = itemSubItemMap[sourceParentId]
    const destSubItems = itemSubItemMap[destParentId]

    const newSourceSubItems = deepClone(sourceSubItems)
    const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1)
    const newDestSubItems = deepClone(destSubItems)
    newDestSubItems.splice(destIndex, 0, draggedItem)

    const newItems = clonedItems.map(item => {
      if (item.id === sourceParentId) {
        //@ts-expect-error typescript error
        item[props.childProps] = newSourceSubItems
      } else if (item.id === destParentId) {
        //@ts-expect-error typescript error
        item[props.childProps] = newDestSubItems
      }
      return item
    })
    updateNode(newItems)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StyledDroppable>
        <DroppableWidget {...props} droppableId={'droppableId'} />
      </StyledDroppable>
    </DragDropContext>
  )
}
