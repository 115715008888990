import { assertEvent, assign, emit, PromiseActorLogic, setup } from 'xstate'

import { TrackEventName } from '../../../../analytics'
import { TransferBot } from './steps/select-bot-step'
import { TransferDetails } from './transfer-flows-modal'
import {
  MachineAnalyticsEvent,
  MachineFeedbackEvent,
} from './use-machine-feedback'

export const TransferFlowsMachine = setup({
  types: {
    context: {} as {
      transferDetails?: TransferDetails
      bots: TransferBot[]
    },
    events: {} as
      | { type: 'TRANSFER'; transferDetails: TransferDetails }
      | { type: 'NEXT' }
      | { type: 'BACK' }
      | { type: 'CANCEL' },
    emitted: {} as MachineFeedbackEvent | MachineAnalyticsEvent,
  },
  guards: {
    openOnClose: ({ context }) =>
      !!context.transferDetails?.openTargetBotOnTransfer,
  },
  actors: {
    transferFlows: {} as PromiseActorLogic<void, TransferDetails>,
    openTargetBot: {} as PromiseActorLogic<void>,
    getBots: {} as PromiseActorLogic<TransferBot[]>,
    closeModal: {} as PromiseActorLogic<void>,
  },
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QBUBOBDAdrAZmVAYgDYD2A7rALLoDGAFgJaZgB0MALu01AEInuwAxBBLMWTAG4kA1qzRZc+YuSq1GYjl0y9+sBJJI10XUQG0ADAF0LlxKAAOJWAxOY7IAB6IAtADYArCzm-gDMAEzmvmGhAOyhIQCcADQgAJ6IIZksABy+2dkALACMRTHmZdlF-gC+1Sny2HiEpBTU9Eysmtx8AoL4qCSoLPZExjiDALYsDYrNKm3qnWCc3br6mFJGrjY27o7Oru5eCH4FLCGVIb4h-qH+RQVhyWmIBf4JLBVlDzFxRdG1eoYRpKFqqdoaZZaHS9ADCAEEAHKwgCiABldkgQPsXAxREcfNFAkVfFUElUCpS4okUukEAVsuYWAkbmEYgFzGFMlVASAZk1lK01B0WAAjfgAZTARDANFcgkRKIAGshMQ4nLj8Vjjk9AgkEv5slcEmFHuZzFVaYhoiFPhETaUSgyEoVefzQfNhWJxewpTK5XjMIIEcj0WrsRrDtrrdlAjdyWFKmEHnkiiErfTKiwirGwr4CiFzCbEjE3cDZoLwYsWLAAK4TCboVCpQTIABKSIlBBRbfDOKjoGOhrCOX8MRJUW55nTLwQNrt5lyb0XXJiprLCgFYIWIrrDabLZ48NhAGk+5HAwT6TnmaTfHF7wFsnEMzdAkXQvrp+T2SENyC5iFCFWD3Rtm2DJFUQxKw9gvLVB0Qd4ihYZ0XRKe5ihnOkGWQnN-DzAsiy5BJSzqPlyy3T1gJYdgKP6bhK2EURWAMWRpjowCqxFWjN3o7RK3WTZjEDHYYKxftL2jBADWQhJ8yuXJygKGJnjpZMPjzX4CgSRcCxtUigV4zidzEHiANQBiwT6VABiGEYxkmdijMrEzWDM2YLP4sFBMMYSzCsc8DkkhCEBiBls3zfxlJiG4PzCV92RYPJ8gKTl2QiUl-wrbcvVYehZWkAB5ewwEwZAmw4QRAs1NwpLTUlmUdUlKlKaJ4tnIlmS+a4zUKF0ssooDq3ymgipKsqKuWKqilscS4NqkK03HJL41uFlcluXxX3+HJ8mnc02XZbIEgGj0hpFGhSGcbRKBICB0CIJixFYuQOJc3KWEujUbruh6fK2ESArE9UgvgzxECqfwmQZX98xNfVSgzAoAmzUJ8xiY7ija07jI+khxu4crUA4HonpYjYZFe5ycuo-HSsJyb2B6f6-MwUTZpBmqryqbIR0icwYe+AJDVfRNzjecl3l00oClqMjMDuuB3HdXHgNg0GFvBk4QlKFDym+HWdfHLkMzTZD2S034ocNf5fBx97qK6bQengOaNavbw8w+VLfgtQ3HRN2duRYd4opzRkrmU7J7Zp6sfT9WUBwjd26pvTJ8kKcIngnV9biCA1Eh0xJSmuGOqOrUCD3VrmpKpZkwjzeq8nuQsYgzec10XSKVxCcdo7IlWHerdymk8qBK2rpPjhi20SLKSkoiLA1-HbwsWAbruiw5cpfDtge3tji66AK4rSqJjhJ+CrWSl3z5Y1TRNeYibJ29CBclyh3ne5zMvzrEL7rpQFuvdIgl8wbHDTNpEOclyQulimOdq2FUqoyuJHcIvhzQBF-lxMQdNMAM2JssHoYDNYQP8HkEOVII7rXHKpa0a8N4-iNsjSo2DXKfSupAEh3MMYfCKDpDBsDpz8JXrOBeesSSSzXPPXIctqhAA */
  id: 'TransferFlowsMachine',
  context: {
    bots: [],
  },
  initial: 'gettingBots',
  states: {
    gettingBots: {
      invoke: {
        src: 'getBots',
        onDone: {
          actions: assign({ bots: ({ event }) => event.output }),
          target: 'botSelection',
        },
        onError: {
          target: 'closingModal',
          actions: emit({
            type: 'error',
            message: 'Failed to load bots',
          }),
        },
      },
      on: {
        CANCEL: {
          target: 'closingModal',
        },
      },
      tags: ['loading', 'step1'],
    },
    botSelection: {
      on: {
        NEXT: {
          target: 'summary',
        },
        CANCEL: {
          target: 'closingModal',
        },
      },
      tags: ['step1'],
    },

    summary: {
      on: {
        TRANSFER: {
          target: 'transferringFlows',
        },
        BACK: {
          target: 'botSelection',
        },
        CANCEL: {
          target: 'closingModal',
        },
      },
      tags: ['step2'],
    },

    transferringFlows: {
      invoke: {
        src: 'transferFlows',
        input: ({ event, context }) => {
          assertEvent(event, 'TRANSFER')
          context.transferDetails = event.transferDetails
          return event.transferDetails
        },
        onDone: {
          target: 'checkOpenTarget',
          actions: [
            emit(({ context }) => ({
              type: 'success',
              message: `Flows transferred to ${context.transferDetails?.targetBot?.name}`,
            })),
            emit(({ context }) => ({
              type: 'analytics',
              eventName: TrackEventName.TRANSFER_FLOW,
              eventProps: {
                open_destination_bot:
                  context.transferDetails?.openTargetBotOnTransfer,
                is_destination_production:
                  context.transferDetails?.targetBot?.name
                    .toLocaleLowerCase()
                    .includes('prod'),
              },
            })),
          ],
        },
        onError: {
          target: 'closingModal',
          actions: [
            emit(({ event }) => ({
              type: 'error',
              message: (event as any).error.message,
            })),
            emit(({ event }) => ({
              type: 'analytics',
              eventName: TrackEventName.FB_ERROR,
              eventProps: (event as any).error.analytics,
            })),
          ],
        },
      },
    },

    checkOpenTarget: {
      always: [
        {
          guard: 'openOnClose',
          target: 'openingTargetBot',
        },
        {
          target: 'closingModal',
        },
      ],
    },

    closingModal: {
      invoke: { src: 'closeModal', onDone: 'closed' },
    },

    openingTargetBot: {
      invoke: {
        src: 'openTargetBot',
        onDone: 'closed',
      },
    },

    closed: {
      type: 'final',
    },
  },
})
