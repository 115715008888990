import { Icon, IconButton } from '@hubtype/ui-react-web'

import { FeedbackType } from '../../../../domain/feedback'
import { ROLES } from '../../../roles'
import { TextBodySmallBold, TextSmall } from '../typography'
import { SnackbarContainer } from './ht-snackbar-styles'

export interface HtSnackbarProps {
  text: string
  title: string
  type: FeedbackType
  onClose: () => void
}

export const getIconFromFeedbackType = (feedbackType: FeedbackType): string => {
  switch (feedbackType) {
    case FeedbackType.SUCCESS:
      return 'circle-check'
    case FeedbackType.INFORM:
      return 'circle-info'
    case FeedbackType.CONNECTION_ISSUE:
      return 'cloud-slash'
    default:
      return 'circle-xmark'
  }
}

export const HtSnackbar = ({
  text,
  title,
  type,
  onClose,
}: HtSnackbarProps): JSX.Element => {
  return (
    <SnackbarContainer $type={type} role={ROLES.ALERT}>
      <Icon icon={getIconFromFeedbackType(type)} size='medium' prefix='far' />
      <div>
        <TextBodySmallBold>{title}</TextBodySmallBold>
        <TextSmall>{text}</TextSmall>
      </div>
      <IconButton icon='xmark' size='small' onPress={onClose} />
    </SnackbarContainer>
  )
}
