import { HtMediaFileLocale } from '../cms/hubtype/common'
import { ContentFieldsBase, MediaContentType } from '.'

export class AssetFields extends ContentFieldsBase {
  public assetUrl: string

  constructor(assetUrl: string) {
    super()
    this.assetUrl = assetUrl
  }

  contentType(): MediaContentType {
    return MediaContentType.ASSET
  }

  static fromHubtypeCMS(mediaFile: HtMediaFileLocale): AssetFields {
    const newAsset = new AssetFields(mediaFile.file)
    newAsset.id = mediaFile.id
    return newAsset
  }

  toHubtypeCMS(locale: string): HtMediaFileLocale | undefined {
    if (!this.id || !this.assetUrl) return undefined
    const node: HtMediaFileLocale = {
      id: this.id,
      file: this.assetUrl,
      locale,
    }
    return node
  }

  static getCopy(content: AssetFields): AssetFields {
    return new AssetFields(content.assetUrl)
  }
}
