import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import styles from './emoji-picker.module.css'

export interface BaseEmoji {
  id: string
  name: string
  native: string
}

export interface EmojiPickerProps {
  /** Ref of the input or textarea */
  inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement>
  /** Value of the input or textarea */
  value?: string
  /** Handler that is called when an emoji is selected */
  onEmojiSelect: (emoji: BaseEmoji) => void
}

export function EmojiPicker(props: EmojiPickerProps) {
  return (
    <div className={styles.base}>
      <Picker
        data={data}
        onEmojiSelect={props.onEmojiSelect}
        maxFrequentRows={1}
        previewPosition='none'
        theme='light'
      />
    </div>
  )
}

export default EmojiPicker
