import { FeedbackType } from '../../domain/feedback'
import { HtFlowBuilderData } from '../../domain/models/cms/hubtype/flow-builder-data'
import { FlowBuilderUser } from '../../domain/models/organization-models'
import { VersionHistoryData } from '../../UI/components/header/version-history-dropdown/version-history-dropdown'
import { FlowBuilderApi } from './flow-builder-api'
import { HubtypeService } from './hubtype-service'

export class FlowBuilderService {
  static async getUserInfo(
    authToken: string
  ): Promise<FlowBuilderUser | undefined> {
    const data = await FlowBuilderApi.get(authToken, `get-user-info/`)
    if (data.error) return undefined
    const user = await HubtypeService.getUserData(authToken, data.user_id)
    if (!user) return undefined
    return new FlowBuilderUser(data.bot_id, user)
  }

  static async loadFlow(
    authToken: string,
    flowVersion: string
  ): Promise<HtFlowBuilderData | undefined> {
    const data = await FlowBuilderApi.get(authToken, `flow/${flowVersion}`)
    return data.error ? undefined : data
  }

  static async saveFlow(
    authToken: string,
    flowData: HtFlowBuilderData
  ): Promise<FeedbackType> {
    const body = JSON.stringify(flowData)
    const data = await FlowBuilderApi.post(authToken, 'flow', body)
    return data.error || FeedbackType.SUCCESS
  }

  static async publishFlow(
    authToken: string,
    version: string
  ): Promise<FeedbackType> {
    const body = JSON.stringify({ version })
    const data = await FlowBuilderApi.post(authToken, 'publish', body)
    return data.error || FeedbackType.SUCCESS
  }

  static async getVersionHistory(
    authToken: string
  ): Promise<VersionHistoryData[] | { error: FeedbackType }> {
    const data = await FlowBuilderApi.get(authToken, 'flow')
    if (data.error) return data
    return data.map((v: any) => ({
      id: v.version,
      version: v.version,
      createdAt: new Date(v.created_at),
      createdBy: v.created_by,
      isPublished: v.is_published,
    }))
  }

  static async uploadFile(
    authToken: string,
    file: File
  ): Promise<string | null> {
    const sanitizedFileName = file.name.replace(/[\s?&=]/g, '-')
    const endpoint = `upload-media-file?filename=${sanitizedFileName}`
    const body = await file.arrayBuffer()
    const data = await FlowBuilderApi.postFile(
      authToken,
      endpoint,
      body,
      file.type
    )
    return data.error ? null : data.url
  }
}
