import { Select, SelectItem, SelectProps } from '@hubtype/ui-react-web'
import { memo, useState } from 'react'
import {} from 'react-stately'

import { TrackEventName, useAnalytics } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { TextBodySmallBold, TextSmallExtra } from '../../base'
import { HtEditableDropdownItem } from '../../base/common/dropdown/editable-dropdown-item'
import { StyledSelectHeader } from '../../base/common/dropdown/styles'
import { HtInputWithAction } from '../../base/ht-input/ht-input-with-action'
import { InputAction } from '../constants'

interface BotVariablesDropdownProps {
  readOnly?: boolean
  field?: InputAction
  selectedKey?: string
  onUseVariable: (variable: string) => void
}

export const BotVariablesDropdown = memo(
  (props: BotVariablesDropdownProps): JSX.Element => {
    const analytics = useAnalytics()
    const botVariables = useFlowBuilderSelector(ctx => ctx.state.botVariables)
    const setBotVariables = useFlowBuilderSelector(ctx => ctx.setBotVariables)
    const [filterValue, setFilterValue] = useState('')

    const isNewVariableValid = (newVariable: string): boolean => {
      return !botVariables.some(variable => variable === newVariable)
    }

    const onCreateVariable = (newVariable: string) => {
      newVariable = newVariable.trim()
      analytics.trackEvent(TrackEventName.VARIABLES_CREATE, {
        variable_name: newVariable,
        source: getSource(),
      })
      setBotVariables([...botVariables, newVariable])
      onUseVariable(newVariable)
    }

    const onRemoveVariable = (variableToRemove: string) => {
      analytics.trackEvent(TrackEventName.VARIABLES_DELETE, {
        variable_name: variableToRemove,
        source: getSource(),
      })
      setBotVariables(
        botVariables.filter(variable => variable !== variableToRemove)
      )
    }

    const onUseVariable = (variable: string) => {
      analytics.trackEvent(TrackEventName.VARIABLES_SELECT, {
        variable_name: variable,
        source: getSource(),
      })
      props.onUseVariable(variable)
    }

    const onOpenChange = (isOpen: boolean) => {
      setFilterValue('')
      if (!isOpen) return
      analytics.trackEvent(TrackEventName.VARIABLES_OPEN, {
        number_of_variables: botVariables.length,
        source: getSource(),
      })
    }

    const getSource = () => {
      return props.field ? 'condition' : 'text'
    }

    const dropdownProps: Partial<SelectProps<SelectItem>> = props.field
      ? {
          label: props.field.label,
          placeholder: props.field.placeholder,
          description: props.field.helperText,
          selectedKey: props.selectedKey,
          buttonProps: {
            buttonSymbol: <TextBodySmallBold>If</TextBodySmallBold>,
          },
        }
      : {
          label: 'Add variable',
          isLabelHidden: true,
          buttonProps: { size: 'small', width: 'max-content' },
          popoverProps: { placement: 'bottom start', width: '240px' },
        }

    return (
      <Select
        {...dropdownProps}
        items={botVariables.map(variable => ({ id: variable }))}
        onChange={value => value && onUseVariable(value.id)}
        onOpenChange={onOpenChange}
        filterValue={filterValue}
        isReadOnly={props.readOnly}
        header={
          <StyledSelectHeader>
            <HtInputWithAction
              inputProps={{ label: 'New variable' }}
              buttonText='Add'
              errorMessage='Variable already added'
              applyAction={onCreateVariable}
              isValueValid={isNewVariableValid}
              onValueChange={setFilterValue}
            />
            {botVariables.length === 0 && (
              <TextSmallExtra>
                There are no variables created yet. You need to define them
                first in the bot’s code and then create them here using the
                exact same name.
              </TextSmallExtra>
            )}
          </StyledSelectHeader>
        }
      >
        {variable => (
          <HtEditableDropdownItem
            item={variable}
            onRemove={() => onRemoveVariable(variable.id)}
          />
        )}
      </Select>
    )
  }
)
