import { Select } from '@hubtype/ui-react-web'
import { memo, useState } from 'react'

import { UrlFields } from '../../../../../domain/models/content-fields'
import { HTTP_URL_REGEX } from '../../../../constants'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { PopupType } from '../../../../types'
import { TextSmallExtra } from '../../../base'
import { HtEditableDropdownItem } from '../../../base/common/dropdown/editable-dropdown-item'
import { StyledSelectHeader } from '../../../base/common/dropdown/styles'
import { HtInputWithAction } from '../../../base/ht-input/ht-input-with-action'
import { InputAction } from '../../constants'

interface UrlDropdownProps {
  readOnly: boolean
  field: InputAction
  selectedUrl?: UrlFields
  changeProps?: {
    buttonIndex?: number
    elementIndex?: number
  }
  onChange: (action: any) => void
}

export const UrlDropdown = memo((props: UrlDropdownProps): JSX.Element => {
  const urls = useFlowBuilderSelector(ctx => ctx.state.nonMessageContents.urls)
  const { addUrl, setPopupContent } = useFlowBuilderSelector(ctx => ctx)
  const [errorMessage, setErrorMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [filterValue, setFilterValue] = useState('')

  const isNewUrlValid = (newUrl: string): boolean => {
    if (urls.some(url => url.name === newUrl)) {
      setErrorMessage('URL already exists')
      return false
    }
    if (newUrl && !HTTP_URL_REGEX.test(newUrl)) {
      setErrorMessage('Invalid URL')
      return false
    }
    setErrorMessage('')
    return true
  }

  const onAddUrl = (newUrl: string) => {
    const newUrlFields = new UrlFields(newUrl.trim())
    addUrl(newUrlFields)
    onSelectUrl(newUrlFields)
    setIsOpen(false)
  }

  const onRemoveUrl = (urlToRemove: UrlFields) => {
    setPopupContent({ type: PopupType.DELETE_URL, urlToRemove })
    setIsOpen(false)
  }

  const onEditUrl = (urlToEdit: UrlFields) => {
    setPopupContent({ type: PopupType.EDIT_URL, urlToEdit })
    setIsOpen(false)
  }

  const onSelectUrl = (url?: UrlFields) => {
    props.onChange({
      ...props.changeProps,
      type: props.field.actionType,
      fieldKey: props.field.key,
      value: url,
    })
  }

  const onOpenChange = () => {
    setIsOpen(!isOpen)
    setFilterValue('')
  }

  return (
    <Select
      isOpen={isOpen}
      label={props.field.label}
      placeholder={props.field.placeholder}
      description={props.field.helperText}
      items={urls}
      selectedKey={props.selectedUrl?.id}
      isReadOnly={props.readOnly}
      onChange={onSelectUrl}
      onOpenChange={onOpenChange}
      filterValue={filterValue}
      buttonProps={{
        tooltipProps: {
          content: props.selectedUrl?.name,
          isDisabled: !props.selectedUrl,
          placement: 'left',
          offset: 24,
        },
      }}
      isClearable={true}
      header={
        <StyledSelectHeader>
          <HtInputWithAction
            inputProps={{ label: 'Add URL' }}
            buttonText='Add'
            errorMessage={errorMessage}
            applyAction={onAddUrl}
            isValueValid={isNewUrlValid}
            onValueChange={setFilterValue}
          />
          {urls.length === 0 && (
            <TextSmallExtra>There are no URLs added yet.</TextSmallExtra>
          )}
        </StyledSelectHeader>
      }
    >
      {url => (
        <HtEditableDropdownItem
          key={url.id}
          item={url}
          onRemove={() => onRemoveUrl(url)}
          onEdit={() => onEditUrl(url)}
          canEdit={true}
        />
      )}
    </Select>
  )
})
