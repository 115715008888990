import { IconButton } from '@hubtype/ui-react-web'
import { memo } from 'react'

import { TextBody } from '../typography'
import { Size } from '../variants'
import { StyledChip } from './ht-chip-styles'

export type HtChipType =
  | 'default'
  | 'primary'
  | 'aqua'
  | 'green'
  | 'yellow'
  | 'orange'
  | 'red'
  | 'pink'
  | 'darkLilara'

export type HtChipSize = Size.MEDIUM | Size.LARGE

interface HtChipProps {
  label: string
  type?: HtChipType
  size?: HtChipSize
  disabled?: boolean
  onDelete?: () => void
}

export const HtChip = memo(
  ({
    type = 'default',
    size = Size.MEDIUM,
    label,
    disabled,
    onDelete,
  }: HtChipProps): JSX.Element => {
    return (
      <StyledChip $type={type} $size={size} $hasEllipsis={!onDelete}>
        <TextBody>{label}</TextBody>
        {!disabled && onDelete && (
          <IconButton icon='xmark' size='small' onPress={onDelete} />
        )}
      </StyledChip>
    )
  }
)
