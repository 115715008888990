import { Checkbox } from '@hubtype/ui-react-web'
import { Node } from '@react-types/shared'
import { useRef } from 'react'
import { useGridListItem, useGridListSelectionCheckbox } from 'react-aria'
import { ListState } from 'react-stately'

import { DropdownData } from '../../common/dropdown/types'
import { StyledListItem } from './multiselect-styles'

interface ListItemProps {
  state: ListState<DropdownData>
  item: Node<DropdownData>
}

export const ListItem = ({ item, state }: ListItemProps): JSX.Element => {
  const ref = useRef(null)
  const { rowProps, gridCellProps } = useGridListItem(
    { node: item },
    state,
    ref
  )

  return (
    <StyledListItem {...rowProps} {...gridCellProps} ref={ref}>
      <ItemCheckbox item={item} state={state} />
    </StyledListItem>
  )
}

const ItemCheckbox = ({ item, state }: ListItemProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { checkboxProps } = useGridListSelectionCheckbox(
    { key: item.key },
    state
  )

  return <Checkbox {...checkboxProps} ref={inputRef} label={item.value?.name} />
}
