import { Icon } from '@hubtype/ui-react-web'
import { PresenceMessage } from 'ably'

import { TrackEventName, useAnalytics } from '../../analytics'
import { Popup } from '../../components/popup/popup'
import { postCloseFromFlowBuilderMessage } from '../../hubtype-events'
import { ConnectionLostPopup } from './connection-lost'
import styles from './session-modal.module.css'

export interface SessionEndedPopupProps {
  activeUser?: PresenceMessage
}

export const SessionEndedPopup = ({
  activeUser,
}: SessionEndedPopupProps): JSX.Element => {
  const analytics = useAnalytics()

  const discard = (): void => {
    analytics.trackEvent(TrackEventName.USER_KICKED_OUT)
    postCloseFromFlowBuilderMessage(null)
  }

  if (!activeUser) return <ConnectionLostPopup />

  return (
    <Popup
      title={
        <div className={styles.title}>
          <Icon icon='exclamation-circle' size='medium' />
          Session ended
        </div>
      }
      discardButton={{ text: 'Close', onClick: discard }}
      message='Your session has been ended by another user. All changes have been saved.'
      canCloseOnClickOutside={false}
      closeButtonEnabled={false}
    >
      <div className={styles.sessionBox}>
        <div className={styles.activeUser}>
          <strong>Session ended by</strong>
          {activeUser.data.userName}
        </div>
      </div>
    </Popup>
  )
}
