import { createContext, useContext } from 'react'
import { ListState } from 'react-stately'

import { SelectItem } from '../select/select'

export const MultiselectStateContext =
  createContext<ListState<SelectItem> | null>(null)

export const useMultiselectListContext = () => {
  const result = useContext(MultiselectStateContext)
  if (!result) {
    throw new Error('MultiselectStateContext used outside of its Provider')
  }
  return result
}
