import { Edge } from 'reactflow'

import {
  AssetFields,
  ButtonFields,
  ContentType,
  ElementFields,
  NonMessageContentFields,
  WhatsappButtonListRowFields,
  WhatsappButtonListSectionFields,
} from '../../../domain/models/content-fields'

export enum ActionType {
  ADD_BUTTON = 'ADD_BUTTON',
  ADD_CONDITION_VALUE = 'ADD_VALUE',
  ADD_ELEMENT = 'ADD_ELEMENT',
  ADD_ROW = 'ADD_ROW',
  ADD_SECTION = 'ADD_SECTION',
  CHANGE_BUTTON_STYLE = 'CHANGE_BUTTON_STYLE',
  DELETE_BUTTON = 'DELETE_BUTTON',
  DELETE_ELEMENT = 'DELETE_ELEMENT',
  DELETE_ROW = 'DELETE_ROW',
  DELETE_SECTION = 'DELETE_SECTION',
  MOVE_SUB_CONTENT = 'MOVE_SUB_CONTENT',
  UPDATE_BUTTON_CONTENT = 'UPDATE_BUTTON_CONTENT',
  UPDATE_CONDITION_VALUE = 'UPDATE_CONDITION_VALUE',
  UPDATE_CONTENT = 'UPDATE_CONTENT',
  UPDATE_ELEMENT_BUTTON = 'UPDATE_ELEMENT_BUTTON',
  UPDATE_ELEMENT_CONTENT = 'UPDATE_ELEMENT_CONTENT',
  UPDATE_ROW = 'UPDATE_ROW',
  UPDATE_SECTION = 'UPDATE_SECTION',
}

export enum ContentKey {
  AUTO_ASSIGN = 'hasAutoAssign',
  CHANNELS = 'channels',
  CHECK_AVAILABLE_AGENTS = 'checkAvailableAgents',
  CODE = 'code',
  CONDITION_VARIABLE = 'conditionVariable',
  CONFIDENCE = 'confidence',
  COUNTRIES = 'countries',
  DESCRIPTION = 'description',
  FOOTER = 'footer',
  HEADER = 'header',
  IMAGE = 'image',
  INTENTS = 'intents',
  IS_MEANINGFUL = 'isMeaningful',
  KEYWORDS = 'keywords',
  KNOWLEDGE_FEEDBACK = 'feedbackEnabled',
  KNOWLEDGE_SOURCE = 'sources',
  NAME = 'name',
  PAYLOAD = 'payload',
  PAYLOAD_ID = 'payloadId',
  PAYLOAD_PARAMS = 'payloadParams',
  QUEUE = 'queue',
  SELECTED_SUB_CONTENT = 'selectedSubContentId',
  SUBTITLE = 'subtitle',
  TARGET_FLOW_ID = 'targetFlowId',
  TEXT = 'text',
  TITLE = 'title',
  URL = 'url',
  VARIABLE_FORMAT = 'variableFormat',
  VIDEO = 'videoUrl',
  WHATSAPP_BUTTON_TEXT = 'listButtonText',
}

export enum HelperText {
  AUTO_ASSIGN = 'Check this box if you want new cases to be automatically assigned to any available agent.',
  BUTTON_STYLE = 'Quick replies are buttons located at the bottom of the conversation. When a quick reply is tapped, the other ones will be dismissed.',
  CHECK_AVAILABLE_AGENTS = 'Check this box if you want to specify a different flow if the queue is open but there are no agents available at the moment.',
  CODE = 'Once set, content ID should not be changed to avoid messing data in logs and reports.',
  CONFIDENCE = 'Minimum confidence percentage from which the intent defined action will be triggered.',
  FLOW = 'Select the flow to which users will be redirected.',
  HANDOFF_PAYLOAD = 'Identifies and triggers, once the case is resolved or discarded, an action previously defined in the conversational app’s code.',
  INTENTS = 'Select an intent or type a new value and hit enter. Keep in mind that new values require to be defined in the bot code.',
  KEYWORDS = 'Type a new value and hit enter',
  KNOWLEDGE_FEEDBACK = 'Activate to add thumbs up & down feedback buttons next to the knowledge base generated message. (Webchat only)',
  MEANINGFUL_ELEMENT = 'Activate to indicate in analytics that conversations with users who reach this point are likely to be successful. Conversations that don’t reach a meaningful element will be considered a dropoff.',
  NUMBERS_ONLY = 'Numbers only',
  PAYLOAD = 'This is the action (previously defined in the bot’s code) that will be triggered.',
  PAYLOAD_PARAMS = 'JSON format required.',
  QUEUE = 'Name of the queue where the case will be sent to.',
  ROW_DESCRIPTION = 'Provides additional context for the selection choice.',
  ROW_TITLE = 'A single selection choice that will be a selectable button.',
  SMART_INTENT_DESCRIPTION = 'Provide a brief description of the user intention that the AI should detect.',
  SMART_INTENT_TITLE = 'Provide a short title that the AI will use along with the description below to identify the intent to be detected.',
  URL = 'Use a URL with a valid format (https://...)',
  VIDEO = 'Enter a valid video URL. Valid video file extensions include MP4 and 3GP. Maximum file size is 100MB.',
}

export enum Label {
  ADD_BUTTON = 'Add button',
  ADD_CONDITION_VALUE = 'Add value',
  ADD_ELEMENT = 'Add Element',
  ADD_REPLY = 'Add quick reply',
  ADD_ROW = 'Add row',
  ADD_SECTION = 'Add section',
  AUTO_ASSIGN = 'Auto-assign cases to agents',
  BUTTON_PAYLOAD = 'Button bot action',
  BUTTON_STYLE = 'Show buttons as quick replies',
  BUTTON_TEXT = 'Button text',
  BUTTON_URL = 'Button URL',
  CHANNELS = 'Channels that will follow their own flow',
  CHECK_AVAILABLE_AGENTS = 'Distinct flow based on agent availability',
  CODE = 'Content ID',
  CONDITION_VALUE = 'Condition value',
  CONDITION_VARIABLE = 'Condition variable',
  CONFIDENCE = 'Intent confidence',
  COUNTRIES = 'Countries',
  DESCRIPTION = 'Description',
  FLOW = 'Flow',
  FOOTER = 'Footer (optional)',
  HANDOFF_BOT_ACTION = 'Bot action after agent assistance',
  HEADER = 'Header (optional)',
  IMAGE = 'Image',
  INTENTS = 'Intent selection',
  KEYWORDS = 'Keywords selection',
  KNOWLEDGE_FEEDBACK = 'Ask for user feedback',
  KNOWLEDGE_SOURCE = 'Knowledge sources',
  MAIN_TEXT = 'Main text',
  MEANINGFUL_ELEMENT = 'Meaningful element',
  PAYLOAD = 'Payload',
  PAYLOAD_PARAMS = 'Payload parameters',
  QUEUE = 'Queue',
  ROW_DESCRIPTION = 'Row description (optional)',
  ROW_TITLE = 'Row title',
  SECTION_TITLE = 'Section title',
  SUBTITLE = 'Subtitle',
  TEXT = 'Message text',
  TITLE = 'Title',
  VARIABLE_FORMAT = 'Variable format',
  VIDEO = 'Video embed',
  WHATSAPP_BUTTON_LIST_TEXT = 'Message text to introduce list',
  WHATSAPP_BUTTON_TEXT = 'Button to open list text / List header',
}

export enum Placeholder {
  CHANNELS = 'Select channels',
  CODE = 'Content ID',
  CONDITION_VALUE = 'Type value in',
  CONDITION_VARIABLE = 'Select variable',
  COUNTRIES = 'Select countries',
  FLOW = 'Select flow',
  FOOTER = 'Footer',
  HANDOFF_PAYLOAD = 'Select an action',
  HEADER = 'Header',
  INTENTS = 'Select intents',
  KEYWORDS = 'New keyword',
  KNOWLEDGE_SOURCE = 'Add sources',
  PAYLOAD = 'Select payload',
  PAYLOAD_PARAMS = 'Insert parameters',
  SMART_INTENT_DESCRIPTION = 'Intent description',
  SMART_INTENT_TITLE = 'Intent title',
  URL = 'Select URL',
  VARIABLE_FORMAT = 'Select format',
  VIDEO = 'https://',
  WHATSAPP_BUTTON_TEXT = 'Button / List header',
}

export enum ErrorText {
  CODE_REPEATED = 'Content ID already exists.',
  INVALID_CHARACTERS = 'Use only alphanumeric characters plus _ . # & + - <> and blank spaces, without accents.',
  NUMBERS_ONLY = 'Numbers only',
  PAYLOAD = 'Ignoring payload since url is defined',
  PAYLOAD_PARAMS = 'JSON format required.',
  ROW_TITLE = "Row titles can't be duplicated, change this title or delete the other one.",
  SECTION_TITLE = "Section titles can't be duplicated, change this title or delete the other one.",
  SMART_INTENT_TITLE_REPEATED = 'Title already exists.',
  VIDEO = 'Enter a valid video URL. Valid video file extensions include MP4 and 3GP. Maximum file size is 100MB.',
}

export type EditorValueTypes =
  | string
  | number
  | boolean
  | AssetFields
  | NonMessageContentFields
  | undefined
  | any[]

export type Action =
  | { type: ActionType.ADD_BUTTON }
  | { type: ActionType.ADD_CONDITION_VALUE }
  | { type: ActionType.ADD_ELEMENT }
  | { type: ActionType.ADD_ROW }
  | { type: ActionType.ADD_SECTION }
  | { type: ActionType.CHANGE_BUTTON_STYLE; value: boolean }
  | { type: ActionType.DELETE_BUTTON; buttonToDelete: ButtonFields }
  | { type: ActionType.DELETE_ELEMENT; elementToDelete: ElementFields }
  | { type: ActionType.DELETE_ROW; rowToDelete: WhatsappButtonListRowFields }
  | {
      type: ActionType.DELETE_SECTION
      sectionToDelete: WhatsappButtonListSectionFields
    }
  | {
      type: ActionType.MOVE_SUB_CONTENT
      selectedSubContentId: string
      subContents: ButtonFields[] | ElementFields[]
      contentType: ContentType
    }
  | {
      type: ActionType.UPDATE_BUTTON_CONTENT
      buttonIndex?: number
      fieldKey: string
      value: string
    }
  | {
      type: ActionType.UPDATE_CONTENT
      fieldKey: string
      value: EditorValueTypes
      edges?: Edge[]
    }
  | {
      type: ActionType.UPDATE_ELEMENT_BUTTON
      elementIndex: number
      buttonIndex: number
      fieldKey: string
      value: string | NonMessageContentFields
    }
  | {
      type: ActionType.UPDATE_ELEMENT_CONTENT
      elementIndex: number
      fieldKey: string
      value: string | AssetFields
    }
  | {
      type: ActionType.UPDATE_ROW
      rowId: string
      fieldKey: string
      value: string
    }
  | {
      type: ActionType.UPDATE_CONDITION_VALUE
      conditionValueId: string
      fieldKey: string
      value: string
    }
  | {
      type: ActionType.UPDATE_SECTION
      sectionId: string
      fieldKey: string
      value: string
    }

export class InputAction {
  constructor(
    readonly actionType: ActionType,
    readonly key?: ContentKey,
    readonly label?: string,
    readonly helperText?: HelperText,
    readonly placeholder?: Placeholder,
    public errorText?: ErrorText
  ) {}
}

export const ADD_BUTTON = new InputAction(
  ActionType.ADD_BUTTON,
  undefined,
  Label.ADD_BUTTON
)
export const ADD_ELEMENT = new InputAction(
  ActionType.ADD_ELEMENT,
  undefined,
  Label.ADD_ELEMENT
)
export const ADD_REPLY = new InputAction(
  ActionType.ADD_BUTTON,
  undefined,
  Label.ADD_REPLY
)
export const ADD_ROW = new InputAction(
  ActionType.ADD_ROW,
  undefined,
  Label.ADD_ROW
)
export const ADD_SECTION = new InputAction(
  ActionType.ADD_SECTION,
  undefined,
  Label.ADD_SECTION
)
export const ADD_CONDITION_VALUE = new InputAction(
  ActionType.ADD_CONDITION_VALUE,
  undefined,
  Label.ADD_CONDITION_VALUE
)
export const BUTTON_STYLE = new InputAction(
  ActionType.CHANGE_BUTTON_STYLE,
  undefined,
  Label.BUTTON_STYLE,
  HelperText.BUTTON_STYLE
)
export const AUTO_ASSIGN = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.AUTO_ASSIGN,
  Label.AUTO_ASSIGN,
  HelperText.AUTO_ASSIGN
)
export const BUTTON_TEXT = new InputAction(
  ActionType.UPDATE_BUTTON_CONTENT,
  ContentKey.TEXT,
  Label.BUTTON_TEXT
)
export const CHANNELS = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.CHANNELS,
  Label.CHANNELS,
  undefined,
  Placeholder.CHANNELS
)
export const KNOWLEDGE_FEEDBACK = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.KNOWLEDGE_FEEDBACK,
  Label.KNOWLEDGE_FEEDBACK,
  HelperText.KNOWLEDGE_FEEDBACK
)
export const KNOWLEDGE_SOURCES = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.KNOWLEDGE_SOURCE,
  Label.KNOWLEDGE_SOURCE,
  undefined,
  Placeholder.KNOWLEDGE_SOURCE
)
export const CHECK_AVAILABLE_AGENTS = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.CHECK_AVAILABLE_AGENTS,
  Label.CHECK_AVAILABLE_AGENTS,
  HelperText.CHECK_AVAILABLE_AGENTS
)
export const CODE = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.CODE,
  Label.CODE,
  HelperText.CODE,
  Placeholder.CODE,
  ErrorText.CODE_REPEATED
)
export const CONDITION_VALUE = new InputAction(
  ActionType.UPDATE_CONDITION_VALUE,
  ContentKey.NAME,
  Label.CONDITION_VALUE,
  undefined,
  Placeholder.CONDITION_VALUE,
  ErrorText.NUMBERS_ONLY
)
export const CONDITION_VARIABLE = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.CONDITION_VARIABLE,
  Label.CONDITION_VARIABLE,
  undefined,
  Placeholder.CONDITION_VARIABLE
)
export const CONFIDENCE = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.CONFIDENCE,
  Label.CONFIDENCE,
  HelperText.CONFIDENCE
)
export const COUNTRIES = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.COUNTRIES,
  Label.COUNTRIES,
  undefined,
  Placeholder.COUNTRIES
)
export const ELEMENT_BUTTON_PAYLOAD = new InputAction(
  ActionType.UPDATE_ELEMENT_BUTTON,
  ContentKey.PAYLOAD,
  Label.BUTTON_PAYLOAD,
  HelperText.PAYLOAD,
  undefined,
  ErrorText.PAYLOAD
)
export const ELEMENT_BUTTON_TEXT = new InputAction(
  ActionType.UPDATE_ELEMENT_BUTTON,
  ContentKey.TEXT,
  Label.BUTTON_TEXT
)
export const ELEMENT_BUTTON_URL = new InputAction(
  ActionType.UPDATE_ELEMENT_BUTTON,
  ContentKey.URL,
  Label.BUTTON_URL,
  HelperText.URL,
  Placeholder.URL
)
export const ELEMENT_IMAGE = new InputAction(
  ActionType.UPDATE_ELEMENT_CONTENT,
  ContentKey.IMAGE,
  Label.IMAGE
)
export const ELEMENT_TITLE = new InputAction(
  ActionType.UPDATE_ELEMENT_CONTENT,
  ContentKey.TITLE,
  Label.TITLE
)
export const ELEMENT_SUBTITLE = new InputAction(
  ActionType.UPDATE_ELEMENT_CONTENT,
  ContentKey.SUBTITLE,
  Label.SUBTITLE
)
export const FLOW = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.TARGET_FLOW_ID,
  Label.FLOW,
  HelperText.FLOW,
  Placeholder.FLOW
)
export const HANDOFF_PAYLOAD = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.PAYLOAD,
  Label.HANDOFF_BOT_ACTION,
  HelperText.HANDOFF_PAYLOAD,
  Placeholder.HANDOFF_PAYLOAD
)
export const IMAGE = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.IMAGE,
  Label.IMAGE
)
export const INTENTS = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.INTENTS,
  Label.INTENTS,
  HelperText.INTENTS,
  Placeholder.INTENTS
)
export const KEYWORDS = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.KEYWORDS,
  Label.KEYWORDS,
  HelperText.KEYWORDS,
  Placeholder.KEYWORDS
)
export const MEANINGFUL_ELEMENT = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.IS_MEANINGFUL,
  Label.MEANINGFUL_ELEMENT,
  HelperText.MEANINGFUL_ELEMENT
)
export const MOVE_SUB_CONTENT = new InputAction(ActionType.MOVE_SUB_CONTENT)
export const BUTTON_PAYLOAD = new InputAction(
  ActionType.UPDATE_BUTTON_CONTENT,
  ContentKey.PAYLOAD,
  Label.BUTTON_PAYLOAD,
  HelperText.PAYLOAD,
  Placeholder.PAYLOAD,
  ErrorText.PAYLOAD
)
export const PAYLOAD = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.PAYLOAD_ID,
  Label.PAYLOAD,
  HelperText.PAYLOAD,
  Placeholder.PAYLOAD,
  ErrorText.PAYLOAD
)
export const PAYLOAD_PARAMS = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.PAYLOAD_PARAMS,
  Label.PAYLOAD_PARAMS,
  HelperText.PAYLOAD_PARAMS,
  Placeholder.PAYLOAD_PARAMS,
  ErrorText.PAYLOAD_PARAMS
)
export const QUEUE = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.QUEUE,
  Label.QUEUE,
  HelperText.QUEUE
)
export const ROW_DESCRIPTION = new InputAction(
  ActionType.UPDATE_ROW,
  ContentKey.DESCRIPTION,
  Label.ROW_DESCRIPTION,
  HelperText.ROW_DESCRIPTION
)
export const ROW_TITLE = new InputAction(
  ActionType.UPDATE_ROW,
  ContentKey.TITLE,
  Label.ROW_TITLE,
  HelperText.ROW_TITLE,
  undefined,
  ErrorText.ROW_TITLE
)
export const SECTION_TITLE = new InputAction(
  ActionType.UPDATE_SECTION,
  ContentKey.TITLE,
  Label.SECTION_TITLE,
  undefined,
  undefined,
  ErrorText.SECTION_TITLE
)
export const SELECT_SUB_CONTENT = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.SELECTED_SUB_CONTENT
)
export const SMART_INTENT_DESCRIPTION = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.DESCRIPTION,
  Label.DESCRIPTION,
  HelperText.SMART_INTENT_DESCRIPTION,
  Placeholder.SMART_INTENT_DESCRIPTION
)
export const SMART_INTENT_TITLE = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.TITLE,
  Label.TITLE,
  HelperText.SMART_INTENT_TITLE,
  Placeholder.SMART_INTENT_TITLE,
  ErrorText.SMART_INTENT_TITLE_REPEATED
)
export const TEXT = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.TEXT,
  Label.TEXT
)
export const URL = new InputAction(
  ActionType.UPDATE_BUTTON_CONTENT,
  ContentKey.URL,
  Label.BUTTON_URL,
  HelperText.URL,
  Placeholder.URL
)
export const VARIABLE_FORMAT = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.VARIABLE_FORMAT,
  Label.VARIABLE_FORMAT,
  undefined,
  Placeholder.VARIABLE_FORMAT
)
export const VIDEO = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.VIDEO,
  Label.VIDEO,
  HelperText.VIDEO,
  Placeholder.VIDEO,
  ErrorText.VIDEO
)
export const WHATSAPP_BUTTON_TEXT = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.WHATSAPP_BUTTON_TEXT,
  Label.WHATSAPP_BUTTON_TEXT,
  undefined,
  Placeholder.WHATSAPP_BUTTON_TEXT
)
export const WHATSAPP_BUTTON_LIST_TEXT = new InputAction(
  ActionType.UPDATE_CONTENT,
  ContentKey.TEXT,
  Label.WHATSAPP_BUTTON_LIST_TEXT
)
