import { memo } from 'react'

import { isWebview } from '../../../webviews/utils'
import { ImageNodeProps } from '../../types'
import { Handles, NodeHeader } from './node-components'
import { Image, MessageNode } from './node-styles'

const ImageNode = ({ data, selected }: ImageNodeProps) => {
  return (
    <MessageNode $isSelected={selected}>
      {!isWebview(data.flowId) && (
        <Handles hasFollowUp={true} contentId={data.getContentId()} />
      )}
      <NodeHeader data={data} title={'Image'} icon='image' />
      {data.image?.assetUrl && <Image src={data.image.assetUrl} />}
    </MessageNode>
  )
}

export default memo(ImageNode)
