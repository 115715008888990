import { Node } from '@react-types/shared'
import { useRef } from 'react'
import { useGridListItem } from 'react-aria'

import { SelectItem } from '../../select/select'
import { useMultiselectListContext } from '../context'
import styles from '../multiselect.module.css'
import { ListCheckbox } from './list-checkbox'

export interface ListItemProps {
  item: Node<SelectItem>
}

export const ListItem = ({ item }: ListItemProps): JSX.Element => {
  const ref = useRef(null)
  const state = useMultiselectListContext()
  const { rowProps, gridCellProps } = useGridListItem(
    { node: item },
    state,
    ref
  )

  return (
    <div className={styles.item} {...rowProps} {...gridCellProps} ref={ref}>
      <ListCheckbox item={item} />
    </div>
  )
}
