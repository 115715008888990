import styled, { IStyledComponent } from 'styled-components'

import TrashIcon from '../../assets/edge-trash-icon.svg'
import ToSourceIcon from '../../assets/to-source-icon.svg'
import ToTargetIcon from '../../assets/to-target-icon.svg'
import { COLORS } from '../base'

type StyledSvgProps = IStyledComponent<
  'web',
  Omit<
    React.SVGProps<SVGSVGElement>,
    keyof React.DOMAttributes<SVGSVGElement>
  > & {
    onClick?: (evt: React.MouseEvent) => void
  }
>

export const EdgePath = styled.path<{
  $hasHover: boolean
  $isSelected?: boolean
}>`
  cursor: pointer;
  fill: none;
  stroke: ${COLORS.WHITE};
  stroke-width: 2;
  ${props => (props.$hasHover || props.$isSelected) && `stroke-width: 3.5`};
`

export const EdgeBackground = styled.path`
  cursor: pointer;
  fill: none;
  stroke-width: 20;
`

export const RemoveButton = styled(TrashIcon)`
  pointer-events: bounding-box;
  cursor: pointer;
` as StyledSvgProps

export const ToTarget = styled(ToTargetIcon)`
  pointer-events: bounding-box;
  cursor: pointer;
` as StyledSvgProps

export const ToSource = styled(ToSourceIcon)`
  pointer-events: bounding-box;
  cursor: pointer;
` as StyledSvgProps
