import { Select } from '@hubtype/ui-react-web'

import { LocaleCode } from '../../../domain/models/locales/code'
import { Locale } from '../../../domain/models/locales/locale'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { TextSmall } from '../base'
import { CopyFromContainer } from './locale-item-styles'

interface LocaleItemProps {
  currentCopyFrom?: LocaleCode
  changeCopyFrom: (copyFrom?: LocaleCode) => void
}

type None = 'none'
const NO_COPY_FROM = 'none' as None

export const CopyFromDropdown = ({
  currentCopyFrom,
  changeCopyFrom,
}: LocaleItemProps): JSX.Element => {
  const stateLocales = useFlowBuilderSelector(ctx => ctx.state.locales)

  const onChange = (value?: Locale | { id: None }) => {
    if (!value) return
    if (value.id === NO_COPY_FROM) changeCopyFrom(undefined)
    else changeCopyFrom(value.code)
  }

  const getItemText = (value: Locale | { id: None }) => {
    if (value.id === NO_COPY_FROM) return <TextSmall>None</TextSmall>
    return (
      <TextSmall>
        {value.language} ({value.code})
      </TextSmall>
    )
  }

  return (
    <CopyFromContainer>
      <TextSmall>Copy from</TextSmall>
      <Select
        label='Copy from'
        items={[{ id: NO_COPY_FROM }, ...stateLocales]}
        selectedKey={currentCopyFrom || NO_COPY_FROM}
        buttonProps={{
          size: 'small',
          intent: 'secondary',
          width: 'fit-content',
        }}
        popoverProps={{ placement: 'bottom start' }}
        isLabelHidden={true}
        onChange={onChange}
        renderButtonAsItem={true}
      >
        {getItemText}
      </Select>
    </CopyFromContainer>
  )
}
