import { DragEvent } from 'react'
import { XYPosition } from 'reactflow'

import {
  BotActionFields,
  CarouselFields,
  ChannelFields,
  ContentType,
  CountryConditionFields,
  ElementFields,
  GoToFlowFields,
  HandoffFields,
  ImageFields,
  IntentFields,
  KeywordFields,
  QueueStatusFields,
  TextFields,
  TopContentFields,
  VideoFields,
  WhatsappButtonListFields,
} from '../../../../domain/models/content-fields'
import { CustomConditionFields } from '../../../../nodes/custom-conditional'
import { ConditionValueFields } from '../../../../nodes/custom-conditional/condition-value/model'
import { KnowledgeBaseFields } from '../../../../nodes/knowledge-base'
import { SmartIntentFields } from '../../../../nodes/smart-intent'
import { WhatsappCTAUrlButtonFields } from '../../../../nodes/whatsapp-cta-url-button'
import { Icon } from '../../../components/base'
import { NODE_HEIGHT, NODE_WIDTH, Provider } from '../../../constants'
import { getContentType } from '../../../node-utils'
import { NodeTypes, State } from '../../../types'
import { ActionType } from '../../action-types'
import { ReversibleAction } from '../reversible-action'
import { NodeAction } from './node-action'

export interface DropNodeInterface {
  type: ActionType.NODE_DROP
  event: DragEvent
}

export interface DropNodeHistoryChange {
  type: ActionType.NODE_DROP
  newNode: NodeTypes
  currentNode?: NodeTypes
  currentFlowId: string
}

export class DropNodeAction extends ReversibleAction {
  static apply = (state: State, event: DragEvent): void => {
    const newNode = this.getNewNode(state, event)
    if (newNode) {
      newNode.data.setErrors()
      newNode.data.getLocalesWithErrors(
        state.locales.map(locale => locale.code)
      )
      this.trackHistoryChange(state, newNode)
      NodeAction.addNode(state, newNode)
      NodeAction.selectNode(state, newNode)
    }
  }

  static undo = (state: State, change: DropNodeHistoryChange) => {
    NodeAction.removeNode(state, change.newNode)
  }

  static redo = (state: State, change: DropNodeHistoryChange) => {
    NodeAction.addNode(state, change.newNode)
    NodeAction.selectNode(state, change.newNode)
  }

  private static trackHistoryChange = (state: State, newNode: NodeTypes) => {
    const newChange: DropNodeHistoryChange = {
      type: ActionType.NODE_DROP,
      newNode,
      currentNode: state.currentNode,
      currentFlowId: state.currentFlowId,
    }
    this.updateChangesHistory(state, newChange)
  }

  private static getNewNode = (
    state: State,
    event: DragEvent
  ): NodeTypes | null => {
    event.preventDefault()
    const contentType = getContentType(event)
    if (!contentType) return null
    const position = this.getNewNodePosition(state, event)
    const newData = this.getNewData(contentType)
    if (newData && position) {
      return NodeAction.createNewNode(newData, position, state.currentFlowId)
    }
    return null
  }

  private static getNewData = (
    contentType: ContentType
  ): TopContentFields | null => {
    switch (contentType) {
      case ContentType.TEXT:
        return new TextFields()
      case ContentType.CAROUSEL: {
        const newElement = new ElementFields()
        return new CarouselFields([newElement], newElement.id)
      }
      case ContentType.IMAGE:
        return new ImageFields()
      case ContentType.VIDEO:
        return new VideoFields()
      case ContentType.HANDOFF:
        return new HandoffFields()
      case ContentType.WHATSAPP_BUTTON_LIST:
        return new WhatsappButtonListFields()
      case ContentType.KEYWORD:
        return new KeywordFields()
      case ContentType.INTENT:
        return new IntentFields()
      case ContentType.QUEUE_STATUS:
        return new QueueStatusFields()
      case ContentType.CHANNEL:
        return new ChannelFields([
          { id: Provider.Whatsapp, icon: 'whatsapp', name: 'WhatsApp' },
        ])
      case ContentType.GO_TO_FLOW:
        return new GoToFlowFields()
      case ContentType.BOT_ACTION:
        return new BotActionFields()
      case ContentType.COUNTRY_CONDITION:
        return new CountryConditionFields()
      case ContentType.CUSTOM_CONDITION:
        return new CustomConditionFields([new ConditionValueFields()])
      case ContentType.SMART_INTENT:
        return new SmartIntentFields()
      case ContentType.WHATSAPP_CTA_URL_BUTTON:
        return new WhatsappCTAUrlButtonFields()
      case ContentType.KNOWLEDGE_BASE:
        return new KnowledgeBaseFields()
      default:
        throw new Error(`Node of type ${contentType} can not be dropped`)
    }
  }

  private static getNewNodePosition = (
    state: State,
    event: DragEvent
  ): XYPosition | null => {
    if (!state.reactFlowInstance || !state.reactFlowWrapper?.current) {
      return null
    }
    const reactFlowBounds =
      state.reactFlowWrapper.current.getBoundingClientRect() as DOMRect
    const zoom = state.reactFlowInstance.getZoom()
    const position = state.reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left - (NODE_WIDTH / 2) * zoom,
      y: event.clientY - reactFlowBounds.top - (NODE_HEIGHT / 2) * zoom,
    })
    return position
  }
}
