import { createContext, useContext } from 'react'
import { SelectState } from 'react-stately'

export const SelectStateContext = createContext<SelectState<object> | null>(
  null
)

export const useSelectStateContext = () => {
  const result = useContext(SelectStateContext)
  if (!result) {
    throw new Error('SelectStateContext used outside of its Provider')
  }
  return result
}
