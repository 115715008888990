import { Item } from 'react-stately'

import {
  SelectDropdown,
  SelectDropdownProps,
} from './components/select-dropdown'

export interface SelectItem {
  id: string
  name?: string
}

export interface SelectProps<T extends SelectItem>
  extends Omit<SelectDropdownProps<T>, 'onSelectionChange'> {
  onChange?: (value: T | undefined) => void
}

/** A select displays a collapsible list of options and allows a user to select one of them. */
export const Select = <T extends SelectItem>(
  props: SelectProps<T>
): JSX.Element => {
  const onSelectionChange = (id?: React.Key) => {
    const selectedValue = props.items.find(item => item.id === id)
    props.onChange?.(selectedValue)
  }

  return (
    <SelectDropdown {...props} onSelectionChange={onSelectionChange}>
      {item => (
        <Item textValue={item.name || item.id}>
          {props.children?.(item) || item.name || item.id}
        </Item>
      )}
    </SelectDropdown>
  )
}
