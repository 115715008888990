import { normalizeValue } from '@hubtype/util-shared'
import { Node } from '@react-types/shared'
import { Key, useRef } from 'react'
import { useGridList } from 'react-aria'
import { ListProps, OverlayTriggerState, useListState } from 'react-stately'

import { Button } from '../../button/button'
import { SelectItem, SelectProps } from '../../select/select'
import { MultiselectStateContext } from '../context'
import styles from '../multiselect.module.css'
import { ListItem } from './list-item'

interface CheckboxListProps<T extends SelectItem>
  extends Omit<ListProps<T>, 'children' | 'disabledKeys' | 'items'>,
    SelectProps<T> {
  /** The state of the overlay trigger. */
  overlayState: OverlayTriggerState
  /** The text of the apply button. */
  applyButtonText: string
  /** Handler that is called when the apply button is pressed. */
  onApply: (keys: Key[]) => void
}

export const CheckboxList = <T extends SelectItem>({
  overlayState,
  ...props
}: CheckboxListProps<T>): JSX.Element => {
  const ref = useRef<HTMLUListElement>(null)
  const state = useListState(props)
  const { gridProps } = useGridList(props, state, ref)

  const onApply = () => {
    props.onApply(Array.from(state.selectionManager.selectedKeys))
    overlayState.close()
  }

  const shouldFilterItem = (item: Node<object>): boolean => {
    return Boolean(
      props.filterValue &&
        item.textValue &&
        !normalizeValue(item.textValue).includes(
          normalizeValue(props.filterValue)
        )
    )
  }

  return (
    <MultiselectStateContext.Provider value={state}>
      <ul className={styles.ul} {...gridProps}>
        {[...state.collection].map(
          item =>
            !shouldFilterItem(item) && <ListItem key={item.key} item={item} />
        )}
      </ul>
      <div className={styles.footer}>
        <Button size='small' onPress={onApply}>
          {props.applyButtonText}
        </Button>
      </div>
    </MultiselectStateContext.Provider>
  )
}
