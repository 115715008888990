import { normalizeValue } from '@hubtype/util-shared'
import { Node } from '@react-types/shared'
import { useRef } from 'react'
import { AriaListBoxOptions, useGridList, useListBox } from 'react-aria'

import { useSelectStateContext } from '../context'
import styles from '../select.module.css'
import { ListItem } from './list-item'
import { ListSection } from './list-section'

interface ListProps<T extends object> extends AriaListBoxOptions<T> {
  filterValue?: string
}

export const List = <T extends object>({
  filterValue,
  ...props
}: ListProps<T>): JSX.Element => {
  const ref = useRef<HTMLUListElement>(null)
  const state = useSelectStateContext()
  const { gridProps } = useGridList(props, state, ref)
  const { listBoxProps } = useListBox(props, state, ref)

  const shouldFilterItem = (item: Node<object>): boolean => {
    return Boolean(
      filterValue &&
        item.textValue &&
        !normalizeValue(item.textValue).includes(normalizeValue(filterValue))
    )
  }

  const renderItems = () => {
    const items = [...state.collection].filter(item => !shouldFilterItem(item))

    if (items.length === 0) {
      return <div className={styles.notFound}>No matches found</div>
    }

    return items.map(item => {
      const commonProps = { item, state }
      if (item.type === 'section') {
        return <ListSection key={item.key} section={item} {...commonProps} />
      }
      return <ListItem key={item.key} {...commonProps} />
    })
  }

  return (
    <ul {...gridProps} ref={ref} className={styles.ul} role={listBoxProps.role}>
      {renderItems()}
    </ul>
  )
}
