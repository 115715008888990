import { JsonConverter, JsonCustomConvert } from 'json2typescript'

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date> {
  serialize(date: Date): string {
    return date.toISOString()
  }

  deserialize(date: string): Date {
    return new Date(date)
  }
}
