import { FlowBuilderApp, TestWebchatApp } from '@hubtype/flow-builder'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<FlowBuilderApp />} />
        <Route path='test-webchat' element={<TestWebchatApp />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
