import { LocaleCode } from '../../../../domain/models/locales/code'
import { Locale } from '../../../../domain/models/locales/locale'
import {
  ConversationalApp,
  ConversationalAppProviderAccount,
} from '../../../../domain/models/organization-models'
import { PreviewUrlParams } from '../../../types'

export const arraySortAlphabetically = <T extends { name: string }>(
  array: T[]
): T[] => {
  return [...array].sort((a, b) => b.name.localeCompare(a.name))
}

export const WEBCHAT_PREVIEW_WINDOW = {
  width: 400,
  height: 600,
  top: window.screen.availHeight / 2 - 250,
  left: window.screen.availWidth - window.screen.availWidth / 4,
}

export const WEBCHAT_PREVIEW_FEATURES = `width=${WEBCHAT_PREVIEW_WINDOW.width},height=${WEBCHAT_PREVIEW_WINDOW.height},top=${WEBCHAT_PREVIEW_WINDOW.top},left=${WEBCHAT_PREVIEW_WINDOW.left}`

export const openURLInNewTab = (url: string): void => {
  window.open(url, '_blank')
}

export const openURLInNewWindowWithDimensions = (
  url: string,
  features: string
): void => {
  window.open(url, undefined, features)
}

export const generateUrlForWhatsappIntegration = (
  integration: ConversationalAppProviderAccount
): string => {
  return `https://wa.me/${integration.phoneNumber.replace(/\D/g, '')}`
}

interface WebchatIntegrationUrls {
  previewUrl: string
  shareUrl: string
}

export const generateUrlsForWebchatIntegration = (
  currentConversationalApp: ConversationalApp,
  integration: ConversationalAppProviderAccount,
  currentLocaleCode: LocaleCode,
  locales: Locale[]
): WebchatIntegrationUrls => {
  const domainUrl = import.meta.env.VITE_FLOW_BUILDER_DOMAIN_URL
  if (!domainUrl) {
    throw new Error('You need to set env var VITE_FLOW_BUILDER_DOMAIN_URL')
  }
  if (!currentConversationalApp.id || !integration.id) {
    throw new Error('Missing information to construct preview URL')
  }
  const bundleLocation = new URL(integration.netlifyUrl).host
  const webchatUrl = new URL(domainUrl)
  webchatUrl.pathname = '/test-webchat'
  webchatUrl.searchParams.set(
    PreviewUrlParams.BOT_ID,
    currentConversationalApp.id
  )
  webchatUrl.searchParams.set(PreviewUrlParams.BUNDLE_LOCATION, bundleLocation)
  webchatUrl.searchParams.set(PreviewUrlParams.WEBCHAT_APP_ID, integration.id)
  webchatUrl.searchParams.set(PreviewUrlParams.WEBCHAT_OPEN_AUTO, 'true')
  webchatUrl.searchParams.set(
    PreviewUrlParams.CURRENT_LOCALE,
    currentLocaleCode
  )
  webchatUrl.searchParams.set(
    PreviewUrlParams.LOCALES,
    locales.map(l => l.code).join(',')
  )
  const previewUrl = webchatUrl.toString()
  webchatUrl.searchParams.set(PreviewUrlParams.IS_SHARED, 'true')
  const shareUrl = webchatUrl.toString()

  return { previewUrl, shareUrl }
}

export async function copyToClipboard(value: string) {
  return await navigator.clipboard.writeText(value)
}
