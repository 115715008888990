import { useState } from 'react'

import { Locale, NewLocale } from '../../../domain/models/locales/locale'
import { SUPPORTED_LOCALES } from '../../../domain/models/locales/supported-locales'
import { useFeedbackMessage } from '../../custom-hooks/use-feedback-message'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { PopupType } from '../../types'
import { HtModal } from '../base'
import { LocaleItem } from './locale-item'
import styles from './locale-panel.module.css'
import { LocaleSearchBar } from './search-bar'

export const LocalesPanel = (): JSX.Element => {
  const { reportSuccess } = useFeedbackMessage()
  const { setPopupContent, toggleLocalesPanel, addLocales } =
    useFlowBuilderSelector(ctx => ctx)
  const [localesToRemove, setLocalesToRemove] = useState<Locale[]>([])
  const [localesToAdd, setLocalesToAdd] = useState<NewLocale[]>([])
  const [filteredLocales, setFilteredLocales] = useState<Locale[]>(
    Object.values(SUPPORTED_LOCALES)
  )

  const updateStateLocales = (): void => {
    toggleLocalesPanel(false)
    if (localesToAdd.length > 0) {
      addLocales(localesToAdd)
      reportSuccess('Languages added')
    }
    if (localesToRemove.length > 0) {
      setPopupContent({ type: PopupType.REMOVE_LOCALES, localesToRemove })
    }
  }

  const cancel = (): void => {
    toggleLocalesPanel(false)
  }

  const getLocales = () => {
    if (filteredLocales.length === 0) {
      return <div className={styles.emptySearch}>No options</div>
    }
    return filteredLocales.map(locale => (
      <LocaleItem
        key={locale.id}
        currentLocale={locale}
        localesToAdd={localesToAdd}
        localesToRemove={localesToRemove}
        setLocalesToAdd={setLocalesToAdd}
        setLocalesToRemove={setLocalesToRemove}
      />
    ))
  }

  const isConfirmButtonDisabled = () =>
    localesToAdd.length === 0 && localesToRemove.length === 0

  return (
    <HtModal
      title='Add or remove languages'
      confirmButton={{
        text: 'Add / remove languages',
        onClick: updateStateLocales,
        disabled: isConfirmButtonDisabled(),
      }}
      discardButton={{ text: 'Cancel', onClick: cancel }}
      cancel={cancel}
      canCloseOnClickOutside={false}
    >
      <LocaleSearchBar
        locales={Object.values(SUPPORTED_LOCALES)}
        setFilteredLocales={setFilteredLocales}
      />
      <div className={styles.localesList}>{getLocales()}</div>
    </HtModal>
  )
}
