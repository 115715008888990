import { IconButton, SelectItem, Tooltip } from '@hubtype/ui-react-web'

import { TextBodySmall } from '../..'
import { StyledEditableItem } from './styles'

interface HtEditableDropdownItemProps<T extends SelectItem> {
  item: T
  canEdit?: boolean
  canRemove?: boolean
  onRemove: () => void
  onEdit?: () => void
}

export const HtEditableDropdownItem = <T extends SelectItem>({
  item,
  canEdit = false,
  canRemove = true,
  onRemove,
  onEdit,
}: HtEditableDropdownItemProps<T>) => {
  const isTooltipNeeded = Boolean(item.name && item.name.length > 100)
  return (
    <StyledEditableItem $isLongItem={isTooltipNeeded}>
      <Tooltip
        content={item.name}
        isDisabled={!isTooltipNeeded}
        delay={500}
        placement='bottom left'
      >
        <TextBodySmall>{item.name || item.id}</TextBodySmall>
      </Tooltip>
      <div>
        {canEdit && onEdit && (
          <IconButton icon='edit' size='small' onPress={onEdit} />
        )}
        {canRemove && onRemove && (
          <IconButton icon='trash-can' size='small' onPress={onRemove} />
        )}
      </div>
    </StyledEditableItem>
  )
}
