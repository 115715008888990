import { useReactFlow } from 'reactflow'

import { TrackEventName, useAnalytics } from '../../../analytics'
import { ControlButton } from '../control-button'
import styles from '../flow-controls.module.css'

export const ViewControls = () => {
  const { zoomIn, zoomOut, fitView } = useReactFlow()
  const analytics = useAnalytics()

  const onZoomIn = () => {
    analytics.trackEvent(TrackEventName.CLICK_ZOOM_BUTTONS, {
      action: 'in',
    })
    zoomIn()
  }

  const onZoomOut = () => {
    analytics.trackEvent(TrackEventName.CLICK_ZOOM_BUTTONS, {
      action: 'out',
    })
    zoomOut()
  }

  const onFitView = () => {
    analytics.trackEvent(TrackEventName.CLICK_VIEW_EVERYTHING_CTA)
    fitView({ padding: 0.2 })
  }

  return (
    <div className={styles.controls}>
      <ControlButton onPress={onZoomIn} aria-label='Zoom in' icon='plus' />
      <ControlButton onPress={onZoomOut} aria-label='Zoom out' icon='minus' />
      <ControlButton onPress={onFitView} aria-label='Fit view' icon='expand' />
    </div>
  )
}
