import { EmojiPickerTrigger, TextInput } from '@hubtype/ui-react-web'
import { TextArea } from '@hubtype/ui-react-web'
import { memo, useEffect, useRef, useState } from 'react'

import { INPUT_DEBOUNCE_DELAY } from '../../../constants'
import { useDebounce } from '../../../custom-hooks'
import { InputAction } from '../constants'
import { BotVariablesDropdown } from './bot-variables-dropdown'

interface TextFieldProps {
  id: string
  value: string
  changeProps?: {
    buttonIndex?: number
    elementIndex?: number
    sectionId?: string
    rowId?: string
    conditionValueId?: string
  }
  field: InputAction
  readOnly?: boolean
  hasVariables?: boolean
  multiline?: boolean
  hasEmojiPicker?: boolean
  isInvalid?: boolean
  isOptional?: boolean
  maxLength?: number
  defaultRows?: number
  isLabelHidden?: boolean
  errorMessage?: string
  isCounterEnabled?: boolean
  onChange: (action: any) => void
}

export const ContentTextField = memo((props: TextFieldProps): JSX.Element => {
  const [value, setValue] = useState(props.value)
  const ref = useRef<HTMLInputElement>(null)
  const debouncedValue = useDebounce<string>(value, INPUT_DEBOUNCE_DELAY)

  useEffect(() => setValue(props.value), [props.id])
  useEffect(() => setValue(props.value), [props.value])
  useEffect(() => updateContent(), [debouncedValue])

  const updateContent = () => {
    if (value === props.value) return
    const sanitizedValue = removeZeroWidthSpaces(value)
    props.onChange({
      ...props.changeProps,
      type: props.field.actionType,
      fieldKey: props.field.key,
      value: sanitizedValue,
    })
  }

  const removeZeroWidthSpaces = (inputString: string) => {
    return inputString.replace(/\u200B/g, '')
  }

  const onUseVariable = (variable: string) => {
    const textWithVariable = addVariableOnText(variable)
    setValue(textWithVariable)
    ref.current?.focus()
  }

  const addVariableOnText = (variable: string): string => {
    const cursor = ref?.current?.selectionStart || props.value.length
    const textBeforeCursor = props.value.slice(0, cursor)
    const textAfterCursor = props.value.slice(cursor)
    return `${textBeforeCursor}{${variable}}${textAfterCursor}`
  }

  const isTextTooLong = props.maxLength ? value.length > props.maxLength : false
  const errorMessage = isTextTooLong
    ? 'Text is too long'
    : props.field.errorText || props.errorMessage

  const Component = props.multiline ? TextArea : TextInput

  return (
    <Component
      {...props}
      //@ts-expect-error ref is not a valid prop for TextInput
      ref={ref}
      label={props.field.label}
      placeholder={props.field.placeholder}
      value={value}
      onChange={setValue}
      description={props.field.helperText}
      errorMessage={errorMessage}
      isInvalid={props.isInvalid || isTextTooLong}
      maxLength={props.maxLength}
      isReadOnly={props.readOnly}
      footerLeftSlot={
        props.hasVariables && (
          <BotVariablesDropdown onUseVariable={onUseVariable} />
        )
      }
      footerRightSlot={
        props.hasEmojiPicker && (
          <EmojiPickerTrigger
            inputRef={ref}
            value={value}
            onChange={setValue}
          />
        )
      }
    />
  )
})
