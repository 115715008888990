import { cva } from 'class-variance-authority'
import { forwardRef, useEffect } from 'react'
import { useObjectRef, useSearchField } from 'react-aria'
import { useSearchFieldState } from 'react-stately'

import { useDebounce, useFirstRender } from '../editable-text/hooks'
import Icon from '../icon/icon'
import IconButton from '../icon-button/icon-button'
import styles from './search-bar.module.css'

const inputClasses = cva(styles.base, {
  variants: {
    size: {
      small: styles.small,
      medium: styles.medium,
      large: styles.large,
    },
  },
})

export interface SearchBarProps {
  /** The label for the search field. */
  label: string
  /** The current value (controlled). */
  value?: string
  /** The default value (uncontrolled). */
  defaultValue?: string
  /** The size of the input */
  size?: 'small' | 'medium' | 'large'
  /** Whether the input is disabled. */
  isDisabled?: boolean
  /** Whether the element should receive focus on render. */
  autoFocus?: boolean
  /** Temporary text that occupies the text input when it is empty. */
  placeholder?: string
  /** The width of the search bar */
  width?: string
  /** Handler that is called when the SearchField value changes with debounce. */
  onChangeWithDebounce?: (value: string) => void
  /** Handler that is called when the SearchField is submitted. */
  onSubmit?: (value: string) => void
  /** Handler that is called when the clear button is pressed. */
  onClear?: () => void
}

/** A search bar lets users quickly find relevant content. It allows a user to type a word or phrase to filter through a large amount of data without navigation. */
export const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  ({ size = 'medium', ...props }, forwardedRef) => {
    const ref = useObjectRef(forwardedRef)
    const state = useSearchFieldState(props)
    const isFirstRender = useFirstRender()
    const debouncedValue = useDebounce<string>(state.value)
    const { inputProps, clearButtonProps } = useSearchField(props, state, ref)

    useEffect(() => {
      if (isFirstRender) return
      props.onChangeWithDebounce?.(debouncedValue)
    }, [debouncedValue])

    return (
      <div className={inputClasses({ size })} style={{ width: props.width }}>
        <Icon
          icon='magnifying-glass'
          size='small'
          className={styles.magnifyingGlassIcon}
        />
        <input
          {...inputProps}
          ref={ref}
          placeholder={props.placeholder || props.label}
          className={styles.input}
        />
        {state.value !== '' && (
          <IconButton
            {...clearButtonProps}
            icon='xmark'
            size='small'
            shape='rounded'
          />
        )}
      </div>
    )
  }
)

export default SearchBar
