import { TextArea } from '@hubtype/ui-react-web'
import { useEffect, useRef, useState } from 'react'

import {
  ButtonFields,
  ContentType,
  UrlFields,
} from '../../../../domain/models/content-fields'
import { HTTP_URL_REGEX } from '../../../constants'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { NodeTypes, PopupType } from '../../../types'
import { BoldSpan, TextBodySmall } from '../../base'
import { Popup } from '../popup'

interface EditUrlPopupProps {
  urlToEdit: UrlFields
}

export const EditUrlPopup = ({ urlToEdit }: EditUrlPopupProps): JSX.Element => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const { state, setPopupContent, editUrl } = useFlowBuilderSelector(ctx => ctx)
  const [urlName, setUrlName] = useState(urlToEdit.name)
  const [error, setError] = useState<string | undefined>(undefined)
  const [linkedNodes, setLinkedNodes] = useState<NodeTypes[]>([])

  useEffect(() => {
    const nodesWithUrl = state.nodes.filter(node => isNodeLinkedToUrl(node))
    setLinkedNodes(nodesWithUrl)
  }, [])

  const buttonHasUrl = (button: ButtonFields): boolean => {
    return button.hasUrl(urlToEdit.id, state.currentLocale.code)
  }

  const isNodeLinkedToUrl = (node: NodeTypes): boolean => {
    return (
      (node.type === ContentType.TEXT &&
        node.data.buttons.some(button => buttonHasUrl(button))) ||
      (node.type === ContentType.WHATSAPP_CTA_URL_BUTTON &&
        buttonHasUrl(node.data.button))
    )
  }

  const onEditUrl = (): void => {
    if (!urlName || error) return
    setPopupContent()
    editUrl(urlToEdit, urlName)
  }

  const onNameChanges = (newUrl: string) => {
    setUrlName(newUrl)
    if (
      state.nonMessageContents.urls.some(
        url => url.id !== urlToEdit.id && url.name === newUrl
      )
    ) {
      setError('URL already exists')
    } else if (newUrl && !HTTP_URL_REGEX.test(newUrl)) {
      setError('Invalid URL')
    } else {
      setError(undefined)
    }
  }

  return (
    <Popup
      title={linkedNodes.length > 0 ? 'Edit URL in use' : PopupType.EDIT_URL}
      confirmButton={{
        text: 'Save',
        onClick: onEditUrl,
        disabled: !urlName || !!error || urlName === urlToEdit.name,
      }}
    >
      {linkedNodes.length > 0 && (
        <TextBodySmall>
          This URL is being used in one or more other buttons in this
          conversational app. If it is edited, it will be also edited to all the
          other button components.
        </TextBodySmall>
      )}
      <TextArea
        ref={inputRef}
        label='New url name'
        isLabelHidden={true}
        placeholder={urlToEdit.name}
        onChange={onNameChanges}
        value={urlName}
        isInvalid={!!error}
        errorMessage={error}
      />
      {linkedNodes.length > 0 && (
        <TextBodySmall>
          Contents using it:{' '}
          <BoldSpan>
            {linkedNodes.map(node => node.data.code).join('; ')}
          </BoldSpan>
        </TextBodySmall>
      )}
    </Popup>
  )
}
