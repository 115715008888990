import { memo } from 'react'

import { CountryConditionNodeProps } from '../../types'
import { Condition, Handles, NodeHeader } from './node-components'
import { Border, ConditionalNode, StyledList } from './node-styles'

const CountryConditionNode = ({
  data,
  selected,
  id,
}: CountryConditionNodeProps) => {
  return (
    <ConditionalNode $isSelected={selected}>
      <Handles contentId={data.getContentId()} />
      <NodeHeader data={data} title={'Country'} icon='split' />
      {data.countries.length > 0 && (
        <StyledList>
          {data.countries.map((country, i) => (
            <Condition
              key={i}
              id={id + '-' + country.id}
              border={i === 0 ? Border.NONE : Border.TOP}
            >
              {country.name}
            </Condition>
          ))}
          <Condition id={id + '-default'} border={Border.TOP}>
            Other
          </Condition>
        </StyledList>
      )}
    </ConditionalNode>
  )
}

export default memo(CountryConditionNode)
