import { HtFlowBuilderData } from '../domain/models/cms/hubtype/flow-builder-data'

interface HtNode {
  id?: string
  meta?: { x: number; y: number }
}

export async function getHash(flowData: HtFlowBuilderData) {
  const nodesWithoutMeta = removeMetaFromNodes(flowData.nodes)

  const webviewContentsWithoutMeta = removeMetaFromNodes(
    flowData.webview_contents
  )

  const flowDataWithoutMeta = {
    ...flowData,
    nodes: nodesWithoutMeta,
    webview_contents: webviewContentsWithoutMeta,
  }
  const flowDataString = JSON.stringify(flowDataWithoutMeta)

  const hashHex = await generateHash(flowDataString)

  return hashHex
}

function removeMetaFromNodes<T extends HtNode>(nodes?: T[]): T[] {
  return (
    nodes?.map(node => {
      delete node.meta
      return node
    }) || []
  )
}

async function generateHash(flowDataString: string) {
  const encoder = new TextEncoder()
  const data = encoder.encode(flowDataString)
  const hashBuffer = await crypto.subtle.digest('SHA-1', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('')

  return hashHex
}
