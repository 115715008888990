import { all, IconName as FaIconName } from '@awesome.me/kit-6256b7ca80/icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cva } from 'class-variance-authority'
import { ComponentProps } from 'react'

import styles from './icon.module.css'

library.add(...all)

const iconClasses = cva(styles.base, {
  variants: {
    size: {
      tiny: styles.tiny,
      small: styles.small,
      medium: styles.medium,
      large: styles.large,
    },
  },
})

export type IconName = FaIconName
export type IconPrefix = 'fas' | 'far' | 'fab' | 'fad'

export interface IconProps {
  /** The name of the icon. */
  icon: IconName
  /** The size of the icon. */
  size?: 'tiny' | 'small' | 'medium' | 'large'
  /** The prefix of the icon. */
  prefix?: IconPrefix
  /** Additional classes to apply to the icon. */
  className?: string
  /** The rotation of the icon. */
  rotation?: ComponentProps<typeof FontAwesomeIcon>['rotation']
}

/** An icon is a graphical representation of an object. */
export function Icon({
  size,
  prefix = 'fas',
  icon,
  className,
  rotation,
}: IconProps) {
  return (
    <FontAwesomeIcon
      icon={[prefix, icon]}
      aria-label={icon}
      className={iconClasses({ size, className })}
      rotation={rotation}
    />
  )
}

export default Icon
