import { Button, Icon } from '@hubtype/ui-react-web'

import { TextSmall } from '../typography'
import { StyledAiNotificationWithActions } from './ht-notification-styles'

interface NotificationButton {
  text: string
  icon?: string
  onClick: () => void
}

export interface HtAiNotificationWithActionsProps {
  confirmButton: NotificationButton
  discardButton: NotificationButton
  isLoading?: boolean
  children: string
}

export const HtAiNotificationWithActions = ({
  confirmButton,
  discardButton,
  isLoading,
  children,
}: HtAiNotificationWithActionsProps): JSX.Element => {
  return (
    <StyledAiNotificationWithActions>
      <div>
        <TextSmall>{children}</TextSmall>
      </div>
      <div>
        <Button
          intent='secondary'
          size='small'
          isDisabled={isLoading}
          onPress={discardButton.onClick}
        >
          {discardButton.text}
        </Button>
        {/* TODO: Ai button */}
        <Button
          intent='primary'
          size='small'
          isLoading={isLoading}
          onPress={confirmButton.onClick}
        >
          {confirmButton.icon && <Icon icon={confirmButton.icon} />}
          {confirmButton.text}
        </Button>
      </div>
    </StyledAiNotificationWithActions>
  )
}
