import { memo } from 'react'

import { ButtonFields } from '../../../../../domain/models/content-fields'
import {
  BUTTON_PAYLOAD,
  BUTTON_TEXT,
  ELEMENT_BUTTON_TEXT,
  ELEMENT_BUTTON_URL,
  URL,
} from '../../constants'
import { EditorFields } from '../../editor-styles'
import { ContentTextField } from '../../editor-widgets/text-input-widgets'
import { EditorProps } from '../../node-editor-panel'
import { UrlDropdown } from './url-dropdown'

interface ButtonEditorProps extends EditorProps {
  button: ButtonFields
  buttonIndex: number
  elementIndex?: number
  isReply: boolean
  showButtonInfo: boolean
}

export const ButtonEditor = memo(
  ({
    button,
    buttonIndex,
    elementIndex,
    isReply,
    showButtonInfo,
    ...props
  }: ButtonEditorProps): JSX.Element => {
    const inCarouselContent = elementIndex !== undefined

    // TODO. remove when we stop supporting old payloads
    const onRemovePayload = () => {
      const change = {
        type: BUTTON_PAYLOAD.actionType,
        fieldKey: BUTTON_PAYLOAD.key,
        buttonIndex,
        value: undefined,
      }
      //@ts-expect-error due to Action Type
      props.onChange(change)
    }

    if (!showButtonInfo) return <></>
    return (
      <EditorFields
        aria-label={`Button ${buttonIndex + 1}`}
        $inCarouselContent={inCarouselContent}
      >
        <ContentTextField
          {...props}
          id={button.id}
          field={inCarouselContent ? ELEMENT_BUTTON_TEXT : BUTTON_TEXT}
          changeProps={{ buttonIndex, elementIndex }}
          value={button.text}
          hasEmojiPicker={true}
          isCounterEnabled={true}
          multiline={true}
          defaultRows={1}
        />
        {!isReply && (
          <UrlDropdown
            {...props}
            field={inCarouselContent ? ELEMENT_BUTTON_URL : URL}
            selectedUrl={button.url}
            changeProps={{ buttonIndex, elementIndex }}
          />
        )}
      </EditorFields>
    )
  }
)
