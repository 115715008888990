import { memo } from 'react'

import { ConnectionLostPopup } from '../../one-user/modals/connection-lost'
import { SessionConflictPopup } from '../../one-user/modals/session-conflict'
import { SessionEndedPopup } from '../../one-user/modals/session-ended'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { PopupContent, PopupType } from '../../types'
import { HtModal, ModalProps } from '../base'
import { IntentModelsPopup } from '../header/ai-settings/intent-models/intent-models-modal'
import { KnowledgeBasePopup } from '../header/ai-settings/knowledge-base/knowledge-base-modal'
import { PublishPopup } from '../header/publish/publish-modal'
import { TransferFlowsModal } from '../header/transfer-flows/transfer-flows-modal/transfer-flows-modal'
import { DeleteLocalesPopup } from '../locale-panel/delete-locale-modal'
import { DeletePayloadPopup } from './popups/delete-payload-modal'
import { DeleteUrlPopup } from './popups/delete-url-modal'
import { EditUrlPopup } from './popups/edit-url'
import { AddFlowPopup } from './popups/flows/add-flow'
import { DeleteFlowPopup } from './popups/flows/delete-flow'
import { EditFlowPopup } from './popups/flows/rename-flow'
import { RestoreVersionPopup } from './popups/restore-version-modal'

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
export type PopupProps = Optional<ModalProps, 'cancel'>

export const Popup = memo((props: PopupProps): JSX.Element => {
  const setPopupContent = useFlowBuilderSelector(ctx => ctx.setPopupContent)

  const onCancel = (): void => {
    props.cancel?.()
    setPopupContent()
  }

  return <HtModal {...props} cancel={onCancel} />
})

export const renderPopup = (content: PopupContent): JSX.Element => {
  switch (content.type) {
    case PopupType.INTENT_MODELS:
      return <IntentModelsPopup />
    case PopupType.KNOWLEDGE_BASE:
      return <KnowledgeBasePopup />
    case PopupType.PUBLISH:
      return <PublishPopup />
    case PopupType.REMOVE_LOCALES:
      return <DeleteLocalesPopup {...content} />
    case PopupType.RESTORE_VERSION:
      return <RestoreVersionPopup />
    case PopupType.ADD_FLOW:
      return <AddFlowPopup />
    case PopupType.EDIT_FLOW:
      return <EditFlowPopup {...content} />
    case PopupType.DELETE_FLOW:
      return <DeleteFlowPopup {...content} />
    case PopupType.DELETE_PAYLOAD:
      return <DeletePayloadPopup {...content} />
    case PopupType.DELETE_URL:
      return <DeleteUrlPopup {...content} />
    case PopupType.EDIT_URL:
      return <EditUrlPopup {...content} />
    case PopupType.SESSION_CONFLICT:
      return <SessionConflictPopup {...content} />
    case PopupType.SESSION_ENDED:
      return <SessionEndedPopup {...content} />
    case PopupType.CONNECTION_LOST:
      return <ConnectionLostPopup />
    case PopupType.TRANSFER_FLOWS:
      return <TransferFlowsModal />
    default:
      throw new Error(`Not implemented popupType`)
  }
}
