import { createContext, useContext } from 'react'
import { TabListState } from 'react-stately'

export const TabListStateContext = createContext<TabListState<object> | null>(
  null
)

export const useTabListStateContext = () => {
  const result = useContext(TabListStateContext)
  if (!result) {
    throw new Error('TabListStateContext used outside of its Provider')
  }
  return result
}
