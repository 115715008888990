import { ToastOptions, ToastState } from '@react-stately/toast'
import { createContext, useContext } from 'react'

import { ToastContentProps } from './toast'

export interface FeedbackStateContextProps
  extends ToastState<ToastContentProps> {
  addSuccess: (
    title: string,
    description?: string,
    options?: ToastOptions
  ) => void
  addError: (
    title: string,
    description?: string,
    options?: ToastOptions
  ) => void
}

export const FeedbackStateContext =
  createContext<FeedbackStateContextProps | null>(null)

export const useToastFeedback = () => {
  const result = useContext(FeedbackStateContext)
  if (!result) {
    throw new Error('FeedbackStateContext used outside of its Provider')
  }
  return result
}
