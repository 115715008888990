import { ReactFlowProvider } from 'reactflow'

import { useRepository } from '../repository/repository-utils'
import { SaveOrigin } from './analytics'
import { FeedbackPanel } from './components/feedback-panel/feedback-panel'
import { Header } from './components/header/header'
import { useSave } from './components/header/save/use-save'
import { VersionHistoryData } from './components/header/version-history-dropdown/version-history-dropdown'
import { LocalesPanel } from './components/locale-panel/locales-panel'
import { NodeEditorPanel } from './components/node-editor-panel/node-editor-panel'
import { renderPopup } from './components/popup/popup'
import { VersionBanner } from './components/version-banner/version-banner'
import { DRAFT_VERSION } from './constants'
import { useLoadFlow } from './custom-hooks/use-load-flow'
import Flow from './flow'
import { FlowContainer } from './flow-styles'
import { useOneUser } from './one-user/use-one-user'
import { useFlowBuilderSelector } from './reducer/hooks'
import { LoadingMessage } from './types'
import { useFlowBuilderFeedback } from './use-flow-builder-feedback'

const FlowBuilder = (): JSX.Element => {
  const { reportError } = useFlowBuilderFeedback()
  const {
    state,
    removeFeedbackMessages,
    restoreChangeHistory,
    setLoadingMessage,
    toggleFlowSaved,
    toggleInteractivity,
    setCurrentVersion,
  } = useFlowBuilderSelector(ctx => ctx)

  const { authToken, flowBuilderUser } = state

  const { loadFlow } = useLoadFlow()
  const repository = useRepository()
  const { saveFlow } = useSave()
  const { isFlowBuilderVisible } = useOneUser(flowBuilderUser!)

  const uploadFile = async (file: File) => {
    const asset = await repository.cmsWriter.uploadFile(
      state.authToken,
      file,
      state.flowBuilderUser?.botId || ''
    )
    if (!asset) {
      reportError('Error uploading image')
    }
    return asset
  }

  const loadPublishedVersion = async (
    version: VersionHistoryData
  ): Promise<void> => {
    setLoadingMessage(LoadingMessage.LOADING_VERSION)
    if (!state.isFlowSaved) {
      await saveFlow(SaveOrigin.ON_OPEN_PREVIOUS_VERSION)
    }
    restoreChangeHistory()
    removeFeedbackMessages()
    await loadFlow(state.currentLocale, version.id, state.organizationContents)
    setLoadingMessage(undefined)
    toggleInteractivity(false)
    toggleFlowSaved(true)
    setCurrentVersion(version)
  }

  const restoreDraftFlow = async (): Promise<void> => {
    setCurrentVersion(undefined)
    toggleInteractivity(true)
    setLoadingMessage(LoadingMessage.LOADING_CONTENT)
    await loadFlow(
      state.currentLocale,
      DRAFT_VERSION,
      state.organizationContents
    )
  }

  return (
    <>
      {isFlowBuilderVisible && (
        <ReactFlowProvider>
          <VersionBanner restoreDraftFlow={restoreDraftFlow} />
          {!state.isReadOnly && !state.loadingMessage && <FeedbackPanel />}
          {state.nodes.length > 0 && (
            <FlowContainer id='flow-container'>
              <Header loadPublishedVersion={loadPublishedVersion} />
              {state.isLocalesPanelOpen && <LocalesPanel />}
              <Flow />
              {state.currentNode && <NodeEditorPanel uploadFile={uploadFile} />}
            </FlowContainer>
          )}
        </ReactFlowProvider>
      )}
      {state.popupContent && renderPopup(state.popupContent)}
    </>
  )
}
export default FlowBuilder
