import { Button, TextInput, TextInputProps } from '@hubtype/ui-react-web'
import { useState } from 'react'
import styled from 'styled-components'

export const StyledInputWithActionForm = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
`

interface HtInputWithActionProps {
  buttonText: string
  errorMessage?: string
  inputProps: Omit<TextInputProps, 'value'>
  applyAction: (value: string) => void
  isValueValid: (value: string) => boolean
  onValueChange?: (value: string) => void
}

export const HtInputWithAction = ({
  buttonText,
  errorMessage,
  inputProps,
  applyAction,
  isValueValid,
  onValueChange,
}: HtInputWithActionProps) => {
  const [value, setValue] = useState('')
  const [hasError, setHasError] = useState(false)

  const onInputChange = (newValue: string) => {
    setValue(newValue)
    setHasError(!isValueValid(newValue))
    onValueChange?.(newValue)
  }

  const onButtonClick = () => {
    applyAction(value)
    setValue('')
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onButtonClick()
  }

  return (
    <StyledInputWithActionForm onSubmit={handleSubmit}>
      <TextInput
        {...inputProps}
        isLabelHidden={true}
        size='small'
        value={value}
        isInvalid={hasError}
        errorMessage={errorMessage}
        description={inputProps.description}
        onChange={onInputChange}
      />
      <Button
        size='small'
        isDisabled={hasError || !value}
        onPress={onButtonClick}
        type='submit'
      >
        {buttonText}
      </Button>
    </StyledInputWithActionForm>
  )
}
