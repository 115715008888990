import { Node } from '@react-types/shared'
import { useRef } from 'react'
import { useGridListItem } from 'react-aria'

import { useSelectStateContext } from '../context'
import styles from '../select.module.css'

interface ListItemProps<T extends object> {
  item: Node<T>
}

export const ListItem = <T extends object>({
  item,
}: ListItemProps<T>): JSX.Element => {
  const ref = useRef<HTMLLIElement>(null)
  const state = useSelectStateContext()
  const { rowProps } = useGridListItem({ node: item }, state, ref)

  return (
    //eslint-disable-next-line jsx-a11y/role-has-required-aria-props
    <li className={styles.option} {...rowProps} ref={ref} role='option'>
      <span>{item.rendered}</span>
    </li>
  )
}
