import { useMachine } from '@xstate/react'
import { useForm } from 'react-hook-form'
import { fromPromise } from 'xstate'

import { ConversationalApp } from '../../../../../domain/models/organization-models'
import { HubtypeService } from '../../../../../repository/hubtype/hubtype-service'
import { TrackEventName, useAnalytics } from '../../../../analytics'
import { DRAFT_VERSION, LATEST_VERSION } from '../../../../constants'
import { postCloseFromFlowBuilderMessage } from '../../../../hubtype-events'
import {
  PRESENCE_FLOW_BUILDER_CHANNEL_NAME,
  useRealtimeContext,
} from '../../../../realtime'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { LoadingMessage } from '../../../../types'
import { SelectBotStep, TransferBot } from './steps/select-bot-step'
import { SummaryStep } from './steps/summary-step'
import { TransferFlowsMachine } from './transfer-flows-machine'
import { useMachineFeedback } from './use-machine-feedback'

export interface TransferDetails {
  targetBot?: ConversationalApp
  openTargetBotOnTransfer?: boolean
}

export const TransferFlowsModal = () => {
  const analytics = useAnalytics()
  const realtimeClient = useRealtimeContext()
  const { state, setPopupContent, setLoadingMessage } = useFlowBuilderSelector(
    ctx => ctx
  )

  const form = useForm<TransferDetails>({
    mode: 'onChange',
    defaultValues: {
      openTargetBotOnTransfer: true,
    },
  })

  const [machineState, send, machine] = useMachine(
    TransferFlowsMachine.provide({
      actors: {
        transferFlows: fromPromise(async () => await transferFlows()),
        getBots: fromPromise(async () => await getBots()),
        closeModal: fromPromise(() => closeModal()),
        openTargetBot: fromPromise(() => openTargetBot()),
      },
    })
  )

  useMachineFeedback(machine)

  const getBots = async (): Promise<TransferBot[]> => {
    const bots =
      state.organizationContents.conversationalApps.filter(hasFlowBuilder)
    const promises = bots.map(async bot => {
      return {
        ...bot,
        isBeingEdited: await isBotBeingEdited(bot),
        hasUnpublishedChanges: await hasUnpublishedChanges(bot),
      }
    })
    const cApps = await Promise.all(promises)
    analytics.trackEvent(TrackEventName.TRANSFER_FLOW_LIST_STATE, {
      any_bot_being_edited: cApps.some(cApp => cApp.isBeingEdited),
      any_bot_unpublished: cApps.some(cApp => cApp.hasUnpublishedChanges),
    })
    return cApps
  }

  const hasFlowBuilder = (bot: ConversationalApp) => {
    return (
      bot.id !== state.organizationContents.currentConversationalApp.id &&
      bot.flowBuilderSettings.cmsType === 'flow-builder-backend'
    )
  }

  const isBotBeingEdited = async (bot: ConversationalApp) => {
    const channelName = `${PRESENCE_FLOW_BUILDER_CHANNEL_NAME}-${state.flowBuilderUser?.organizationId}-${bot.id}`
    const channel = realtimeClient.channels.get(channelName)
    const members = await channel.presence.get()
    return members.length > 0
  }

  const hasUnpublishedChanges = async (bot: ConversationalApp) => {
    const [draft, published] = await Promise.all([
      HubtypeService.getFlow(state.authToken, bot.id, DRAFT_VERSION),
      HubtypeService.getFlow(state.authToken, bot.id, LATEST_VERSION),
    ])
    if (!draft) return false
    if (!published) return true
    return draft.hash !== published.hash
  }

  const transferFlows = async (): Promise<void> => {
    const { targetBot } = form.getValues()
    if (!targetBot || !state.flowBuilderUser) return
    setLoadingMessage(LoadingMessage.TRANSFER_FLOWS)
    const isBeingEdited = await isBotBeingEdited(targetBot)
    if (isBeingEdited) {
      return Promise.reject({
        analytics: {
          code: 'transfer_failed',
          details: 'bot_being_edited',
        },
        message:
          'Failed to transfer flows. Destination bot is currently being edited.',
      })
    }
    return await HubtypeService.transferFlows(
      state.authToken,
      state.flowBuilderUser.botId,
      targetBot.id
    )
  }

  const closeModal = async () => {
    setLoadingMessage(undefined)
    setPopupContent()
  }

  const openTargetBot = async () => {
    postCloseFromFlowBuilderMessage(
      `/bots/${machineState.context.transferDetails?.targetBot?.id}/flowbuilder`
    )
  }

  if (machineState.hasTag('step1'))
    return (
      <SelectBotStep
        form={form}
        bots={machineState.context.bots}
        isLoading={machineState.hasTag('loading')}
        cancel={() => send({ type: 'CANCEL' })}
        goNext={() => send({ type: 'NEXT' })}
      />
    )

  if (machineState.hasTag('step2')) {
    return (
      <SummaryStep
        form={form}
        goBack={() => send({ type: 'BACK' })}
        transfer={() =>
          send({ type: 'TRANSFER', transferDetails: form.getValues() })
        }
      />
    )
  }

  return null
}
