import { memo } from 'react'

import { BotActionFields } from '../../../../../domain/models/content-fields'
import { isValidJSON } from '../../../../../utils/string-utils'
import { HtInfoPanel, TextBodyBold } from '../../../base'
import { PAYLOAD, PAYLOAD_PARAMS } from '../../constants'
import { ContentTextField } from '../../editor-widgets/text-input-widgets'
import { UniqueContentField } from '../../editor-widgets/unique-content-id-widget'
import { EditorProps } from '../../node-editor-panel'
import { PayloadDropdown } from './payload-dropdown'

interface BotActionEditorProps extends EditorProps {
  nodeContent: BotActionFields
}

export const BotActionEditor = memo(
  ({ nodeContent, ...props }: BotActionEditorProps): JSX.Element => {
    const hasPayloadParamsError = (): boolean => {
      if (nodeContent.payloadParams === '') return false
      return !isValidJSON(nodeContent.payloadParams)
    }

    return (
      <>
        <TextBodyBold>Bot action</TextBodyBold>
        <UniqueContentField
          {...props}
          id={nodeContent.id}
          value={nodeContent.code}
          isAiGenerated={nodeContent.isCodeAiGenerated}
        />
        <PayloadDropdown
          {...props}
          field={PAYLOAD}
          payloadId={nodeContent.payloadId}
        />
        <ContentTextField
          {...props}
          id={nodeContent.id}
          field={PAYLOAD_PARAMS}
          value={nodeContent.payloadParams}
          multiline={true}
          isInvalid={hasPayloadParamsError()}
        />
        <HtInfoPanel
          title='About bot action'
          text={
            "Bot action is used to trigger specific actions that can't be build in Flow builder but they are implemented in the bot's code."
          }
        ></HtInfoPanel>
      </>
    )
  }
)
