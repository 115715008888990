import { Realtime } from 'ably'
import { createContext, useContext } from 'react'

export const RealtimeContext = createContext<Realtime | undefined>(undefined)

export const useRealtimeContext = () => {
  const context = useContext(RealtimeContext)
  if (!context) {
    throw new Error('RealtimeContext must be used within an RealtimeProvider')
  }
  return context
}
