import '@hubtype/base-css'
import './flow-builder-tokens.css'

import * as Sentry from '@sentry/react'
import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom/client'

import App from './app/app'
import buildVersion from './assets/build-info.json'

const enableSentry = import.meta.env.VITE_ENABLE_SENTRY
if (enableSentry) {
  Sentry.init({
    // the ENVIRONMENT_NAME must match with values in deploy.py
    environment: import.meta.env.VITE_ENVIRONMENT_NAME,
    release: `${import.meta.env.VITE_ENVIRONMENT_NAME}-${buildVersion.version}`,
    dsn: 'https://5e35cf5f4cfefe6d01e45e7ce6fe9172@o368614.ingest.sentry.io/4506586942734336',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'hubtype.com'],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
} else {
  console.log('Sentry is disabled by configuration')
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
