import { HtContentType } from '../cms/hubtype/node-types'
import { HtPayloadNode } from '../cms/hubtype/payload'
import { ContentFieldsBase, ContentType, NonMessageContentType } from '.'

export class PayloadFields extends ContentFieldsBase {
  constructor(public name = '') {
    super()
  }

  contentType(): NonMessageContentType {
    return ContentType.PAYLOAD
  }

  static fromHubtypeCMS(component: HtPayloadNode): PayloadFields {
    const newPayload = new PayloadFields()
    newPayload.id = component.id
    newPayload.name = component.content.payload
    return newPayload
  }

  toHubtypeCMS(): HtPayloadNode {
    const node: HtPayloadNode = {
      id: this.id,
      type: HtContentType.PAYLOAD,
      content: {
        payload: this.name,
      },
    }
    return node
  }

  static getCopy(content: PayloadFields): PayloadFields {
    const newPayload = new PayloadFields()
    Object.assign(newPayload, content)
    return newPayload
  }
}
