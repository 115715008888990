/* eslint-disable @typescript-eslint/naming-convention */
import { FeedbackType } from '../../domain/feedback'

export class FlowBuilderApi {
  static get(authToken: string, endpoint: string, body?: BodyInit) {
    const method = 'GET'
    return this.request(authToken, method, endpoint, body)
  }

  static post(authToken: string, endpoint: string, body?: BodyInit) {
    const method = 'POST'
    return this.request(authToken, method, endpoint, body)
  }

  static put(authToken: string, endpoint: string, body?: BodyInit) {
    const method = 'PUT'
    return this.request(authToken, method, endpoint, body)
  }

  static postFile(
    authToken: string,
    endpoint: string,
    body: BodyInit,
    contentType: string
  ) {
    const method = 'POST'
    return this.request(authToken, method, endpoint, body, contentType)
  }

  static async request(
    authToken: string,
    method: string,
    endpoint: string,
    body?: BodyInit,
    contentType?: any
  ) {
    const flowBuilderApiUrl = FlowBuilderApi.getFlowBuilderApiUrl()
    const url = `${flowBuilderApiUrl}/${endpoint}`
    try {
      const headers: any = {
        authorization: `Bearer ${authToken}`,
      }
      if (!contentType) {
        headers['content-type'] = 'application/json;charset=UTF-8'
      }
      const response = await window.fetch(url, {
        method,
        mode: 'cors',
        headers,
        body,
      })
      if (response.ok) {
        return await response.json()
      }
    } catch (error: any) {
      console.log('error', error)
      if (
        error.message.includes('Failed to fetch') ||
        error.message.includes('NetworkError')
      ) {
        return { error: FeedbackType.ERROR }
      }
    }
    return { error: FeedbackType.ERROR }
  }

  static getFlowBuilderApiUrl(): string {
    if (!import.meta.env.VITE_FLOW_BUILDER_API_URL) {
      throw new Error('You need to set env var VITE_FLOW_BUILDER_API_URL')
    }
    return import.meta.env.VITE_FLOW_BUILDER_API_URL
  }
}
