import { Button, Icon } from '@hubtype/ui-react-web'
import { memo, useEffect, useState } from 'react'

import { TrackEventName, useAnalytics } from '../../analytics'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { GoToFlowNodeProps } from '../../types'
import { ViewportAnimator } from '../../utils/viewport-animator'
import { TextBodySmall, TextBodySmallBold } from '../base'
import { Handles, NodeHeaderCode } from './node-components'
import { RoundNode, StyledGoToFlowTitle } from './node-styles'

const GoToFlowNode = ({ data, selected }: GoToFlowNodeProps) => {
  const analytics = useAnalytics()
  const state = useFlowBuilderSelector(ctx => ctx.state)
  const selectFlow = useFlowBuilderSelector(ctx => ctx.selectFlow)
  const [flowName, setFlowName] = useState<string | undefined>(undefined)

  useEffect(() => {
    setFlowName(state.flows.find(flow => flow.id === data.targetFlowId)?.name)
  }, [state.flows, data.targetFlowId])

  const onSelectFlow = (flowId: string) => {
    if (!flowName) return
    analytics.trackEvent(TrackEventName.FLOW_OPEN, {
      flow_name: flowName,
      origin: 'node',
    })
    selectFlow(flowId)
    ViewportAnimator.centerStartNode(state)
  }

  return (
    <RoundNode $isSelected={selected}>
      <NodeHeaderCode hideCode={true} data={data} />
      <Handles hasFollowUp={false} contentId={data.getContentId()} />
      <Icon icon='arrow-progress' size='large' />
      <StyledGoToFlowTitle>
        <TextBodySmallBold>Go to flow</TextBodySmallBold>
        {flowName && <TextBodySmall>{flowName}</TextBodySmall>}
      </StyledGoToFlowTitle>
      {data.targetFlowId && (
        <Button
          size='small'
          appearance='outline'
          intent='secondary'
          onPress={() => {
            if (data.targetFlowId) onSelectFlow(data.targetFlowId)
          }}
        >
          Open
        </Button>
      )}
    </RoundNode>
  )
}

export default memo(GoToFlowNode)
