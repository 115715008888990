import { FeedbackType } from '../../domain/feedback'
import { CMSEnvironment } from '../../domain/models/cms'
import { getNextVersion } from '../../utils/version-utils'
import { FlowBuilderService } from '../hubtype/flow-builder-service'

export class HubtypeEnvironment implements CMSEnvironment {
  async publishFlow(authToken: string, botId: string): Promise<FeedbackType> {
    const versionHistory = await FlowBuilderService.getVersionHistory(
      authToken,
      botId
    )
    if (!Array.isArray(versionHistory)) return versionHistory.error
    const newVersionId = getNextVersion(versionHistory.map(v => v.id))
    return await FlowBuilderService.publishFlow(authToken, newVersionId, botId)
  }
}
