import { Icon, IconButton } from '@hubtype/ui-react-web'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import { ContentType } from '../../../../../domain/models/content-fields'
import { ARIA_LABEL } from '../../../../roles'
import { SELECT_SUB_CONTENT } from '../../constants'
import {
  SortableItem,
  StyledDraggableArea,
  StyledSubContentHeader,
} from '../../editor-styles'

export interface ItemWithId {
  id: string
}

export interface DroppableWidgetProps<T extends ItemWithId> {
  contentType: ContentType
  droppableId: string
  sortableContents: T[]
  selectedContentId?: string
  readOnly?: boolean
  isSubSection?: boolean
  isRemovable: boolean
  isSelectable?: boolean
  isDragDisabled?: boolean
  header: (item: T, index: number) => React.ReactNode
  children?: (item: T, index: number) => React.ReactNode
  onChange: (action: any) => void
  onRemove: (item: T) => void
}

export const DroppableWidget = <T extends ItemWithId>(
  props: DroppableWidgetProps<T>
): JSX.Element => {
  const isSelected = (id: string) => id === props.selectedContentId

  const selectSubContent = (evt: React.MouseEvent, item: T) => {
    const value = isSelected(item.id) ? undefined : item.id
    evt.stopPropagation()
    props.onChange({
      type: SELECT_SUB_CONTENT.actionType,
      fieldKey: SELECT_SUB_CONTENT.key,
      value,
    })
  }

  return (
    <Droppable droppableId={props.droppableId} type={props.contentType}>
      {provided => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          //need a min height to allow dropping elements when section is empty
          style={{ width: '100%', minHeight: '0.1px' }}
        >
          {props.sortableContents.map((item, index) => (
            <Draggable
              key={item.id}
              draggableId={item.id}
              index={index}
              isDragDisabled={props.readOnly || props.isDragDisabled}
            >
              {(provided, snapshot) => (
                <SortableItem
                  aria-label={`${ARIA_LABEL.SORTABLE_CONTENT}-${item.id}`}
                  $isSelected={isSelected(item.id) || !props.isSelectable}
                  $isDragging={snapshot.isDragging}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                >
                  <StyledDraggableArea
                    $isSelected={isSelected(item.id) || !props.isSelectable}
                    $isSubSection={props.isSubSection}
                  >
                    <StyledSubContentHeader
                      {...provided.dragHandleProps}
                      $isSelectable={props.isSelectable}
                      onClick={evt => selectSubContent(evt, item)}
                    >
                      {!props.isDragDisabled && (
                        <Icon icon='grip-dots' size='medium' />
                      )}
                      {props.header(item, index)}
                      {props.isRemovable && !props.readOnly && (
                        <IconButton
                          icon={props.isSelectable ? 'trash-can' : 'xmark'}
                          onPress={() => props.onRemove(item)}
                        />
                      )}
                    </StyledSubContentHeader>
                  </StyledDraggableArea>
                  {props.children?.(item, index)}
                </SortableItem>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}
