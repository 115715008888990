import { useEffect, useRef, useState } from 'react'
import { HiddenSelect, Key, useSelect } from 'react-aria'
import { useSelectState } from 'react-stately'

import Field, { FieldProps } from '../../field/field'
import { Popover, PopoverProps } from '../../popover/popover'
import { SelectStateContext } from '../context'
import { SelectItem } from '../select'
import {
  DropdownButton,
  DropdownButtonProps,
} from './dropdown-button/dropdown-button'
import { List } from './list'

export interface SelectDropdownProps<T extends object>
  extends Omit<
    FieldProps,
    | 'children'
    | 'labelProps'
    | 'errorMessageProps'
    | 'descriptionProps'
    | 'ref'
    | 'maxLength'
    | 'fieldRef'
  > {
  /** Item objects in the collection. */
  items: T[]
  /** This is the footer */
  footer?: React.ReactNode
  /** This is the header */
  header?: React.ReactNode
  /** The item keys that are disabled. These items cannot be selected, focused, or otherwise interacted with. */
  disabledKeys?: string[]
  /** The currently selected key in the collection (controlled). */
  selectedKey?: Key
  /** The initial selected key in the collection (uncontrolled). */
  defaultSelectedKey?: Key
  /** The props for the popover. */
  popoverProps?: Partial<PopoverProps>
  /** The props for the button. */
  buttonProps?: Partial<DropdownButtonProps>
  /** Whether the dropdown is loading. */
  isLoading?: boolean
  /** The value of the filter input. */
  filterValue?: string
  /** Whether to render the button as an item. */
  renderButtonAsItem?: boolean
  /** Whether the dropdown is open. */
  isOpen?: boolean
  /** Whether the button can be cleared. */
  isClearable?: boolean
  /** Handler that is called when the selection changes. */
  onSelectionChange?: (key?: Key) => void
  /**  A function that returns the element to render for each item in the collection. */
  children?: (item: T) => JSX.Element
  /** Handler that is called when the dropdown is opened or closed. */
  onOpenChange?: (isOpen: boolean) => void
}

export const SelectDropdown = <T extends SelectItem>(
  props: SelectDropdownProps<T>
): JSX.Element => {
  const state = useSelectState(props)
  const ref = useRef<HTMLButtonElement>(null)
  const { triggerProps, menuProps, ...fieldProps } = useSelect(
    { ...props, isDisabled: props.isDisabled || props.isReadOnly },
    state,
    ref
  )

  const [buttonWidth, setButtonWidth] = useState<string>()
  useEffect(() => {
    if (ref.current) {
      setButtonWidth(ref.current.offsetWidth + 'px')
    }
  }, [ref])

  return (
    <SelectStateContext.Provider value={state}>
      <Field {...props} {...fieldProps}>
        <HiddenSelect state={state} triggerRef={ref} label={props.label} />
        <DropdownButton
          {...triggerProps}
          buttonRef={ref}
          isOpen={state.isOpen}
          isEmpty={!state.selectedItem}
          isReadOnly={props.isReadOnly}
          isInvalid={props.isInvalid}
          isDisabled={props.isDisabled}
          isLoading={props.isLoading}
          isClearable={props.isClearable && !!state.selectedItem}
          onClear={() => state.setSelectedKey(null)}
          {...props.buttonProps}
        >
          {(props.renderButtonAsItem
            ? state.selectedItem?.props.children
            : state.selectedItem?.textValue) ||
            props.placeholder ||
            props.label}
        </DropdownButton>
        {state.isOpen && (
          <Popover
            state={state}
            triggerRef={ref}
            width={buttonWidth}
            offset={4}
            {...props.popoverProps}
          >
            {props.header}
            <List {...menuProps} filterValue={props.filterValue} />
            {props.footer}
          </Popover>
        )}
      </Field>
    </SelectStateContext.Provider>
  )
}
