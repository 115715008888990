import { memo } from 'react'

import { ButtonStyle } from '../../../domain/models/content-fields'
import { isWebview } from '../../../webviews/utils'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { TextNodeProps } from '../../types'
import { MarkdownText } from './markdown/markdown'
import { Button, Handles, NodeHeader } from './node-components'
import { MessageNode, StyledButtonList } from './node-styles'

const TextNode = ({ data, selected }: TextNodeProps): JSX.Element => {
  const updateNode = useFlowBuilderSelector(ctx => ctx.updateNode)
  const selectButton = (id: string) => {
    data.selectedSubContentId = id
    updateNode(data)
  }
  return (
    <MessageNode $isSelected={selected}>
      {!isWebview(data.flowId) && (
        <Handles hasFollowUp={true} contentId={data.getContentId()} />
      )}
      <NodeHeader data={data} title={'Text'} icon='text' />
      {data.text && (
        <MarkdownText isSelected={selected}>{data.text}</MarkdownText>
      )}
      {data.buttons.length !== 0 && (
        <StyledButtonList>
          {data.buttons.map((b, i) => (
            <Button
              onClick={() => selectButton(b.id)}
              key={i}
              button={b}
              isReply={data.buttonsStyle === ButtonStyle.QUICK_REPLY}
            />
          ))}
        </StyledButtonList>
      )}
    </MessageNode>
  )
}

export default memo(TextNode)
