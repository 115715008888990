import { IconButton } from '@hubtype/ui-react-web'
import { RefObject, useEffect, useRef } from 'react'
import {
  AriaSliderProps,
  mergeProps,
  useFocusRing,
  useNumberFormatter,
  useSlider,
  useSliderThumb,
  VisuallyHidden,
} from 'react-aria'
import { SliderState, useSliderState } from 'react-stately'

import { INPUT_DEBOUNCE_DELAY } from '../../../constants'
import { useDebounce } from '../../../custom-hooks'
import { HelperText } from '../base-styles'
import { TextBodySmall, TextSmallBold } from '../typography'
import {
  IconsContainer,
  InputContainer,
  StyledSlider,
  StyledThumb,
  StyledTrack,
  StyledTrackContainer,
} from './ht-slider-styles'

interface SliderProps extends AriaSliderProps {
  label: string
  helperText?: string
  formatOptions?: Intl.NumberFormatOptions
  defaultValue: number
  onUpdate: (value: number) => void
}
export const HtSlider = (props: SliderProps): JSX.Element => {
  const trackRef = useRef<HTMLDivElement>(null)
  const numberFormatter = useNumberFormatter(props.formatOptions)
  const state = useSliderState({ ...props, numberFormatter })
  const { groupProps, trackProps } = useSlider(props, state, trackRef)
  const debouncedValue = useDebounce<number>(
    state.getThumbValue(0),
    INPUT_DEBOUNCE_DELAY
  )

  useEffect(() => props.onUpdate(state.getThumbValue(0)), [debouncedValue])

  useEffect(() => {
    if (props.defaultValue !== state.getThumbValue(0)) {
      state.setThumbValue(0, props.defaultValue)
    }
  }, [props.defaultValue])

  return (
    <StyledSlider {...groupProps}>
      {props.label && <TextSmallBold>{props.label}</TextSmallBold>}
      <StyledTrackContainer>
        <StyledTrack
          {...trackProps}
          ref={trackRef}
          $size={state.getThumbValue(0)}
          $isDisabled={state.isDisabled}
        >
          <Thumb index={0} state={state} trackRef={trackRef} />
        </StyledTrack>
        <SliderInput state={state} label={props.label} />
      </StyledTrackContainer>
      {props.helperText && <HelperText>{props.helperText}</HelperText>}
    </StyledSlider>
  )
}

interface ThumbProps {
  state: SliderState
  index: number
  trackRef: RefObject<HTMLDivElement>
}

const Thumb = ({ state, trackRef, index }: ThumbProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { focusProps } = useFocusRing()
  const { thumbProps, inputProps } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef,
    },
    state
  )
  return (
    <StyledThumb {...thumbProps} $isDisabled={state.isDisabled}>
      <VisuallyHidden>
        <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
      </VisuallyHidden>
    </StyledThumb>
  )
}

interface SliderInputProps {
  state: SliderState
  label: string
}

const SliderInput = ({ state, label }: SliderInputProps) => {
  const onInputChange = (value: string): void => {
    if (new RegExp('^[0-9]*$').test(value)) {
      state.setThumbValue(0, Number(value))
    }
  }

  const getCaretButtonState = (exception: number): boolean => {
    return state.getThumbValue(0) === exception || state.isDisabled
  }

  return (
    <InputContainer $isDisabled={state.isDisabled}>
      <input
        aria-label={label}
        disabled={state.isDisabled}
        value={state.getThumbValue(0)}
        onChange={({ target }) => onInputChange(target.value)}
      />
      <TextBodySmall>%</TextBodySmall>
      <IconsContainer>
        <IconButton
          icon='caret-up'
          size='small'
          onPress={() => {
            state.incrementThumb(0, 1)
            state.setFocusedThumb(0)
          }}
          isDisabled={getCaretButtonState(100)}
        />
        <IconButton
          icon='caret-down'
          size='small'
          onPress={() => {
            state.decrementThumb(0, 1)
            state.setFocusedThumb(0)
          }}
          isDisabled={getCaretButtonState(0)}
        />
      </IconsContainer>
    </InputContainer>
  )
}
