import { getFileType, getIconPropsFromFileName } from '@hubtype/util-shared'
import { cx } from 'class-variance-authority'
import React, { forwardRef, useEffect, useState } from 'react'
import { mergeProps, useField, useFocusRing, useObjectRef } from 'react-aria'

import { FieldFooter, FooterErrorProps } from '../field/footer'
import { FieldLabel } from '../field/label'
import Icon from '../icon/icon'
import IconButton from '../icon-button/icon-button'
import { Spinner } from '../spinner/spinner'
import styles from './uploader.module.css'

export interface UploaderProps
  extends Omit<FooterErrorProps, 'errorMessageProps'> {
  /** The maximum file size in MB that can be uploaded. */
  maxFileSize: number
  /** The label of the uploader. */
  label?: string
  /** The description of the uploader. */
  description?: string
  /** The accepted file types. */
  acceptedFileTypes?: string
  /** Handler that is called when a file is uploaded. */
  onChange: (file?: File) => void | Promise<void>
}

/** The uploader component allows users to upload files. */
export const Uploader = forwardRef<HTMLInputElement, UploaderProps>(
  (
    {
      maxFileSize,
      acceptedFileTypes,
      description,
      label,
      onChange,
      ...errorProps
    },
    forwardedRef
  ) => {
    const ref = useObjectRef(forwardedRef)
    const [file, setFile] = useState<File | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState(errorProps.errorMessage)
    const [isInvalid, setIsInvalid] = useState(errorProps.isInvalid)
    const [isDragging, setIsDragging] = useState(false)

    const { isFocusVisible, focusProps } = useFocusRing()
    const { fieldProps, errorMessageProps, labelProps, descriptionProps } =
      useField({ ...errorProps, label })

    useEffect(() => {
      setIsInvalid(errorProps.isInvalid)
      setErrorMessage(errorProps.errorMessage)
    }, [errorProps.isInvalid, errorProps.errorMessage])

    const handleFileUpload = (file: File) => {
      if (maxFileSize && file.size > maxFileSize * 1024 * 1024) {
        setIsInvalid(true)
        setErrorMessage(
          `File exceeds ${maxFileSize} MB, please upload a file that is maximum ${maxFileSize} MB.`
        )
        setFile(null)
      } else {
        uploadFile(file)
      }
    }

    const uploadFile = async (file: File) => {
      setIsInvalid(false)
      setErrorMessage(undefined)
      setIsLoading(true)
      setFile(file)
      await onChange(file)
      setIsLoading(false)
    }

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const uploadedFile = event.target.files?.[0]
      if (uploadedFile) {
        handleFileUpload(uploadedFile)
      }
    }

    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragging(false)
      if (file) return
      const droppedFile = event.dataTransfer.files[0]
      const fileType = getFileType(droppedFile.name)
      if (
        !acceptedFileTypes ||
        (fileType && acceptedFileTypes.includes(fileType))
      ) {
        handleFileUpload(droppedFile)
      }
    }

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      setIsDragging(true)
    }

    const onDragLeave = () => {
      setIsDragging(false)
    }

    const handleRemoveFile = () => {
      setFile(null)
      setIsInvalid(false)
      setErrorMessage(undefined)
      onChange(undefined)
    }

    return (
      <div className={styles.fileUploader}>
        <div>
          {label && <FieldLabel label={label} labelProps={labelProps} />}
          {description && (
            <div className={styles.description} {...descriptionProps}>
              {description}
            </div>
          )}
        </div>
        {!isLoading ? (
          <div
            className={styles.uploadArea}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            data-focus-visible={isFocusVisible || undefined}
          >
            <input
              {...mergeProps(focusProps, fieldProps)}
              ref={ref}
              id={`file-input-${ref.current?.id}`}
              type='file'
              accept={acceptedFileTypes}
              onChange={onFileInputChange}
              disabled={isLoading}
            />
            <label htmlFor={`file-input-${ref.current?.id}`}>
              {isInvalid || !file ? (
                <div
                  className={cx(
                    styles.uploaderMessage,
                    isDragging && styles.dragActive
                  )}
                >
                  <span className={styles.title}>
                    <Icon icon='arrow-up-from-line' />
                    Upload
                  </span>
                  <p className={styles.subtitle}>
                    Drag or click here to upload your file
                  </p>
                </div>
              ) : (
                <div className={styles.uploadedFile}>
                  <div className={styles.fileName}>
                    <Icon {...getIconPropsFromFileName(file.name)} />
                    <span>{file.name}</span>
                  </div>
                  <IconButton
                    icon='trash-can'
                    size='small'
                    onPress={handleRemoveFile}
                  />
                </div>
              )}
            </label>
          </div>
        ) : (
          <div className={styles.loading}>
            <Spinner intent='secondary' />
          </div>
        )}
        <FieldFooter
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          errorMessageProps={errorMessageProps}
        />
      </div>
    )
  }
)
