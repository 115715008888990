import { forwardRef } from 'react'

import styles from './interactive-card.module.css'
import { RadioGroup, RadioGroupProps } from './radio-group'

export const InteractiveCardGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  (props, forwardedRef) => {
    return (
      <div className={styles.cardGroup} ref={forwardedRef}>
        <RadioGroup {...props} orientation='horizontal' />
      </div>
    )
  }
)
