import { DOMAttributes, FocusableElement } from '@react-types/shared'
import { LabelHTMLAttributes } from 'react'

import styles from './field.module.css'

export interface LabelProps {
  /** The field's label. */
  label?: string
  /** Whether user input is required on the input before form submission. */
  isRequired?: boolean
  /** Whether the field is optional. */
  isOptional?: boolean
  /** Whether the label is hidden. */
  isLabelHidden?: boolean
  /** Props for the label element. */
  labelProps?:
    | DOMAttributes<FocusableElement>
    | LabelHTMLAttributes<HTMLLabelElement>
  /** Right slot for the label */
  labelRightSlot?: React.ReactNode
}

export function FieldLabel({
  isLabelHidden,
  label,
  isRequired,
  isOptional,
  labelProps,
  labelRightSlot,
}: LabelProps) {
  if (isLabelHidden) return null

  return (
    <div className={styles.labelContainer}>
      <label {...labelProps} className={styles.label}>
        {label}
      </label>
      {isRequired && <span className={styles.required}>*</span>}
      {isOptional && <span className={styles.optional}>(Optional)</span>}
      {labelRightSlot && (
        <span className={styles.labelSlot}>{labelRightSlot}</span>
      )}
    </div>
  )
}
