import { Button, Icon } from '@hubtype/ui-react-web'
import { cx } from 'class-variance-authority'

import styles from './notification.module.css'

interface NotificationButton {
  text: string
  icon?: string
  onClick: () => void
}

export interface HtAiNotificationWithActionsProps {
  confirmButton: NotificationButton
  discardButton: NotificationButton
  isLoading?: boolean
  children: string
}

export const HtAiNotificationWithActions = ({
  confirmButton,
  discardButton,
  isLoading,
  children,
}: HtAiNotificationWithActionsProps): JSX.Element => {
  return (
    <div className={cx(styles.notification, styles.withActions, styles.ai)}>
      {children}
      <div>
        <Button
          intent='secondary'
          appearance='minimal'
          size='small'
          isDisabled={isLoading}
          onPress={discardButton.onClick}
        >
          {discardButton.text}
        </Button>
        <Button
          className={styles.aiButton}
          size='small'
          isLoading={isLoading}
          onPress={confirmButton.onClick}
        >
          <span>
            {!isLoading && confirmButton.icon && (
              <Icon icon={confirmButton.icon} />
            )}
            {confirmButton.text}
          </span>
        </Button>
      </div>
    </div>
  )
}
