import { Edge, XYPosition } from 'reactflow'

import { NodeEdges } from '../../../UI/components/edges/edge-utils'
import { IdMapping, NonMessageContents } from '../../../UI/types'
import {
  flowIdToWebviewId,
  isWebview,
  webviewIdToFlowId,
} from '../../../webviews/utils'
import { HtWebviewTextNode } from '../../../webviews/webview-text'
import { HtButtonStyle, HtContentType } from '../cms/hubtype/node-types'
import { HtTextNode } from '../cms/hubtype/text'
import { LocaleCode } from '../locales/code'
import { LocaleManager } from '../utils/locale-manager'
import {
  ButtonFields,
  ButtonStyle,
  ContentType,
  MessageContentType,
  TopContentFieldsBase,
} from '.'
import { TextLocale } from './common'

export class TextFields extends TopContentFieldsBase {
  public selectedSubContentId?: string
  constructor(
    public text = '',
    public buttons: ButtonFields[] = [],
    public buttonsStyle = ButtonStyle.BUTTON,
    public textLocales: TextLocale[] = []
  ) {
    super()
  }

  contentType(): MessageContentType {
    return ContentType.TEXT
  }

  stringContentType(): string {
    return 'text'
  }

  setValuesByLocale(
    locale: string,
    nonMessageContents: NonMessageContents
  ): void {
    this.text = LocaleManager.getTextByLocale(this.textLocales, locale)
    this.buttons.forEach(b => b.setValuesByLocale(locale, nonMessageContents))
  }

  setContentByLocale(locale: LocaleCode): void {
    this.textLocales = LocaleManager.setTextByLocale(
      this.textLocales,
      this.text,
      locale
    )
    this.buttons.forEach(b => b.setContentByLocale(locale))
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    LocaleManager.addLocale(this.textLocales, newLocale, copyFrom)
    this.buttons.forEach(button => button.addLocale(newLocale, copyFrom))
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    LocaleManager.removeLocales(this.textLocales, localesToRemove)
    this.buttons.forEach(button => button.removeLocales(localesToRemove))
  }

  static fromHubtypeCMS(
    component: HtTextNode | HtWebviewTextNode,
    locale: string,
    nonMessageContents: NonMessageContents
  ): TextFields {
    const newText = new TextFields()
    this.setHubtypeCmsCommonData(newText, component)
    if (component.type === HtContentType.TEXT) {
      newText.buttonsStyle =
        component.content.buttons_style === HtButtonStyle.QUICK_REPLY
          ? ButtonStyle.QUICK_REPLY
          : ButtonStyle.BUTTON
    }
    newText.textLocales = component.content.text
    newText.setValuesByLocale(locale, nonMessageContents)
    if (component.type === HtContentType.WEBVIEW_TEXT && component.webview_id) {
      newText.flowId = flowIdToWebviewId(component.webview_id)
    }
    return newText
  }

  toHubtypeCMS(
    position: XYPosition,
    locale: LocaleCode
  ): HtTextNode | HtWebviewTextNode {
    this.setContentByLocale(locale)
    if (isWebview(this.flowId)) {
      const node: HtWebviewTextNode = {
        id: this.id,
        code: this.code,
        is_code_ai_generated: this.isCodeAiGenerated,
        webview_id: webviewIdToFlowId(this.flowId),
        meta: position,
        type: HtContentType.WEBVIEW_TEXT,
        content: { text: this.textLocales },
      }
      return node
    }
    const node: HtTextNode = {
      ...this.getHubtypeCmsCommonData(position),
      type: HtContentType.TEXT,
      content: {
        text: this.textLocales,
        buttons_style: this.buttonsStyle as unknown as HtButtonStyle,
        buttons: this.buttons.map(button => button.toHubtypeCMS(locale)),
      },
    }
    return node
  }

  setErrors(): void {
    const requiredFields = []
    if (!this.code) requiredFields.push('content ID')
    if (!this.text) requiredFields.push('text')
    const fieldErrors = this.getMissingFieldsErrors(requiredFields)
    const buttonsErrors = this.buttons.flatMap(button =>
      button.getErrors(this.code, this.edges)
    )
    this.errors.fieldErrors = [...buttonsErrors, ...fieldErrors]
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    const textErrors = LocaleManager.getLocalesWithEmptyText(
      this.textLocales,
      locales
    )
    const buttonsErrors = this.buttons.flatMap(button =>
      button.getLocalesWithErrors(locales)
    )
    this.errors.localesWithErrors = [...textErrors, ...buttonsErrors]
    return this.errors.localesWithErrors
  }

  hasString(value: string): boolean {
    if (this.fieldsIncludeString([this.code, this.text], value)) return true
    const includesString = this.buttons.map(b => b.hasString(value))
    return includesString.indexOf(true) !== -1
  }

  getConnections(edges: Edge[]): NodeEdges {
    return {
      buttons: this.buttons.map(b => b.getConnections(edges)),
      followUp: this.getFollowUp(edges),
    }
  }

  static getCopy(content: TextFields, isWebview?: boolean): TextFields {
    const newText = new TextFields()
    TopContentFieldsBase.copyContent(content, newText)
    newText.buttons = content.buttons.map(button =>
      ButtonFields.getCopy(button)
    )
    if (isWebview) newText.buttons = []
    return newText
  }

  getIdMappingForOldFields(oldText: TextFields): IdMapping {
    return this.buttons.reduce((idMapping: IdMapping, button, i) => {
      idMapping[oldText.buttons[i].id] = button.id
      return idMapping
    }, {})
  }
}
