import { cva } from 'class-variance-authority'
import { ComponentProps, forwardRef } from 'react'
import {
  mergeProps,
  PressEvent,
  useButton,
  useFocusRing,
  useObjectRef,
} from 'react-aria'

import { Icon } from '../icon/icon'
import styles from './icon-button.module.css'

const iconClasses = cva(styles.base, {
  variants: {
    size: {
      small: styles.small,
      medium: styles.medium,
      large: styles.large,
    },
    shape: {
      rounded: styles.rounded,
      square: styles.square,
    },
  },
})

export interface IconButtonProps {
  /** The name of the icon. */
  icon: ComponentProps<typeof Icon>['icon']
  /** The size of the icon. */
  size?: 'small' | 'medium' | 'large'
  /** The prefix of the icon. */
  prefix?: ComponentProps<typeof Icon>['prefix']
  /** The shape of the icon. */
  shape?: 'rounded' | 'square'
  /** Whether the button is disabled. */
  isDisabled?: boolean
  /** Whether the element should receive focus on render. */
  autoFocus?: boolean
  /** A class name to apply to the button. */
  className?: string
  /** Handler that is called when the press is released over the target. */
  onPress?: (evt: PressEvent) => void
  /** Handler that is called when the pointer enters the target. */
  onPointerEnter?: (evt: PointerEvent) => void
  /** Handler that is called when the pointer leaves the target. */
  onPointerLeave?: (evt: PointerEvent) => void
}

/** An icon button is a button that contains only an icon with no visible text labels */
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { size = 'medium', shape = 'square', prefix, icon, className, ...props },
    forwardedRef
  ) => {
    const ref = useObjectRef(forwardedRef)
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { buttonProps } = useButton({ 'aria-label': icon, ...props }, ref)
    const { focusProps, isFocusVisible } = useFocusRing()

    return (
      <button
        {...mergeProps(buttonProps, focusProps, {
          onPointerEnter: props.onPointerEnter,
          onPointerLeave: props.onPointerLeave,
        })}
        ref={ref}
        className={iconClasses({ size, shape, className })}
        data-focus-visible={isFocusVisible || undefined}
      >
        <Icon icon={icon} prefix={prefix} />
      </button>
    )
  }
)

export default IconButton
