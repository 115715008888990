import { HtQueueLocale } from '../cms/hubtype/common'
import { OrganizationQueue } from '../organization-models'
import {
  ContentFieldsBase,
  ContentType,
  NonMessageContentType,
  QueueLocale,
} from '.'

export class QueueFields extends ContentFieldsBase {
  constructor(
    public project = '',
    public name = '',
    public isInvalid = false
  ) {
    super()
  }

  contentType(): NonMessageContentType {
    return ContentType.QUEUE
  }

  static fromHubtype(content: OrganizationQueue): QueueFields {
    const newQueue = new QueueFields()
    newQueue.id = content.id
    newQueue.project = content.project
    newQueue.name = content.name
    return newQueue
  }

  static fromHubtypeCMS(component: QueueLocale): QueueFields {
    const newQueue = new QueueFields()
    newQueue.id = component.id
    newQueue.name = component.name
    newQueue.project = component.project
    newQueue.isInvalid = component.isInvalid
    return newQueue
  }

  toHubtypeCMS(locale: string): HtQueueLocale | undefined {
    if (!this.id || !this.name) return undefined
    const node: HtQueueLocale = {
      id: this.id,
      name: this.name,
      locale: locale,
    }
    return node
  }

  getValuesByLocale(locale: string): QueueLocale | undefined {
    if (!this.id || !this.name) return undefined
    const node: QueueLocale = {
      id: this.id,
      project: this.project,
      name: this.name,
      locale: locale,
      isInvalid: this.isInvalid,
    }
    return node
  }

  static getCopy(content: QueueFields): QueueFields {
    const newQueue = new QueueFields()
    Object.assign(newQueue, content)
    return newQueue
  }
}
