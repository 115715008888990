import { TrackEventName, useAnalytics } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { ControlButton } from '../control-button'
import styles from '../flow-controls.module.css'
import { useUndoRedo } from './use-undo-redo'

export const UndoRedoControls = () => {
  const analytics = useAnalytics()
  const { historyIndex, changeHistory } = useFlowBuilderSelector(
    ctx => ctx.state
  )
  const { undo, redo } = useFlowBuilderSelector(ctx => ctx)

  const onUndo = (hasClickedButton?: boolean) => {
    if (changeHistory.length === 0 || historyIndex < 0) return
    analytics.trackEvent(TrackEventName.UNDO, {
      origin: getOrigin(hasClickedButton),
      consecutive_undo_count: changeHistory.length - historyIndex,
    })
    undo()
  }

  const onRedo = (hasClickedButton?: boolean) => {
    if (historyIndex + 1 === changeHistory.length) return
    analytics.trackEvent(TrackEventName.REDO, {
      origin: getOrigin(hasClickedButton),
    })
    redo()
  }

  const getOrigin = (hasClickedButton?: boolean) => {
    return hasClickedButton ? 'controls' : 'keyboard'
  }

  useUndoRedo(onUndo, onRedo)

  return (
    <div className={styles.controls}>
      <ControlButton
        icon='rotate-left'
        aria-label='Undo'
        onPress={() => onUndo(true)}
        isDisabled={historyIndex < 0}
      />
      <ControlButton
        icon='rotate-right'
        aria-label='Redo'
        onPress={() => onRedo(true)}
        isDisabled={historyIndex + 1 === changeHistory.length}
      />
    </div>
  )
}
