export const ROLES = {
  ALERT: 'alert',
  BANNER: 'banner',
  BUTTON: 'button',
  CHECKBOX: 'checkbox',
  COMPLEMENTARY: 'complementary',
  DIALOG: 'dialog',
  IMG: 'img',
  LIST_BOX: 'listbox',
  LIST: 'list',
  MENU_ITEM_RADIO: 'menuitemradio',
  MENU_ITEM: 'menuitem',
  MENU: 'menu',
  OPTION: 'option',
  SEARCH_BOX: 'search-box',
  SEARCH: 'search',
  SLIDER: 'slider',
  SWITCH: 'switch',
  TEXTBOX: 'textbox',
}

export const ARIA_LABEL = {
  ELEMENT_EDITOR: 'element-editor',
  FLOW: 'flow',
  IMAGE_INPUT: 'image-input',
  NODE_EDITOR: 'node-editor',
  SCREEN_OVERLAY: 'screen-overlay',
  SORTABLE_CONTENT: 'sortable-content',
}
