import { Icon, Switch } from '@hubtype/ui-react-web'
import { memo } from 'react'

import { TextSmallExtraBold } from '../../base'
import { MEANINGFUL_ELEMENT } from '../constants'
import { MeaningfulElementContainer } from '../editor-styles'

interface MeaningfulElementProps {
  isMeaningful: boolean
  readOnly?: boolean
  onChange: (action: any) => void
}

export const MeaningfulElement = memo((props: MeaningfulElementProps) => {
  const onCheckboxChange = (isSelected: boolean) => {
    props.onChange({
      type: MEANINGFUL_ELEMENT.actionType,
      fieldKey: MEANINGFUL_ELEMENT.key,
      value: isSelected,
    })
  }
  return (
    <MeaningfulElementContainer>
      <TextSmallExtraBold>
        <Icon icon='chart-simple' size='small' />
        Advanced Analytics
      </TextSmallExtraBold>
      <Switch
        size='small'
        isReadOnly={props.readOnly}
        isSelected={props.isMeaningful}
        description={MEANINGFUL_ELEMENT.helperText}
        onChange={onCheckboxChange}
      >
        {MEANINGFUL_ELEMENT.label}
      </Switch>
    </MeaningfulElementContainer>
  )
})
