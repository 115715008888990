import { EventProperties } from '@segment/analytics-next'
import { useEffect } from 'react'

import { TrackEventName, useAnalytics } from '../../../../analytics'
import { useFeedbackMessage } from '../../../../custom-hooks/use-feedback-message'

export interface MachineFeedbackEvent {
  type: 'success' | 'error'
  message: string
}

export interface MachineAnalyticsEvent {
  type: 'analytics'
  eventName: TrackEventName
  eventProps?: EventProperties
}

export const useMachineFeedback = (machine?: any) => {
  const { reportSuccess, reportError } = useFeedbackMessage()
  const analytics = useAnalytics()

  useEffect(() => {
    const subscriptionSuccess = machine?.on(
      'success',
      (event: MachineFeedbackEvent) => {
        reportSuccess(event.message)
      }
    )

    const subscriptionError = machine?.on(
      'error',
      (event: MachineFeedbackEvent) => {
        reportError(event.message)
      }
    )

    const subscriptionAnalytics = machine?.on(
      'analytics',
      (event: MachineAnalyticsEvent) => {
        analytics.trackEvent(event.eventName, event.eventProps)
      }
    )

    return () => {
      subscriptionSuccess?.unsubscribe()
      subscriptionError?.unsubscribe()
      subscriptionAnalytics.unsubscribe()
    }
  }, [machine])
}
