import { CustomConditionFields } from '../../../nodes/custom-conditional/model'
import { KnowledgeBaseFields } from '../../../nodes/knowledge-base'
import { SmartIntentFields } from '../../../nodes/smart-intent/model'
import { WhatsappCTAUrlButtonFields } from '../../../nodes/whatsapp-cta-url-button'
import {
  BotActionFields,
  CarouselFields,
  ChannelFields,
  FallbackFields,
  GoToFlowFields,
  HandoffFields,
  ImageFields,
  IntentFields,
  KeywordFields,
  PayloadFields,
  QueueFields,
  QueueStatusFields,
  StartFields,
  TextFields,
  UrlFields,
  VideoFields,
  WhatsappButtonListFields,
} from '.'
import { CountryConditionFields } from './country'

export enum MessageContentType {
  CAROUSEL = 'carousel',
  HANDOFF = 'handoff',
  IMAGE = 'image',
  VIDEO = 'video',
  TEXT = 'text',
  WHATSAPP_BUTTON_LIST = 'whatsapp-button-list',
  GO_TO_FLOW = 'go-to-flow',
  BOT_ACTION = 'bot-action',
  WHATSAPP_CTA_URL_BUTTON = 'whatsapp-cta-url-button',
  KNOWLEDGE_BASE = 'knowledge-base',
}

export enum NonMessageContentType {
  PAYLOAD = 'payload',
  QUEUE = 'queue',
  URL = 'url',
}

export enum SubContentType {
  BUTTON = 'button',
  ELEMENT = 'element',
  ROW = 'row',
  SECTION = 'section',
  CONDITION_VALUE = 'condition-value',
}

export enum MediaContentType {
  ASSET = 'asset',
}

export enum StartContentType {
  START = 'start',
}

export enum InputContentType {
  KEYWORD = 'keyword',
  INTENT = 'intent',
  SMART_INTENT = 'smart-intent',
}

export enum ConditionalContentType {
  QUEUE_STATUS = 'queue-status',
  CHANNEL = 'channel',
  COUNTRY_CONDITION = 'country-condition',
  FALLBACK = 'fallback',
  CUSTOM_CONDITION = 'custom-condition',
}

export const NodeContentType = {
  ...MessageContentType,
  ...InputContentType,
  ...ConditionalContentType,
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type NodeContentType =
  | MessageContentType
  | InputContentType
  | ConditionalContentType

export const ContentType = {
  ...MessageContentType,
  ...SubContentType,
  ...NonMessageContentType,
  ...MediaContentType,
  ...InputContentType,
  ...ConditionalContentType,
  ...StartContentType,
}
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ContentType =
  | MessageContentType
  | SubContentType
  | NonMessageContentType
  | MediaContentType
  | InputContentType
  | ConditionalContentType
  | StartContentType

export enum ButtonStyle {
  BUTTON = 'button',
  QUICK_REPLY = 'quick-reply',
}

export type TopContentFields =
  | TextFields
  | CarouselFields
  | ImageFields
  | HandoffFields
  | KeywordFields
  | IntentFields
  | QueueStatusFields
  | ChannelFields
  | FallbackFields
  | VideoFields
  | WhatsappButtonListFields
  | GoToFlowFields
  | BotActionFields
  | StartFields
  | CountryConditionFields
  | CustomConditionFields
  | SmartIntentFields
  | WhatsappCTAUrlButtonFields
  | KnowledgeBaseFields

export type NonMessageContentFields = UrlFields | PayloadFields | QueueFields
