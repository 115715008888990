import { Icon, IconName } from '@hubtype/ui-react-web'
import { useRef } from 'react'
import {
  AriaButtonOptions,
  mergeProps,
  useButton,
  useFocusRing,
} from 'react-aria'

import styles from './flow-controls.module.css'

interface ControlButtonProps extends AriaButtonOptions<'button'> {
  icon: IconName
}

export const ControlButton = ({ icon, ...props }: ControlButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null)
  const { buttonProps } = useButton(props, ref)
  const { focusProps, isFocusVisible } = useFocusRing()
  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      className={styles.controlButton}
      data-focus-visible={isFocusVisible || undefined}
    >
      <Icon icon={icon} />
    </button>
  )
}
