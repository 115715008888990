import styled from 'styled-components'

import { COLORS } from '../../colors'

export const StyledEditableItem = styled.div<{ $isLongItem?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  margin: -8px -16px;
  svg {
    color: ${COLORS.N500};
  }

  > :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: ${props => (props.$isLongItem ? 'break-all' : 'normal')};
    color: ${COLORS.N900};
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  > div:last-child {
    display: flex;
    visibility: hidden;
  }

  &:hover {
    > div:last-child {
      visibility: visible;
    }
  }
`

export const StyledSelectHeader = styled.div`
  padding: 0px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${COLORS.N500};
`
