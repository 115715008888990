import { cloneElement, ReactElement, ReactNode, useRef } from 'react'
import { useOverlayTrigger } from 'react-aria'
import { useOverlayTriggerState } from 'react-stately'

import { Popover, PopoverProps } from './popover'

export interface PopoverTriggerProps
  extends Omit<PopoverProps, 'children' | 'state' | 'triggerRef'> {
  /** The trigger that opens the dialog. */
  trigger?: ReactElement
  /** The content of the dialog. */
  children?: ReactNode | ((opts: { close: () => void }) => ReactNode)
  /** Whether the popover is open (controlled). */
  isOpen?: boolean
  /** Whether the popover is open by default (uncontrolled). */
  defaultOpen?: boolean
  /** The type of overlay. */
  type?: 'dialog' | 'menu' | 'listbox' | 'tree' | 'grid'
  /** Handler that is called when the open state changes. */
  onOpenChange?: (isOpen: boolean) => void
}

export const PopoverTrigger = ({
  children,
  trigger,
  type = 'dialog',
  ...props
}: PopoverTriggerProps) => {
  const triggerRef = useRef(null)
  const state = useOverlayTriggerState(props)
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type },
    state,
    triggerRef
  )

  if (typeof children === 'function') {
    children = children({ close: state?.close, ...overlayProps })
  } else {
    children = cloneElement(children as ReactElement, overlayProps)
  }

  return (
    <>
      {trigger && cloneElement(trigger, { ...triggerProps, ref: triggerRef })}
      {state.isOpen && (
        <Popover {...props} triggerRef={triggerRef} state={state}>
          {children}
        </Popover>
      )}
    </>
  )
}
