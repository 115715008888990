import { memo, useState } from 'react'
import { EdgeProps, getBezierPath } from 'reactflow'

import { TrackEventName, useAnalytics } from '../../analytics'
import { FALLBACK_NODE_ID } from '../../constants'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { ViewportAnimator } from '../../utils/viewport-animator'
import { COLORS } from '../base'
import {
  EdgeBackground,
  EdgePath,
  RemoveButton,
  ToSource,
  ToTarget,
} from './edge-styles'
import { getCirclePathIntersection, Point2D } from './intersection'

const EDGE_BUTTON_SIZE = 26
const EDGE_CURVATURE = 0.35
const MIN_EDGE_BUTTONS_LENGTH = 200

const BezierEdge = (edgeProps: EdgeProps): JSX.Element => {
  const { id, selected, source, target, sourceX, sourceY, targetX, targetY } =
    edgeProps
  const analytics = useAnalytics()
  const currentNodeId = useFlowBuilderSelector(ctx => ctx.state.currentNode?.id)
  const state = useFlowBuilderSelector(ctx => ctx.state)
  const removeEdgesById = useFlowBuilderSelector(ctx => ctx.removeEdgesById)
  const selectNode = useFlowBuilderSelector(ctx => ctx.selectNode)
  const [hover, setHover] = useState(false)

  const selectAndCenterNode = (evt: React.MouseEvent, id: string): void => {
    evt.stopPropagation()
    ViewportAnimator.centerNode(state, id, selectNode)
  }

  const getEdgePath = (): string => {
    let curvature = EDGE_CURVATURE
    const distance = getDistance()
    if (sourceX > targetX) curvature = 20 / Math.sqrt(distance * 2)
    const hx1 = sourceX + Math.abs(targetX - sourceX) * curvature
    const hx2 = targetX - Math.abs(targetX - sourceX) * curvature
    return `M${sourceX},${sourceY} C${hx1},${sourceY} ${hx2},${targetY}, ${targetX},${targetY}`
  }

  const hasNavigationButton = (id: string) => {
    if (state.isReadOnly) return false
    if (!selected && currentNodeId !== id) return false
    return getDistance() > MIN_EDGE_BUTTONS_LENGTH
  }

  const getNavigationButtonProps = (center: Point2D) => {
    const point = getNavigationButtonPosition(center)
    if (!point) return getButtonProps(center)
    return getButtonProps(point)
  }

  const getButtonProps = (point: Point2D) => {
    return {
      height: EDGE_BUTTON_SIZE,
      width: EDGE_BUTTON_SIZE,
      x: point.x - EDGE_BUTTON_SIZE / 2,
      y: point.y - EDGE_BUTTON_SIZE / 2,
    }
  }

  const getCenterProps = () => {
    const [, labelX, labelY] = getBezierPath(edgeProps)
    return getButtonProps({ x: labelX, y: labelY })
  }

  const getDistance = () => {
    return Math.sqrt(
      Math.pow(sourceX - targetX, 2) + Math.pow(sourceY - targetY, 2)
    )
  }

  const getNavigationButtonPosition = (
    center: Point2D
  ): Point2D | undefined => {
    return getCirclePathIntersection(center.x, center.y, 40, getEdgePath())
  }

  const removeEdge = () => {
    if (edgeProps.sourceHandleId) {
      analytics.trackEvent(TrackEventName.CLICK_DELETE_EDGE)
      removeEdgesById([edgeProps.id])
    }
  }

  return (
    <>
      <EdgeBackground
        d={getEdgePath()}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
      <EdgePath
        $isSelected={selected}
        $hasHover={hover}
        id={id}
        d={getEdgePath()}
      />
      {hasNavigationButton(source) && (
        <ToSource
          {...getNavigationButtonProps({ x: sourceX, y: sourceY })}
          onClick={evt => selectAndCenterNode(evt, target)}
        />
      )}
      {hasNavigationButton(target) && (
        <ToTarget
          {...getNavigationButtonProps({ x: targetX, y: targetY })}
          onClick={evt => selectAndCenterNode(evt, source)}
        />
      )}
      {selected && !state.isReadOnly && target !== FALLBACK_NODE_ID && (
        <RemoveButton
          {...getCenterProps()}
          fill={COLORS.WHITE}
          color={COLORS.N500}
          onClick={evt => {
            evt.stopPropagation()
            removeEdge()
          }}
        />
      )}
    </>
  )
}

export default memo(BezierEdge)
