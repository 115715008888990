import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'
import {
  mergeProps,
  PressEvent,
  useButton,
  useFocusRing,
  useObjectRef,
} from 'react-aria'

import { Spinner } from '../spinner/spinner'
import styles from './button.module.css'

const buttonClasses = cva(styles.base, {
  variants: {
    intent: {
      primary: styles.primary,
      secondary: styles.secondary,
      danger: styles.danger,
    },
    appearance: {
      solid: styles.solid,
      outline: styles.outline,
      minimal: styles.minimal,
    },
    size: {
      small: styles.small,
      medium: styles.medium,
      large: styles.large,
    },
    isLoading: {
      true: styles.loading,
    },
  },
  defaultVariants: { intent: 'primary', size: 'medium' },
})

export interface ButtonProps {
  /** The size of the button. */
  size?: 'small' | 'medium' | 'large'
  /** The intent of the button. */
  intent?: 'primary' | 'secondary' | 'danger'
  /** The appearance of the button. */
  appearance?: 'solid' | 'outline' | 'minimal'
  /** The button's children. */
  children: React.ReactNode
  /** The behavior of the button when used in an HTML form. */
  type?: 'button' | 'submit' | 'reset'
  /** Whether the button is disabled. */
  isDisabled?: boolean
  /** Whether the button is loading. */
  isLoading?: boolean
  /** Whether the element should receive focus on render. */
  autoFocus?: boolean
  /**
   * The `<form>` element to associate the button with.
   * The value of this attribute must be the id of a `<form>` in the same document.
   */
  form?: string
  /** A class name to apply to the button. */
  className?: string
  /** Handler that is called when the press is released over the target. */
  onPress?: (evt: PressEvent) => void
  /** Handler that is called when the pointer enters the target. */
  onPointerEnter?: (evt: PointerEvent) => void
  /** Handler that is called when the pointer leaves the target. */
  onPointerLeave?: (evt: PointerEvent) => void
}

/** A button allows a user to perform an action, with mouse, touch, and keyboard interactions. */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = 'medium',
      intent = 'primary',
      appearance = 'solid',
      children,
      className,
      isLoading,
      ...props
    },
    forwardedRef
  ) => {
    const ref = useObjectRef(forwardedRef)
    const { buttonProps } = useButton(
      { ...props, isDisabled: props.isDisabled || isLoading },
      ref
    )
    const { focusProps, isFocusVisible } = useFocusRing()

    return (
      <button
        {...mergeProps(buttonProps, focusProps, {
          onPointerEnter: props.onPointerEnter,
          onPointerLeave: props.onPointerLeave,
        })}
        ref={ref}
        form={props.form}
        className={buttonClasses({
          intent,
          appearance,
          size,
          className,
          isLoading,
        })}
        data-focus-visible={isFocusVisible || undefined}
      >
        {isLoading && <Spinner intent='secondary' />}
        {children}
      </button>
    )
  }
)

export default Button
