import { useEffect, useState } from 'react'

import {
  ContentType,
  PayloadFields,
} from '../../../../domain/models/content-fields'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { NodeTypes, PopupType } from '../../../types'
import { BoldSpan, TextBodySmall } from '../../base'
import { Popup } from '../popup'

interface DeletePayloadPopupProps {
  payloadToRemove: PayloadFields
}

export const DeletePayloadPopup = ({
  payloadToRemove,
}: DeletePayloadPopupProps): JSX.Element => {
  const { setPopupContent, removePayload } = useFlowBuilderSelector(ctx => ctx)
  const { currentNode, nodes } = useFlowBuilderSelector(ctx => ctx.state)
  const [nodesWithPayload, setNodesWithPayload] = useState<NodeTypes[]>([])

  useEffect(() => {
    const linkedNodes = findLinkedNodes()
    if (shouldPayloadBeAutoDeleted(linkedNodes)) {
      deletePayload()
    } else {
      setNodesWithPayload(linkedNodes)
    }
  }, [])

  const findLinkedNodes = (): NodeTypes[] => {
    return nodes.filter(
      node =>
        node.type === ContentType.BOT_ACTION &&
        node.data.payloadId === payloadToRemove.id
    )
  }

  const shouldPayloadBeAutoDeleted = (linkedNodes: NodeTypes[]): boolean => {
    return (
      (linkedNodes.length === 1 && linkedNodes[0].id === currentNode?.id) ||
      linkedNodes.length === 0
    )
  }

  const deletePayload = (): void => {
    setPopupContent()
    removePayload(payloadToRemove)
  }

  if (nodesWithPayload.length === 0) return <></>

  return (
    <Popup
      title={PopupType.DELETE_PAYLOAD}
      confirmButton={{ text: 'Delete', onClick: deletePayload }}
      message={
        'This payload is being used in one or more other bot actions in this conversational app. If it is deleted, it will be also removed from all the other bot action components.'
      }
    >
      <TextBodySmall>
        Payload to delete: <BoldSpan>{payloadToRemove.name}</BoldSpan>
      </TextBodySmall>
      <TextBodySmall>
        Bot actions using it:{' '}
        <BoldSpan>
          {nodesWithPayload.map(node => node.data.code).join('; ')}
        </BoldSpan>
      </TextBodySmall>
    </Popup>
  )
}
