import { Icon, IconButton } from '@hubtype/ui-react-web'

import { FeedbackType } from '../../../../domain/feedback'
import { StyledNotification } from './ht-notification-styles'

export interface HtNotificationProps {
  children: React.ReactNode
  type: FeedbackType
  isAi?: boolean
  onClose: () => void
}

const getIconFromFeedbackType = (feedbackType: FeedbackType): string => {
  switch (feedbackType) {
    case FeedbackType.SUCCESS:
      return 'circle-check'
    case FeedbackType.INFORM:
      return 'circle-info'
    case FeedbackType.CONNECTION_ISSUE:
      return 'cloud-slash'
    default:
      return 'circle-xmark'
  }
}

export const HtNotification = ({
  children,
  type,
  isAi,
  onClose,
}: HtNotificationProps): JSX.Element => {
  return (
    <StyledNotification $isAi={isAi}>
      <Icon icon={getIconFromFeedbackType(type)} size='large' prefix='far' />
      {children}
      <IconButton icon='xmark' size='large' onPress={onClose} />
    </StyledNotification>
  )
}
