import { cva } from 'class-variance-authority'
import React, { useRef } from 'react'
import { mergeProps, useFocusRing, useSwitch } from 'react-aria'
import { useToggleState } from 'react-stately'

import Field from '../field/field'
import { FooterDescriptionProps, FooterErrorProps } from '../field/footer'
import styles from './switch.module.css'

const switchClasses = cva(styles.base, {
  variants: {
    size: {
      small: [styles.small],
      medium: [styles.medium],
    },
  },
  defaultVariants: { size: 'medium' },
})

export interface SwitchProps extends FooterDescriptionProps, FooterErrorProps {
  /** The content to render as the Switch's label. */
  children: React.ReactNode
  /** The size of the switch. */
  size?: 'small' | 'medium'
  /** Whether the switch is disabled. */
  isDisabled?: boolean
  /** Whether the Switch should be selected (controlled). */
  isReadOnly?: boolean
  /** Whether the element should be selected (controlled). */
  isSelected?: boolean
  /** Whether the Switch should be selected (uncontrolled). */
  defaultSelected?: boolean
  /** The name of the input element, used when submitting an HTML form */
  name?: string
  /** Whether the element should receive focus on render. */
  autoFocus?: boolean
  /** Handler that is called when the Switch's selection state changes. */
  onChange?: (isSelected: boolean) => void
}

/** Use switch to toggle a setting on or off, and immediately saves the change. */
export function Switch({ size = 'medium', ...props }: SwitchProps) {
  const state = useToggleState(props)
  const ref = useRef<HTMLInputElement>(null)
  const { inputProps } = useSwitch(
    { 'aria-label': props.children?.toString(), ...props },
    state,
    ref
  )
  const { isFocusVisible, focusProps } = useFocusRing()

  return (
    <Field {...props} size={size} isLabelHidden>
      <label className={switchClasses({ size })}>
        <input
          {...mergeProps(inputProps, focusProps)}
          ref={ref}
          className={styles.input}
          data-focus-visible={isFocusVisible || undefined}
        />
        <span className={styles.switch} />
        <div className={styles.label}>{props.children}</div>
      </label>
    </Field>
  )
}

export default Switch
