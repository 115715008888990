import { Select } from '@hubtype/ui-react-web'

import { TrackEventName, useAnalytics } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { PopupType } from '../../../types'
import { TextBodySmall } from '../../base'
import { headerDropdownProps } from '../header'

const intentModelsSettingOption = {
  id: 'intent-models',
  name: 'Intent models',
}
const knowledgeBaseSettingOption = {
  id: 'knowledge-base',
  name: 'Knowledge base',
}

export const AiSettingsDropdown = () => {
  const analytics = useAnalytics()
  const setPopupContent = useFlowBuilderSelector(ctx => ctx.setPopupContent)
  const state = useFlowBuilderSelector(ctx => ctx.state)

  const aiSettingsOptions = [
    intentModelsSettingOption,
    knowledgeBaseSettingOption,
  ]

  const onSelectAiSetting = (aiSettingSelectedId?: string) => {
    if (!aiSettingSelectedId) return
    if (aiSettingSelectedId === intentModelsSettingOption.id) {
      setPopupContent({ type: PopupType.INTENT_MODELS })
    } else if (aiSettingSelectedId === knowledgeBaseSettingOption.id) {
      setPopupContent({ type: PopupType.KNOWLEDGE_BASE })
    }
    trackAiSetting(aiSettingSelectedId)
  }

  const trackAiSetting = (aiSettingSelectedId: string) => {
    const action =
      aiSettingSelectedId === intentModelsSettingOption.id
        ? 'Intent model'
        : 'Knowledge base'

    analytics.trackEvent(TrackEventName.AI_SETTINGS_OPEN, {
      action,
      active_intent_model: Boolean(state.currentAiModel),
      active_knowledge_base: state.isKnowledgeBaseActive,
    })
  }

  return (
    <Select
      {...headerDropdownProps}
      items={aiSettingsOptions}
      label='AI Settings'
      preserveButtonLabel={true}
      onChange={value => onSelectAiSetting(value?.id)}
    >
      {item => <TextBodySmall>{item.name}</TextBodySmall>}
    </Select>
  )
}
