import { createContext, useContext } from 'react'
import { OverlayTriggerState } from 'react-stately'

export const ModalTriggerStateContext =
  createContext<OverlayTriggerState | null>(null)

export const useModalTriggerContext = () => {
  const result = useContext(ModalTriggerStateContext)
  if (!result) {
    throw new Error('ModalTriggerStateContext used outside of its Provider')
  }
  return result
}
