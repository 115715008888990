import cloneDeep from 'lodash.clonedeep'
import { useEffect, useState } from 'react'

import { FeedbackMessage, FeedbackType } from '../../../domain/feedback'
import {
  ContentType,
  TopContentFieldsBase,
} from '../../../domain/models/content-fields'
import { HubtypeService } from '../../../repository/hubtype/hubtype-service'
import { TrackEventName, useAnalytics } from '../../analytics'
import {
  AI_CONTENT_ID_GENERATION_MESSAGE,
  MISSING_CONTENT_ID_MESSAGE,
} from '../../constants'
import { useFeedbackMessage } from '../../custom-hooks/use-feedback-message'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { NodeTypes, TextNode, WhatsappButtonListNode } from '../../types'
import { HtAiNotificationWithActions } from '../base'

const nodeTypesWithRandomId = [
  ContentType.IMAGE,
  ContentType.CAROUSEL,
  ContentType.VIDEO,
  ContentType.HANDOFF,
  ContentType.BOT_ACTION,
  ContentType.KNOWLEDGE_BASE,
  ContentType.CUSTOM_CONDITION,
  ContentType.QUEUE_STATUS,
  ContentType.CHANNEL,
  ContentType.COUNTRY_CONDITION,
] as ContentType[]

const nodeTypesWithAiId = [
  ContentType.TEXT,
  ContentType.WHATSAPP_BUTTON_LIST,
  ContentType.WHATSAPP_CTA_URL_BUTTON,
] as ContentType[]

const nodeTypesWithRequiredContentId = [
  ...nodeTypesWithRandomId,
  ...nodeTypesWithAiId,
]

export interface AiGeneratedContentId {
  nodeId: string
  newContentId?: string
}

enum AiNotificationStatus {
  LOADING = 'LOADING',
  ASK = 'ASK',
}

export const AiNotificationsPanel = (): JSX.Element => {
  const analytics = useAnalytics()
  const { reportSuccess, reportError } = useFeedbackMessage()
  const { state, updateAllNodes, removeFeedbackMessages } =
    useFlowBuilderSelector(ctx => ctx)
  const [aiNotificationStatus, setAiNotificationStatus] = useState(
    AiNotificationStatus.ASK
  )
  const [aiGeneratedIds, setAiGeneratedIds] = useState<AiGeneratedContentId[]>()
  const [aiFeedbackMessage, setAiFeedbackMessage] = useState<FeedbackMessage>()

  useEffect(() => {
    const aiCustomFeedbackMessage = state.feedback.find(
      feedback =>
        feedback.type === FeedbackType.CUSTOM &&
        feedback.message === AI_CONTENT_ID_GENERATION_MESSAGE
    )
    setAiFeedbackMessage(aiCustomFeedbackMessage)
    if (aiCustomFeedbackMessage) {
      setAiNotificationStatus(AiNotificationStatus.ASK)
    }
  }, [state.feedback])

  useEffect(() => updateContentIds(), [aiGeneratedIds])

  const updateContentIds = () => {
    if (!aiGeneratedIds) return
    const newNodes = cloneDeep(state.nodes)
    newNodes.forEach(node => {
      if (!node.data.code) {
        if (nodeTypesWithAiId.includes(node.type)) {
          setAiContentId(node.data)
        } else if (nodeTypesWithRandomId.includes(node.type)) {
          node.data.setRandomContentId()
        }
      }
      return node
    })
    updateNotification(newNodes)
    updateAllNodes(newNodes)
  }

  const setAiContentId = (data: TopContentFieldsBase) => {
    const newCode = aiGeneratedIds?.find(
      response => response.nodeId === data.id
    )?.newContentId
    if (newCode) {
      data.code = newCode
      data.isCodeAiGenerated = true
    }
  }

  const updateNotification = (newNodes: NodeTypes[]) => {
    const hasFailed = aiGeneratedIds?.some(
      response => response.newContentId === undefined
    )
    const feedbackMessagesToRemove = state.feedback.filter(
      notification =>
        notification.message.includes(MISSING_CONTENT_ID_MESSAGE) &&
        newNodes.some(
          node => node.id === notification.details?.nodeId && node.data.code
        )
    )
    reportContentIdStatus(hasFailed)
    if (feedbackMessagesToRemove.length) {
      removeFeedbackMessages(feedbackMessagesToRemove)
    }
    if (hasFailed) {
      setAiNotificationStatus(AiNotificationStatus.ASK)
    } else {
      removeAiFeedbackMessage()
    }
    setAiGeneratedIds(undefined)
  }

  const reportContentIdStatus = (hasFailed?: boolean) => {
    const options = { isAiNotification: true }
    if (hasFailed) {
      reportError('Failed to generate content IDs', options, true)
    } else {
      reportSuccess('Content IDs generated', options)
    }
  }

  const generateContentId = async () => {
    setAiNotificationStatus(AiNotificationStatus.LOADING)
    const newAiGeneratedIds = await generateContentIdWithAi()
    if (!newAiGeneratedIds) return
    setAiGeneratedIds(newAiGeneratedIds)
    analytics.trackEvent(
      TrackEventName.CONTENTID_AI_GENERATION,
      getEventProperties(newAiGeneratedIds)
    )
  }

  const generateContentIdWithAi = async (): Promise<
    AiGeneratedContentId[] | undefined
  > => {
    if (!state.authToken) return undefined
    const nodesWithoutContentId = state.nodes.filter(
      node =>
        !node.data.code &&
        (node.type === ContentType.TEXT ||
          node.type === ContentType.WHATSAPP_BUTTON_LIST ||
          node.type === ContentType.WHATSAPP_CTA_URL_BUTTON)
    ) as (TextNode | WhatsappButtonListNode)[]
    return await Promise.all(
      nodesWithoutContentId.map(async node => {
        const newContentId = await HubtypeService.generateContentIdWithAi(
          state.authToken,
          node.data.text
        )
        return { nodeId: node.id, newContentId }
      })
    )
  }

  const getEventProperties = (newAiGeneratedIds: AiGeneratedContentId[]) => {
    const failedCount = newAiGeneratedIds.filter(
      response => response.newContentId === undefined
    ).length
    return {
      action: 'generate',
      is_success: failedCount === 0,
      number_of_failed_ai_generated_content_ids: failedCount,
      number_of_ai_generated_content_ids:
        newAiGeneratedIds?.length - failedCount,
      number_of_generated_content_ids: state.nodes.filter(node =>
        isNodeMissingContentId(node)
      ).length,
    }
  }

  const dismiss = () => {
    analytics.trackEvent(TrackEventName.CONTENTID_AI_GENERATION, {
      action: 'dismiss',
    })
    removeAiFeedbackMessage()
  }

  const removeAiFeedbackMessage = () => {
    if (aiFeedbackMessage) removeFeedbackMessages([aiFeedbackMessage])
  }

  const isNodeMissingContentId = (node: NodeTypes) =>
    !node.data.code && nodeTypesWithRequiredContentId.includes(node.type)

  return (
    <div>
      {aiFeedbackMessage && (
        <HtAiNotificationWithActions
          confirmButton={{
            text:
              aiNotificationStatus === AiNotificationStatus.LOADING
                ? 'Generating'
                : 'Generate content IDs',
            icon: 'wand-magic-sparkles',
            onClick: generateContentId,
          }}
          discardButton={{ text: 'Dismiss', onClick: dismiss }}
          isLoading={aiNotificationStatus === AiNotificationStatus.LOADING}
        >
          {AI_CONTENT_ID_GENERATION_MESSAGE}
        </HtAiNotificationWithActions>
      )}
    </div>
  )
}
