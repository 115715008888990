import { Icon } from '@hubtype/ui-react-web'
import { memo } from 'react'

import { HTTP_URL_REGEX } from '../../constants'
import { VideoNodeProps } from '../../types'
import { Handles, NodeHeader } from './node-components'
import { MessageNode, StyledVideo } from './node-styles'

const VideoNode = ({ data, selected }: VideoNodeProps) => {
  function isVideoValid(): boolean {
    if (!data.videoUrl) return false
    return HTTP_URL_REGEX.test(data.videoUrl)
  }
  return (
    <MessageNode $isSelected={selected}>
      <Handles hasFollowUp={true} contentId={data.getContentId()} />
      <NodeHeader data={data} title={'Video'} icon='film' />
      {isVideoValid() && (
        <StyledVideo $isSelected={selected}>
          <Icon icon='film' size='medium' />
          <span>{data.videoUrl}</span>
        </StyledVideo>
      )}
    </MessageNode>
  )
}

export default memo(VideoNode)
