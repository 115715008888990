import { Banner, Icon, Select, Tooltip } from '@hubtype/ui-react-web'
import { Controller, UseFormReturn } from 'react-hook-form'

import { ConversationalApp } from '../../../../../../domain/models/organization-models'
import { Popup } from '../../../../popup/popup'
import { TransferDetails } from '../transfer-flows-modal'
import {
  StyledWaringButton,
  TransferDisabledMessage,
} from '../transfer-flows-styles'

export interface TransferBot extends ConversationalApp {
  isBeingEdited: boolean
  hasUnpublishedChanges: boolean
}

interface SelectBotStepProps {
  form: UseFormReturn<TransferDetails>
  bots: TransferBot[]
  isLoading: boolean
  cancel: () => void
  goNext: () => void
}

export const SelectBotStep = ({
  form,
  bots,
  isLoading,
  cancel,
  goNext,
}: SelectBotStepProps): JSX.Element => {
  return (
    <Popup
      title='Transfer flows'
      confirmButton={{
        text: 'Next',
        disabled: !form.formState.isValid,
        onClick: goNext,
      }}
      discardButton={{ text: 'Cancel', onClick: cancel }}
      canCloseOnClickOutside={false}
    >
      <form id='select-bot-form'>
        <Controller
          name='targetBot'
          control={form.control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              label='Transfer to'
              placeholder='Select a bot'
              isLoading={isLoading}
              items={bots}
              selectedKey={field.value?.id}
              disabledKeys={bots
                .filter(cApp => cApp.isBeingEdited)
                .map(cApp => cApp.id)}
            >
              {item => (
                <TransferDisabledMessage>
                  {item.name}
                  {item.isBeingEdited && <span>Currently being edited</span>}
                  {!item.isBeingEdited && item.hasUnpublishedChanges && (
                    <Tooltip
                      content='This bot has unpublished updates that will be lost if its flows are replaced.'
                      placement='right'
                    >
                      <StyledWaringButton>
                        <Icon
                          icon='exclamation-triangle'
                          size='small'
                          prefix='fad'
                        />
                      </StyledWaringButton>
                    </Tooltip>
                  )}
                </TransferDisabledMessage>
              )}
            </Select>
          )}
        />
      </form>
      <Banner
        intent='info'
        title='About transfer flow'
        description='The published flow is the one that will be transferred; any unpublished updates will not be included. Once transferred, the flow must be published within the destination bot to replace its current published version.'
      />
    </Popup>
  )
}
