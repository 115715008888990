import { createContext, useContext } from 'react'
import { RadioGroupState } from 'react-stately'

export const RadioGroupStateContext = createContext<RadioGroupState | null>(
  null
)

export const useRadioGroupStateContext = () => {
  const result = useContext(RadioGroupStateContext)
  if (!result) {
    throw new Error('useRadioGroupStateContext used outside of its Provider')
  }
  return result
}
