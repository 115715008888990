import { memo } from 'react'

import {
  ContentType,
  WhatsappButtonListFields,
  WhatsappButtonListSectionFields,
} from '../../../../../domain/models/content-fields'
import { flatten } from '../../../../../utils/array-utils'
import { MAX_SECTION_TITLE_LENGTH } from '../../../../constants'
import { TextBodySmallBold } from '../../../base'
import { ActionType, SECTION_TITLE } from '../../constants'
import { EditorFields } from '../../editor-styles'
import { DroppableWidget } from '../../editor-widgets/sortable-widget/droppable-widget'
import { ContentTextField } from '../../editor-widgets/text-input-widgets'
import { EditorProps } from '../../node-editor-panel'
import { RowEditor } from './row-editor'

interface SectionEditorProps extends EditorProps {
  node: WhatsappButtonListFields
  selectedSubContentId?: string
  section: WhatsappButtonListSectionFields
  index: number
}

export const SectionEditor = memo(
  ({
    node,
    section,
    index,
    selectedSubContentId,
    ...props
  }: SectionEditorProps): JSX.Element => {
    const isSelected = selectedSubContentId === section.id

    const hasDuplicatedTitleError = (): boolean => {
      return node.sections.some(
        s => section.title && s.title === section.title && s.id !== section.id
      )
    }

    const isRowRemovable = (): boolean => {
      return flatten(node.sections.map(s => s.rows)).length > 1
    }

    return (
      <>
        {isSelected && (
          <EditorFields>
            <ContentTextField
              {...props}
              id={section.id}
              field={SECTION_TITLE}
              value={section.title}
              hasEmojiPicker={true}
              maxLength={MAX_SECTION_TITLE_LENGTH}
              changeProps={{ sectionId: section.id }}
              isInvalid={hasDuplicatedTitleError()}
            />
          </EditorFields>
        )}
        <DroppableWidget
          {...props}
          contentType={ContentType.ROW}
          droppableId={section.id}
          sortableContents={section.rows}
          selectedContentId={selectedSubContentId}
          isSelectable={true}
          isSubSection={true}
          isRemovable={isRowRemovable()}
          onRemove={row => {
            props.onChange({
              rowToDelete: row,
              type: ActionType.DELETE_ROW,
            })
          }}
          header={row => (
            <TextBodySmallBold>{row.title || 'Row title'}</TextBodySmallBold>
          )}
        >
          {(row, i) => (
            <RowEditor
              {...props}
              key={i}
              node={node}
              row={row}
              parentSection={section}
              isSelected={selectedSubContentId === row.id}
            />
          )}
        </DroppableWidget>
      </>
    )
  }
)
