import cloneDeep from 'lodash.clonedeep'

import { HtContentType } from '../cms/hubtype/node-types'
import { HtUrlNode } from '../cms/hubtype/url'
import { ContentFieldsBase, ContentType, NonMessageContentType } from '.'

export class UrlFields extends ContentFieldsBase {
  constructor(public name = '') {
    super()
  }

  contentType(): NonMessageContentType {
    return ContentType.URL
  }

  static fromHubtypeCMS(component: HtUrlNode): UrlFields {
    const newUrl = new UrlFields()
    newUrl.id = component.id
    newUrl.name = component.content.url
    return newUrl
  }

  toHubtypeCMS(): HtUrlNode {
    const node: HtUrlNode = {
      id: this.id,
      type: HtContentType.URL,
      content: {
        url: this.name,
      },
    }
    return node
  }

  static getCopy(content: UrlFields): UrlFields {
    const newUrl = new UrlFields()
    Object.assign(newUrl, content)
    return newUrl
  }

  cloneWithUrl = (url: string): UrlFields => {
    const clone = cloneDeep(this)
    clone.name = url
    return clone
  }
}
