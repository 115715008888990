import { Button } from '@hubtype/ui-react-web'

import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { useCloseFromHubtype } from './use-close-from-hubtype'
import { useExit } from './use-exit'

export const ExitButton = (): JSX.Element => {
  const { exitFlow } = useExit()
  const { state } = useFlowBuilderSelector(ctx => ctx)

  useCloseFromHubtype(state.isFlowSaved, exitFlow)

  const onExit = () => {
    exitFlow()
  }

  return (
    <Button
      intent='secondary'
      size='small'
      appearance='outline'
      onPress={onExit}
    >
      Exit
    </Button>
  )
}
