import { TextInput } from '@hubtype/ui-react-web'
import { useEffect, useRef, useState } from 'react'
import { v7 as uuidv7 } from 'uuid'

import { normalizeValue } from '../../../../../utils/string-utils'
import { WEBVIEW_PREFIX } from '../../../../../webviews/utils'
import { TrackEventName, useAnalytics } from '../../../../analytics'
import { MAX_FLOW_NAME_LENGTH } from '../../../../constants'
import { useFlowBuilderSelector } from '../../../../reducer/hooks'
import { PopupType } from '../../../../types'
import { ViewportAnimator } from '../../../../utils/viewport-animator'
import { Popup } from '../../popup'

export const AddFlowPopup = (): JSX.Element => {
  const analytics = useAnalytics()
  const inputRef = useRef<HTMLInputElement>(null)
  const { state, setPopupContent, addFlow } = useFlowBuilderSelector(ctx => ctx)
  const [flowName, setFlowName] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => inputRef.current?.focus(), [])

  const onAddFlow = async (): Promise<void> => {
    analytics.trackEvent(TrackEventName.FLOW_ADD, {
      has_added: true,
      flow_name: flowName,
      number_of_flows: state.flows.length + 1,
    })
    setPopupContent()
    const newFlow = {
      name: flowName,
      id: flowName.includes(WEBVIEW_PREFIX)
        ? `${WEBVIEW_PREFIX}${uuidv7()}`
        : uuidv7(),
    }
    addFlow(newFlow)
    ViewportAnimator.centerStartNode(state)
  }

  const cancel = () => {
    analytics.trackEvent(TrackEventName.FLOW_ADD, { has_added: false })
    setPopupContent()
  }

  const onNameChanges = (value: string) => {
    setFlowName(value)
    if (value.length > 30) {
      setError('Flow title is too long')
    } else if (
      state.flows.some(
        flow => normalizeValue(flow.name) === normalizeValue(value)
      )
    ) {
      setError('Flow title already exists')
    } else {
      setError(undefined)
    }
  }

  return (
    <Popup
      title={PopupType.ADD_FLOW}
      confirmButton={{
        text: 'Add',
        onClick: onAddFlow,
        disabled: !flowName || !!error,
      }}
      cancel={cancel}
    >
      <TextInput
        ref={inputRef}
        label='Flow title'
        onChange={onNameChanges}
        value={flowName}
        maxLength={MAX_FLOW_NAME_LENGTH}
        isInvalid={!!error}
        errorMessage={error}
      />
    </Popup>
  )
}
