import { ReactNode } from 'react'
import { Orientation, useRadioGroup } from 'react-aria'
import { useRadioGroupState } from 'react-stately'

import { RadioGroupStateContext } from './context'

export interface RadioGroupProps {
  /** The label of the radio group. */
  label: string
  /** The children of the radio group. */
  children: ReactNode
  /** The current value (controlled). */
  value?: string
  /** The default value (uncontrolled). */
  defaultValue?: string
  /** Whether the radio group is disabled. */
  isDisabled?: boolean
  /** The axis the Radio Button(s) should align with. */
  orientation?: Orientation
  /** Handler that is called when the value changes. */
  onChange?: (value: string) => void
}
export function RadioGroup(props: RadioGroupProps) {
  const state = useRadioGroupState(props)
  const { radioGroupProps } = useRadioGroup(props, state)

  return (
    <div {...radioGroupProps}>
      <RadioGroupStateContext.Provider value={state}>
        {props.children}
      </RadioGroupStateContext.Provider>
    </div>
  )
}
