import { Checkbox } from '@hubtype/ui-react-web'
import { memo } from 'react'

import { InputAction } from '../constants'

interface FieldsCheckboxProps {
  checked: boolean
  field: InputAction
  readOnly?: boolean
  onChange: (action: any) => void
}

export const FieldsCheckbox = memo((props: FieldsCheckboxProps) => {
  return (
    <Checkbox
      isSelected={props.checked}
      label={props.field.label}
      description={props.field.helperText}
      isReadOnly={props.readOnly}
      onChange={value =>
        props.onChange({
          type: props.field.actionType,
          fieldKey: props.field.key,
          value,
        })
      }
    />
  )
})
