import { Button, Tooltip } from '@hubtype/ui-react-web'

import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { PopupType } from '../../../types'

export const TransferFlowsButton = (): JSX.Element => {
  const { state, setPopupContent } = useFlowBuilderSelector(ctx => ctx)

  const openTransferFlowModal = () => {
    setPopupContent({ type: PopupType.TRANSFER_FLOWS })
  }

  const hasOtherBots = state.organizationContents.conversationalApps.some(
    bot =>
      bot.id !== state.organizationContents.currentConversationalApp.id &&
      bot.flowBuilderSettings.cmsType === 'flow-builder-backend'
  )

  return (
    <Tooltip
      content='There are no other bots to transfer the flows to'
      isDisabled={hasOtherBots}
    >
      <Button
        intent='secondary'
        size='small'
        appearance='outline'
        onPress={openTransferFlowModal}
        isDisabled={!hasOtherBots}
      >
        Transfer flows
      </Button>
    </Tooltip>
  )
}
