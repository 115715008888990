import { memo } from 'react'

import {
  WhatsappButtonListFields,
  WhatsappButtonListRowFields,
  WhatsappButtonListSectionFields,
} from '../../../../../domain/models/content-fields'
import {
  MAX_ROW_DESCRIPTION_LENGTH,
  MAX_ROW_TITLE_LENGTH,
} from '../../../../constants'
import { ROW_DESCRIPTION, ROW_TITLE } from '../../constants'
import { EditorFields } from '../../editor-styles'
import { ContentTextField } from '../../editor-widgets/text-input-widgets'
import { EditorProps } from '../../node-editor-panel'

interface RowEditorProps extends EditorProps {
  node: WhatsappButtonListFields
  row: WhatsappButtonListRowFields
  parentSection: WhatsappButtonListSectionFields
  isSelected: boolean
}

export const RowEditor = memo(
  ({
    node,
    row,
    parentSection,
    isSelected,
    ...props
  }: RowEditorProps): React.ReactNode => {
    const hasDuplicatedTextError = (): boolean => {
      return parentSection.rows.some(
        r => row.title && r.title === row.title && r.id !== row.id
      )
    }

    if (!isSelected) return null

    return (
      <EditorFields $isSubSection>
        <ContentTextField
          {...props}
          id={row.id}
          field={ROW_TITLE}
          value={row.title}
          hasEmojiPicker={true}
          maxLength={MAX_ROW_TITLE_LENGTH}
          changeProps={{ rowId: row.id }}
          isInvalid={hasDuplicatedTextError()}
        />
        <ContentTextField
          {...props}
          id={row.id}
          field={ROW_DESCRIPTION}
          value={row.description}
          hasEmojiPicker={true}
          multiline={true}
          defaultRows={3}
          maxLength={MAX_ROW_DESCRIPTION_LENGTH}
          changeProps={{ rowId: row.id }}
        />
      </EditorFields>
    )
  }
)
