import { KeyCodes } from '@hubtype/util-shared'
import { useCallback, useEffect, useRef, useState } from 'react'

export function usePrevious<Type>(value: Type): Type | null {
  const ref = useRef<Type | null>(null) as React.MutableRefObject<Type | null>
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export function useKeyboardButtonPressedFunc(
  onClick: (evt: React.KeyboardEvent) => void,
  keys: KeyCodes[]
) {
  return useCallback(
    (evt: React.KeyboardEvent) => {
      if (keys.includes(evt.key as KeyCodes)) {
        onClick(evt)
      }
    },
    [onClick]
  )
}

export function useDebounce<T>(value: T, delay = 500): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay)
    return () => clearTimeout(timer)
  }, [value, delay])

  return debouncedValue
}

export function useFirstRender() {
  const firstRender = useRef(true)

  useEffect(() => {
    firstRender.current = false
  }, [])

  return firstRender.current
}
