import cloneDeep from 'lodash.clonedeep'
import { Edge } from 'reactflow'

import { IdMapping, NonMessageContents } from '../../../UI/types'
import { HtCarouselElement } from '../cms/hubtype/carousel'
import { HtMediaFileLocale } from '../cms/hubtype/common'
import { LocaleCode } from '../locales/code'
import { LocaleManager } from '../utils/locale-manager'
import {
  AssetFields,
  ButtonFields,
  ContentType,
  SubContentType,
  TopContentFieldsBase,
} from '.'
import { AssetLocale, TextLocale } from './common'

export class ElementFields extends TopContentFieldsBase {
  public image?: AssetFields
  constructor(
    public title = '',
    public subtitle = '',
    public buttons = [new ButtonFields()], // TODO: This can only be 1 button right? Why plural?
    public titleLocales: TextLocale[] = [],
    public subtitleLocales: TextLocale[] = [],
    public imageLocales: AssetLocale[] = []
  ) {
    super()
  }

  contentType(): SubContentType {
    return ContentType.ELEMENT
  }

  stringContentType(): string {
    return 'element'
  }

  setValuesByLocale(
    locale: string,
    nonMessageContents: NonMessageContents
  ): void {
    this.title = LocaleManager.getTextByLocale(this.titleLocales, locale)
    this.subtitle = LocaleManager.getTextByLocale(this.subtitleLocales, locale)
    this.image = LocaleManager.getImageByLocale(this.imageLocales, locale)
    this.buttons.forEach(b => b.setValuesByLocale(locale, nonMessageContents))
  }

  setContentByLocale(locale: LocaleCode): void {
    this.titleLocales = LocaleManager.setTextByLocale(
      this.titleLocales,
      this.title,
      locale
    )
    this.subtitleLocales = LocaleManager.setTextByLocale(
      this.subtitleLocales,
      this.subtitle,
      locale
    )
    this.imageLocales = LocaleManager.setImageByLocale(
      this.imageLocales,
      this.image,
      locale
    )
    this.buttons.forEach(button => button.setContentByLocale(locale))
  }

  addLocale(newLocale: LocaleCode, copyFrom: LocaleCode): void {
    LocaleManager.addLocale(this.titleLocales, newLocale, copyFrom)
    LocaleManager.addLocale(this.subtitleLocales, newLocale, copyFrom)
    LocaleManager.addLocale(this.imageLocales, newLocale, copyFrom)
    this.buttons.forEach(button => button.addLocale(newLocale, copyFrom))
  }

  removeLocales(localesToRemove: LocaleCode[]): void {
    LocaleManager.removeLocales(this.titleLocales, localesToRemove)
    LocaleManager.removeLocales(this.subtitleLocales, localesToRemove)
    LocaleManager.removeLocales(this.imageLocales, localesToRemove)
    this.buttons.forEach(button => button.removeLocales(localesToRemove))
  }

  static fromHubtypeCMS(
    component: HtCarouselElement,
    locale: string,
    nonMessageContents: NonMessageContents
  ): ElementFields {
    const newElement = new ElementFields()
    newElement.id = component.id
    newElement.titleLocales = component.title.map(
      titleLocale => titleLocale as TextLocale
    )
    newElement.subtitleLocales = component.subtitle.map(
      subtitleLocale => subtitleLocale as TextLocale
    )
    newElement.imageLocales = component.image.map(
      htMediaFileLocale => htMediaFileLocale as AssetLocale
    )
    newElement.setValuesByLocale(locale, nonMessageContents)
    return newElement
  }

  toHubtypeCMS(locale: LocaleCode): HtCarouselElement {
    this.setContentByLocale(locale)
    const node: HtCarouselElement = {
      id: this.id,
      title: this.titleLocales,
      subtitle: this.subtitleLocales,
      image: this.imageLocales.map(
        assetLocale => assetLocale as HtMediaFileLocale
      ),
      button: this.buttons[0].toHubtypeCMS(locale),
    }
    return node
  }

  getErrors(parentCode: string, edges: Edge[]): string[] {
    const requiredFields = []
    if (!this.title) requiredFields.push('title')
    if (!this.image) requiredFields.push('image')
    const fieldErrors = requiredFields.map(
      field =>
        `Error in element inside content with id "${parentCode}": field ${field} is required.`
    )
    const buttonsErrors = this.buttons.flatMap(button =>
      button.getErrors(parentCode, edges)
    )
    return [...buttonsErrors, ...fieldErrors]
  }

  getLocalesWithErrors(locales: LocaleCode[]): string[] {
    const buttonErrors = this.buttons.flatMap(button =>
      button.getLocalesWithErrors(locales)
    )
    const imageErrors = LocaleManager.getLocalesWithEmptyAsset(
      this.imageLocales,
      locales
    )
    return [...buttonErrors, ...imageErrors]
  }

  getErrorMessageId(parentCode: string, elementTitle: string): string {
    if (elementTitle) return ` with title "${elementTitle}"`
    return `inside content with id "${parentCode}"`
  }

  static cloneWithAsset(
    element: ElementFields,
    asset?: AssetFields
  ): ElementFields {
    const clone: ElementFields = cloneDeep(element)
    clone.image = asset
    return clone
  }

  hasString(value: string): boolean {
    if (this.fieldsIncludeString([this.title, this.subtitle], value)) {
      return true
    }
    const includesString = this.buttons.map(b => b.hasString(value))
    return includesString.indexOf(true) !== -1
  }

  getConnections(edges: Edge[]): (Edge | undefined)[] {
    return this.buttons.map(b => b.getConnections(edges))
  }

  static getCopy(content: ElementFields): ElementFields {
    const newElement = new ElementFields()
    TopContentFieldsBase.copyContent(content, newElement)
    newElement.buttons = content.buttons.map(button =>
      ButtonFields.getCopy(button)
    )
    if (content.image) {
      newElement.image = AssetFields.getCopy(content.image)
    }
    return newElement
  }

  getIdMappingForOldFields(oldElement: ElementFields): IdMapping {
    return this.buttons.reduce((idMapping: IdMapping, button, i) => {
      idMapping[oldElement.buttons[i].id] = button.id
      return idMapping
    }, {})
  }
}
