export const getFileType = (fileName?: string | null): string | undefined => {
  if (!fileName) return undefined
  return fileName.split('.').pop()?.toLowerCase()
}

export const getIconPropsFromFileType = <T>(fileType?: string): T => {
  const fileTypeToIconMap = new Map<string, string>([
    ['pdf', 'file-pdf'],
    ['doc', 'file-word'],
    ['docx', 'file-word'],
    ['xls', 'file-excel'],
    ['xlsx', 'file-excel'],
    ['csv', 'file-csv'],
    ['url', 'link'],
  ])
  const icon = (fileType && fileTypeToIconMap.get(fileType)) || 'file'
  const prefix = icon === 'link' ? 'fas' : 'far'
  return { icon, prefix } as T
}

export const getIconPropsFromFileName = <T>(fileName?: string): T => {
  const fileType = getFileType(fileName)
  return getIconPropsFromFileType(fileType)
}
