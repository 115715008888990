import { TrackEventName, useAnalytics } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { PopupType } from '../../../types'
import { useRestoreVersion } from '../../version-banner/use-restore-version'
import { Popup } from '../popup'

export const RestoreVersionPopup = (): JSX.Element => {
  const analytics = useAnalytics()
  const restoreVersion = useRestoreVersion()
  const setPopupContent = useFlowBuilderSelector(ctx => ctx.setPopupContent)

  const restorePreviousVersion = (): void => {
    analytics.trackEvent(TrackEventName.VERSION_CLICK_RESTORE_CONFIRMATION_CTA)
    setPopupContent()
    restoreVersion()
  }

  const cancel = async (): Promise<void> => {
    await analytics.trackEvent(TrackEventName.VERSION_CLICK_CANCEL_CTA)
  }

  return (
    <Popup
      title={PopupType.RESTORE_VERSION}
      confirmButton={{
        text: 'Restore version',
        intent: 'danger',
        onClick: restorePreviousVersion,
      }}
      cancel={cancel}
      message="You're about to restore this conversational app to an older version. All changes made from there will be lost. Once loaded you can publish again the restored version."
    />
  )
}
