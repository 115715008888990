import { BotConfig } from '../../domain/models/bot-config-models'
import { HtFlowBuilderData } from '../../domain/models/cms/hubtype/flow-builder-data'
import { QueueFields } from '../../domain/models/content-fields'
import {
  ConversationalApp,
  ConversationalAppProviderAccount,
  HubtypeUser,
  KnowledgeSourceResponse,
  KnowledgeSourceType,
  OrganizationAiIntent,
  OrganizationAiModel,
  OrganizationKnowledgeBase,
  OrganizationKnowledgeSource,
  OrganizationProject,
  OrganizationQueue,
} from '../../domain/models/organization-models'
import { OrganizationContents } from '../../UI/types'
import { HubtypeApi } from './hubtype-api'

export class HubtypeService {
  static async getUserData(
    authToken: string,
    userId: string
  ): Promise<HubtypeUser | undefined> {
    const user = await HubtypeApi.get(authToken, `users/${userId}/`, 'v2')
    if (!user) return undefined
    return new HubtypeUser(
      user.id,
      user.email,
      user.organization_id,
      user.role,
      user.username,
      user.first_name,
      user.last_name,
      user.pic
    )
  }

  static async getConversationalApps(
    authToken: string
  ): Promise<ConversationalApp[]> {
    const data = await HubtypeApi.get(
      authToken,
      `bots/?include_test_providers=${true}`,
      'v1',
      { page_size: 100 }
    )
    if (!data) return []
    return data.results.map(
      (botData: any) =>
        new ConversationalApp(
          botData.id,
          botData.name,
          botData.provider_accounts.map((provider: any) => {
            return new ConversationalAppProviderAccount(
              provider.id,
              provider.provider,
              provider.name,
              provider.is_test,
              provider.is_active,
              provider.phone_number,
              provider.netlify_url
            )
          }),
          botData.flow_builder_settings
        )
    )
  }

  static async getOrganizationAiIntents(
    authToken: string,
    modelId: string
  ): Promise<OrganizationAiIntent[]> {
    const data = await HubtypeApi.get(
      authToken,
      `babel/projects/${modelId}/intents/`,
      'v1',
      { page_size: 100 }
    )
    if (!data) return []
    return data.results.map((intent: any) => ({
      id: intent.id,
      name: intent.name,
    }))
  }

  static async getOrganizationContents(
    authToken: string,
    botId: string
  ): Promise<OrganizationContents> {
    const projects = await this.getOrganizationProjects(authToken)
    const aiModels = await this.getOrganizationAiModels(authToken)
    const featureFlags = await this.getOrganizationFeatureFlags(authToken)
    const knowledgeSources =
      await this.getOrganizationKnowledgeSources(authToken)
    const conversationalApps = await this.getConversationalApps(authToken)
    const currentConversationalApp = conversationalApps.find(
      cApps => cApps.id === botId
    )!
    return {
      projects,
      aiModels,
      featureFlags,
      knowledgeSources,
      currentConversationalApp,
      conversationalApps,
    }
  }

  private static async getOrganizationFeatureFlags(
    authToken: string
  ): Promise<Record<string, boolean>> {
    const data = await HubtypeApi.get(authToken, 'my-organization/')
    if (!data) return {}
    return data.feature_flags
  }

  private static async getOrganizationProjects(
    authToken: string
  ): Promise<OrganizationProject[]> {
    const data = await HubtypeApi.get(
      authToken,
      'projects/?page=1&page_size=100',
      'v2'
    )
    if (!data) return []
    return data.results.map((project: any) => ({
      id: project.id,
      name: project.name,
      queues: project.queues.map((queue: OrganizationQueue) =>
        QueueFields.fromHubtype({ ...queue, project: project.name })
      ),
    }))
  }

  private static async getOrganizationAiModels(
    authToken: string
  ): Promise<OrganizationAiModel[]> {
    const data = await HubtypeApi.get(authToken, 'babel/projects/')
    if (!data) return []
    return data.results.map((model: any) => ({
      id: model.id,
      name: model.name,
    }))
  }

  private static async getOrganizationKnowledgeSources(
    authToken: string
  ): Promise<OrganizationKnowledgeSource[]> {
    const mainKnowledgeBase: OrganizationKnowledgeBase = await HubtypeApi.get(
      authToken,
      `ai/knowledge_bases/main/`
    )
    if (!mainKnowledgeBase) return []
    return this.getNewKnowledgeSources(authToken, mainKnowledgeBase.id)
  }

  private static async getNewKnowledgeSources(
    authToken: string,
    knowledgeBaseId: string
  ): Promise<OrganizationKnowledgeSource[]> {
    const responseData = await HubtypeApi.get(
      authToken,
      `ai/knowledge_bases/${knowledgeBaseId}/sources/`,
      'v1',
      { page_size: 100 }
    )
    const knowledgeSources = responseData.results as KnowledgeSourceResponse[]
    const data = knowledgeSources
      .filter(source => source.active_extraction_job)
      .map(source => {
        const fileType =
          source.type === KnowledgeSourceType.URL
            ? KnowledgeSourceType.URL
            : source.active_extraction_job?.file_name?.split('.').pop()

        return {
          id: source.id,
          name: source.name,
          fileType,
        }
      })
      .sort((a, b) => a.name.localeCompare(b.name))
    if (!data) return []
    return data
  }

  static async getBotConfig(
    authToken: string,
    botId: string
  ): Promise<BotConfig | undefined> {
    const data = await HubtypeApi.get(authToken, `bots/${botId}/config/`, 'v2')
    if (!data) return undefined
    return data
  }

  static async generateContentIdWithAi(
    authToken: string,
    text: string
  ): Promise<string | undefined> {
    try {
      const response = await HubtypeApi.post(
        authToken,
        'flow_builder/ai/generate_content_identifier/',
        'v1',
        { text }
      )
      return response.data.content_identifier || undefined
    } catch (error) {
      console.error('generate_content_identifier', error)
      return undefined
    }
  }

  static async transferFlows(
    authToken: string,
    botId: string,
    targetBotId: string
  ): Promise<void> {
    try {
      await HubtypeApi.post(authToken, `bot_flows/${botId}/transfer/`, 'v1', {
        target_bot_id: targetBotId,
      })
    } catch (error) {
      return Promise.reject({
        analytics: { code: 'transfer_failed' },
        message:
          'Failed to transfer flows. Please try again later or contact support for further assistance.',
      })
    }
  }
  // TODO: Remove this as it is used in flow-builder-service
  static async getFlow(
    authToken: string,
    botId: string,
    version: string
  ): Promise<HtFlowBuilderData | undefined> {
    const data = await HubtypeApi.get(
      authToken,
      `bot_flows/${botId}/versions/${version}/`
    )
    return data.error ? undefined : data
  }
}
