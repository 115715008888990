import { cva } from 'class-variance-authority'

import styles from './spinner.module.css'

const spinnerClasses = cva(styles.spinner, {
  variants: {
    size: {
      tiny: styles.tiny,
      small: styles.small,
      medium: styles.medium,
      large: styles.large,
    },
    intent: {
      primary: styles.primary,
      secondary: styles.secondary,
    },
  },
})

export interface SpinnerProps {
  /** The size of the spinner */
  size?: 'tiny' | 'small' | 'medium' | 'large'
  /** The intent of the spinner */
  intent?: 'primary' | 'secondary'
}

export const Spinner = ({
  size = 'medium',
  intent = 'primary',
}: SpinnerProps): JSX.Element => {
  return (
    <div className={spinnerClasses({ size, intent })}>
      <svg viewBox='25 25 50 50'>
        <circle cx='50' cy='50' r='20'></circle>
      </svg>
    </div>
  )
}
