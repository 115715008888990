import { Icon } from '@hubtype/ui-react-web'

import { TrackEventName, useAnalytics } from '../../analytics'
import { Popup } from '../../components/popup/popup'
import { postCloseFromFlowBuilderMessage } from '../../hubtype-events'
import styles from './session-modal.module.css'

export const ConnectionLostPopup = (): JSX.Element => {
  const analytics = useAnalytics()

  const close = (): void => {
    analytics.trackEvent(TrackEventName.USER_SESSION_ENDED)
    postCloseFromFlowBuilderMessage(null)
  }

  return (
    <Popup
      title={
        <div className={styles.title}>
          <Icon icon='exclamation-circle' size='medium' />
          Disconnected: Connection lost
        </div>
      }
      discardButton={{ text: 'Close', onClick: close }}
      message='Your session has ended due to a temporary connection issue.'
      canCloseOnClickOutside={false}
      closeButtonEnabled={false}
    />
  )
}
