import { CountryInfo } from '../../domain/models/content-fields'
import { CountryCode } from '../../domain/models/locales/country-code'
import { LanguageInfo } from '../../domain/models/locales/language'
import { Locale } from '../../domain/models/locales/locale'
import { AVAILABLE_COUNTRIES } from '../../UI/constants'
import { PreviewUrlParams } from '../../UI/types'
import {
  stringArrayToLocaleArray,
  stringToLocale,
} from '../../UI/utils/locales'
import {
  getStoragePreviewStateFromFBApp,
  parsePreviewStateForBotId,
} from './storage-utils'
import { getParamsFromUrl } from './url-utils'

type LocalesLoaderLoadedData = [string, string[], CountryCode | undefined]

export interface LocalesLoaderStrategy {
  loadFrom(): LocalesLoaderLoadedData
}

export class LoadLocalesFromURL implements LocalesLoaderStrategy {
  public loadFrom(): LocalesLoaderLoadedData {
    const [urlCurrentLocale, urlLocaleCodes, urlCurrentCountry] =
      getParamsFromUrl([
        PreviewUrlParams.CURRENT_LOCALE,
        PreviewUrlParams.LOCALES,
        PreviewUrlParams.CURRENT_COUNTRY,
      ])
    if (!urlCurrentLocale || !urlLocaleCodes) {
      throw new Error('Unable to load locales information from URL')
    }

    return [
      urlCurrentLocale,
      urlLocaleCodes.split(','),
      urlCurrentCountry as CountryCode,
    ]
  }
}

export class LoadLocalesFromStorage implements LocalesLoaderStrategy {
  constructor(public botId: string) {
    this.botId = botId
  }

  public loadFrom(): LocalesLoaderLoadedData {
    const storagePreviewState = getStoragePreviewStateFromFBApp()
    if (!storagePreviewState) {
      throw new Error('Unable to load locales information from storage')
    }

    const parsedPreviewState = parsePreviewStateForBotId({
      previewStateStorageValue: storagePreviewState,
      botId: this.botId,
    })

    if (!parsedPreviewState) {
      throw new Error('Unable to parse locales information from storage')
    }
    const { current, locales, currentCountry } = parsedPreviewState
    return [current, locales, currentCountry]
  }
}

type LanguageCountryItemMap = { language: LanguageInfo } & {
  countries: CountryInfo[]
}

export class LocalesLoader {
  private currentLocale?: Locale
  private locales: Locale[] = []
  public languageCountriesMap = new Map<string, LanguageCountryItemMap>()
  public currentLanguage?: LanguageInfo
  public currentCountry?: CountryInfo
  public availableLanguages: LanguageInfo[] = []
  public availableCountriesForCurrentLanguage: CountryInfo[] = []

  constructor(private strategy?: LocalesLoaderStrategy) {
    this.strategy = strategy
  }

  load(): this {
    if (!this.strategy) {
      throw new Error('You must provide a strategy to load locales')
    }
    const [
      currentLocaleCodeAsString,
      localeCodesAsString,
      currentCountryCodeAsString,
    ] = this.strategy.loadFrom()

    this.currentLocale = stringToLocale(currentLocaleCodeAsString)
    this.locales = stringArrayToLocaleArray(localeCodesAsString)

    if (!this.currentLocale) {
      throw new Error('Current locale not supported')
    }

    this.generateLanguageCountriesMap()

    this.fillLanguageCountriesInfoFromMap(
      this.currentLocale.genericCode,
      currentCountryCodeAsString
    )

    return this
  }

  private fillLanguageCountriesInfoFromMap(
    currentLocaleGenericCode: string,
    currentStringCountryCode?: CountryCode
  ) {
    const currentLocale = this.languageCountriesMap.get(
      currentLocaleGenericCode
    )
    if (currentLocale) {
      this.currentLanguage = currentLocale.language
      if (!currentStringCountryCode) {
        this.currentCountry = currentLocale.countries[0]
      } else {
        this.currentCountry = currentLocale.countries.find(
          country => country.id === currentStringCountryCode
        )
      }
      const languageCountriesItem = this.languageCountriesMap.get(
        currentLocaleGenericCode
      )
      if (languageCountriesItem) {
        this.availableCountriesForCurrentLanguage =
          languageCountriesItem.countries
      }
    }
  }

  private generateLanguageCountriesMap() {
    this.locales.forEach(locale => {
      const genericLocaleCode = locale.genericCode
      const languageInfo = locale.getLanguageInfo()

      const mapHasLocale = this.languageCountriesMap.has(genericLocaleCode)

      if (!mapHasLocale) {
        const countries = locale.isGenericLocale() ? AVAILABLE_COUNTRIES : []
        this.languageCountriesMap.set(genericLocaleCode, {
          language: languageInfo,
          countries: countries,
        })
        this.availableLanguages.push(languageInfo)
      }

      if (!locale.isGenericLocale()) {
        const countryInfo = locale.getCountryInfo()
        if (countryInfo) {
          const languageCountriesItem =
            this.languageCountriesMap.get(genericLocaleCode)
          if (languageCountriesItem) {
            const countries = languageCountriesItem.countries
            countries.push(countryInfo)

            countries.sort((a, b) => a.name.localeCompare(b.name))
          }
        }
      }
    })
  }
}
