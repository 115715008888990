import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'
import { mergeProps, useCheckbox, useFocusRing, useObjectRef } from 'react-aria'
import { useToggleState } from 'react-stately'

import Field from '../field/field'
import { FooterDescriptionProps, FooterErrorProps } from '../field/footer'
import styles from './checkbox.module.css'

const checkboxClasses = cva(styles.checkbox, {
  variants: {
    size: {
      medium: [styles.medium],
      large: [styles.large],
    },
  },
  defaultVariants: { size: 'medium' },
})

export interface CheckboxProps
  extends FooterDescriptionProps,
    FooterErrorProps {
  /** The size of the checkbox. */
  size?: 'medium' | 'large'
  /** The checkbox's label. */
  label: React.ReactNode
  /** The checkbox's subtitle. */
  subtitle?: React.ReactNode
  /** Whether the checkbox is disabled. */
  isDisabled?: boolean
  /** Whether the element should be selected (controlled). */
  isSelected?: boolean
  /** Whether the element should be selected (uncontrolled). */
  defaultSelected?: boolean
  /** Whether the input can be selected but not changed by the user. */
  isReadOnly?: boolean
  /** The name of the input element, used when submitting an HTML form */
  name?: string
  /** Whether the element should receive focus on render. */
  autoFocus?: boolean
  /** Handler that is called when the element's selection state changes. */
  onChange?: (isSelected: boolean) => void
}

/** Checkboxes allow users to select multiple items from a list of individual items, or to mark one individual item as selected. */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ size = 'medium', label, ...props }, forwardedRef) => {
    const state = useToggleState(props)
    const ref = useObjectRef(forwardedRef)
    const { inputProps } = useCheckbox(
      { ...props, 'aria-label': label?.toString() },
      state,
      ref
    )
    const { focusProps, isFocusVisible } = useFocusRing()

    return (
      <Field {...props} size={size} isLabelHidden>
        <label className={checkboxClasses({ size })}>
          <input
            {...mergeProps(inputProps, focusProps)}
            className={styles.input}
            ref={ref}
            data-focus-visible={isFocusVisible || undefined}
          />
          <span className={styles.checkmark}></span>
          <div className={styles.label}>
            <span>{label}</span>
            {props.subtitle && (
              <span className={styles.subtitle}>{props.subtitle}</span>
            )}
          </div>
        </label>
      </Field>
    )
  }
)

export default Checkbox
