import { Icon, Spinner, Tooltip } from '@hubtype/ui-react-web'
import { AriaButtonProps, useButton } from 'react-aria'

import { HtBtnType } from './ht-button-styles'
import { StyledDropdownButton } from './styles'

interface ButtonProps extends AriaButtonProps {
  buttonRef: React.RefObject<HTMLButtonElement>
  btnProps?: {
    size?: 'small' | 'medium'
    width?: string
    tooltip?: string
  }
  readOnly?: boolean
  isInvalid?: boolean
  isLoading?: boolean
  isOpen: boolean
  isEmpty?: boolean
  buttonSymbol?: React.ReactNode
}

export const DropdownButton = (props: ButtonProps): JSX.Element => {
  const ref = props.buttonRef
  const { buttonProps } = useButton(
    { ...props, isDisabled: props.readOnly || props.isLoading },
    ref
  )

  const getIcon = () => {
    if (props.isLoading) {
      return <Spinner intent='secondary' />
    }
    return (
      <Icon icon={props.isOpen ? 'chevron-up' : 'chevron-down'} size='small' />
    )
  }

  return (
    <StyledDropdownButton
      {...buttonProps}
      ref={ref}
      $size={props.btnProps?.size || 'medium'}
      $type={HtBtnType.BORDERLESS_BLACK}
      $width={props.btnProps?.width || '100%'}
      $isEmpty={props.isEmpty}
      $isReadOnly={props.readOnly}
      $isInvalid={props.isInvalid}
      disabled={props.isDisabled || props.isLoading}
      aria-label={props.children?.toString()}
    >
      <div>
        {props.buttonSymbol}
        <Tooltip
          content={props.btnProps?.tooltip}
          isDisabled={!props.btnProps?.tooltip}
          delay={500}
        >
          <span>{props.children}</span>
        </Tooltip>
        {getIcon()}
      </div>
    </StyledDropdownButton>
  )
}
