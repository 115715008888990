import styled from 'styled-components'

import { COLORS } from '../colors'
import { textBodySmall } from '../typography'

export const StyledSlider = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
  color: ${COLORS.N500};
`

export const StyledTrackContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const StyledTrack = styled.div<{ $size: number; $isDisabled: boolean }>`
  width: 100%;
  height: 5px;
  background: ${props => (props.$isDisabled ? COLORS.N300 : COLORS.N500)};
  border-radius: 5px;
  background-image: ${props =>
    props.$isDisabled
      ? `linear-gradient(${COLORS.L300}, ${COLORS.L300})`
      : `linear-gradient(${COLORS.L500}, ${COLORS.L500})`};
  background-size: ${props => props.$size}% 100%;
  background-repeat: no-repeat;
`
export const StyledThumb = styled.div<{ $isDisabled: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${COLORS.WHITE};
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  transform: translate(-50%, -30%) !important;
  cursor: ${props => (props.$isDisabled ? 'default' : 'pointer')};
`

export const InputContainer = styled.div<{ $isDisabled: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px;
  background: ${props => (props.$isDisabled ? COLORS.N50 : COLORS.WHITE)};
  border: 1px solid ${COLORS.N400};
  box-sizing: border-box;
  border-radius: 8px;
  gap: 2px;
  width: 86px;

  input {
    width: 100%;
    padding: 0px;
    background: transparent;
    border: none;
    ${textBodySmall};
    outline: none;
    text-align: end;
  }

  ${textBodySmall} {
    color: ${COLORS.N500};
  }
`

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;

  button {
    height: 10px;
    width: 10px;
    padding: 0px;

    &:hover {
      background: transparent !important;
    }
  }
`
