import { cva } from 'class-variance-authority'
import {
  AriaButtonProps,
  mergeProps,
  useButton,
  useFocusRing,
} from 'react-aria'

import Icon from '../../../icon/icon'
import IconButton from '../../../icon-button/icon-button'
import { Spinner } from '../../../spinner/spinner'
import Tooltip, { TooltipProps } from '../../../tooltip/tooltip'
import styles from './dropdown-button.module.css'

const buttonClasses = cva(styles.base, {
  variants: {
    size: {
      small: styles.small,
      medium: styles.medium,
      large: styles.large,
    },
    intent: {
      primary: styles.primary,
      secondary: styles.secondary,
    },
    status: {
      error: styles.error,
      readOnly: styles.readOnly,
    },
    isEmpty: {
      true: [styles.empty],
    },
  },
  defaultVariants: { size: 'medium' },
})

export interface DropdownButtonProps extends AriaButtonProps {
  /** Ref to the button. */
  buttonRef: React.RefObject<HTMLButtonElement>
  /** Whether the dropdown is open. */
  isOpen: boolean
  /** Whether the dropdown is empty. */
  isEmpty?: boolean
  /** Whether the dropdown is loading. */
  isLoading?: boolean
  /** The intent of the button. */
  intent?: 'primary' | 'secondary'
  /** The size of the button. */
  size?: 'small' | 'medium' | 'large'
  /** Whether the button can be selected but not changed by the user. */
  isReadOnly?: boolean
  /** Whether the dropdown value is invalid. */
  isInvalid?: boolean
  /** The width of the button. */
  width?: string
  /** The tooltip of the button. */
  tooltipProps?: Partial<TooltipProps>
  /** The symbol to display on the button. */
  buttonSymbol?: React.ReactNode
  /** Whether the button can be cleared. */
  isClearable?: boolean
  /** Handler for the clear button. */
  onClear?: () => void
}

export const DropdownButton = ({
  size = 'medium',
  intent = 'primary',
  isEmpty,
  width = '100%',
  tooltipProps,
  buttonSymbol,
  isClearable,
  onClear,
  ...props
}: DropdownButtonProps): JSX.Element => {
  const ref = props.buttonRef
  const { buttonProps } = useButton(
    {
      ...props,
      isDisabled: props.isDisabled || props.isReadOnly || props.isLoading,
    },
    ref
  )
  const { focusProps, isFocusVisible } = useFocusRing()

  const getIcon = () => {
    if (props.isLoading) {
      return <Spinner intent='secondary' />
    }
    return <Icon icon={props.isOpen ? 'chevron-up' : 'chevron-down'} />
  }

  return (
    <button
      {...mergeProps(
        buttonProps,
        focusProps,
        { 'aria-invalid': props.isInvalid },
        { 'aria-readonly': props.isReadOnly },
        { 'aria-disabled': props.isDisabled || props.isLoading }
      )}
      style={{ width }}
      ref={ref}
      className={buttonClasses({ size, intent, isEmpty })}
      data-focus-visible={isFocusVisible || undefined}
    >
      {buttonSymbol}
      <Tooltip isDisabled={!tooltipProps?.content} {...tooltipProps}>
        <span>{props.children}</span>
      </Tooltip>
      {isClearable &&
        !props.isDisabled &&
        !props.isReadOnly &&
        !props.isLoading && (
          <IconButton
            icon='xmark'
            shape='rounded'
            size='small'
            onPress={onClear}
          />
        )}
      {getIcon()}
    </button>
  )
}
